.resolver-surface-editor-locator-global-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    opacity: 0;
    padding: 3px;
    overflow: hidden;
}

.resolver-surface-editor {
    width: 94px;
    height: 94px;

    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

    transform: rotate(-45deg);
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
}
.resolver-surface-editor__control {
    position: absolute;
    box-sizing: border-box;
    width: 47px;
    height: 47px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background: #57585F;
    cursor: pointer;
}

.resolver-surface-editor__control__m-selected {
    background-color: #25272f;
}

.resolver-surface-editor__control:not(.resolver-surface-editor__control__m-selected):hover {
    background-color: #2f313a;
}

.resolver-surface-editor__control-content {
    color: #fff;
    transform: rotate(45deg);
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    position: relative;
}

.resolver-surface-editor__control__m-index-1 {
    left: 0;
    border-bottom: 1px #45464E solid;
}

.resolver-surface-editor__control__m-index-1 .resolver-surface-editor__control-content {
    top: 3px;
    left: 2px;
}

.resolver-surface-editor__control__m-index-2 {
    right: 0;
    border-bottom: 1px #45464E solid;
    border-left: 1px #45464E solid;
}

.resolver-surface-editor__control__m-index-2 .resolver-surface-editor__control-content {
    top: 3px;
    left: -5px;
}

.resolver-surface-editor__control__m-index-3 {
    left: 0;
    bottom: 0;
}

.resolver-surface-editor__control__m-index-3 .resolver-surface-editor__control-content {
    top: -4px;
    left: 2px;
}

.resolver-surface-editor__control__m-index-4 {
    right: 0;
    bottom: 0;
    border-left: 1px #45464E solid;
}

.resolver-surface-editor__control__m-index-4 .resolver-surface-editor__control-content {
    top: -3px;
    left: -3px;
}

.resolver-surface-editor__control__m-index-5 {
    left: 31px;
    top: 31px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px #45464E solid;
}
