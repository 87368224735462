.collection-controls {
    display: flex;
}

.collection-controls__icon__m-collections {
    color: #97989c;
    width: 30px !important;
    height: auto !important;
    margin-top: 2px;
}
