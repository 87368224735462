.report-specialist {
    display: flex;
    flex-flow: row nowrap;
}

.report-specialist-primary {
    font-size: 12px;
    font-weight: 500;
}

.report-specialist-primary__control select {
    height: 24px;
}

.report-specialist-primary__value {
    display: none;
    padding: 4px;
}

.report-specialist-secondary {
    font-size: 12px;
    font-weight: 500;
}

.report-specialist-secondary__control {
    height: 20px;
    padding: 0;
}

.report-specialist__m-read-only .report-specialist-secondary__control {
    border: none;
    background: transparent;
}

.report-specialist__m-read-only .report-specialist-secondary__control::placeholder {
    color: transparent;
}

@media print
{
    .report-specialist-primary__control {
        display: none;
    }

    .report-specialist-primary__value {
        display: block;
    }

    .report-specialist-secondary__control {
        border: none;
        background: transparent;
    }

    .report-specialist-secondary__control::placeholder {
        color: transparent;
    }
}
