.form-select {
    border-bottom: 1px #97989c solid;
    display: inline-block;
    position: relative;
    font-size: 14px;
}

.form-select__element {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
}

.form-select__visible {
    display: flex;
    width: 100%;
    height: 100%;

    flex-direction: row;
}

.form-select__visible-text {
    color: #fff;
    padding: 4px 0;
    flex: 1 1 auto;
}

.form-select__visible-control {
    width: 25px;
    height: 25px;
    background: url('../images/arrow-drop-down.svg');
    flex-shrink: 0;
}

.form-select__m-block {
    display: block;
}

.form-select__m-block .form-select__element {
    width: 100%;
}

.form-select__m-invalid {
    border-color: #ef5350;
}

.form-select__m-invalid .form-select__visible-text {
    color: #ef5350;
}

.form-select__m-disabled .form-select__visible-text,
.form-select__m-disabled .form-select__visible-control {
    opacity: .5;
}
