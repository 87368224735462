.box-tooltip {
    display: flex;
    flex-flow: row nowrap;
}

.box-tooltip__tooth-key {
    width: 20px;
    height: 20px;
    border: 2px #00ad66 solid;
    border-radius: 50%;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-tooltip__tooth-key__m-no-pathologies {
    border-color: #00ad66;
}

.box-tooltip__tooth-key__m-has-pathologies {
    border-color: #ef5350;
}

.box-tooltip__tooth + .box-tooltip__findings {
    padding: 0 0 0 5px;
    flex: 1 1 auto;
}

.box-tooltip__findings-item {
    padding: 1px 0;

    display: flex;
    flex-flow: column nowrap;
}

.box-tooltip__findings-item-tags {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 3px;
}

.box-tooltip-findings-item-tag {
    display: flex;
    flex-flow: row nowrap;
}

.box-tooltip-findings-item-tag__icon {
    width: 12px;
    height: 12px;
    background: url('../../../images/tag.svg') no-repeat center;

    background-size: contain;
    align-self: center;
}

.box-tooltip-findings-item-tag__value {
    padding: 1px 0 1px 5px;
    font-size: 12px;
}
