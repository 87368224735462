/*all*/
body {
	margin: 0;
    padding: 0 !important;
    -moz-user-select: none;
    overflow: hidden;
}
a {
	text-decoration: none;
	cursor: pointer;
    color: #00ad66;
}
body, html {
    height: 100%;
    background-color: #1b1d24;

}

html body, button {
    font-family: 'Roboto', sans-serif;
}

body {
    overflow-y: scroll;
    overflow-x: auto;
}

div:focus {
    outline: none;
}

.main-customs-scroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.main-customs-scroll::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .3);
}

#app-content {
    height: 100%;
    margin: 0 !important;
}

#app-content.m-small-screen {
    min-width: 860px;
}
