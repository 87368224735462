.resolver-config-popup {
    background-color: #2f313a;
    padding: 8px;
    width: 250px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 2px 2px 3px rgba(0,0,0,.5);
}

.resolver-config-popup__heading {
    padding: 0 0 8px;
    font-size: 11px;
    color: #c9cbd2;
    line-height: 16px;
}

.resolver-config-popup__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.resolver-config-popup__button {
    flex: 0 0 auto;
    margin-right: 16px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.resolver-config-popup__button__m-agree {
    background-image: url('../../../../../images/agree.svg');
}

.resolver-config-popup__button__m-cancel {
    background-image: url('../../../../../images/remove-button.svg');
}
