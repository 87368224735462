.DayPickerInput-OverlayWrapper {
    position: absolute;
    right: 20px;
    z-index: 101;
}

.DayPickerInput-Overlay {
    right: 20px;
    left: auto;
    position: absolute;
    padding: 5px;
    background: #4c4d55;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPickerInput__m-invalid {
    border-bottom: 2px #f44336 solid;
}
