.resolver-findings-category__header {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 16px 0;
}

.resolver-findings-category__header__m-with-expand {
	cursor: pointer;
}

.resolver-findings-category__expand-button {
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
	background-image: url(../images/arrow.svg);
	cursor: pointer;
	margin: 0 8px 0 0;
}

.resolver-findings-category__expand-button__m-expanded {
	transform-origin: 50% 50%;
	transform: rotate(90deg);
}

.resolver-findings-category__title {
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #989898;
}
