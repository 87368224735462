.ui-huddle-tile-filter {
	position: relative;
	background-color: #25272F;
	padding: 30px 20px;
	border-radius: 3px;
	cursor: pointer;
}

.ui-huddle-tile-filter__show-btn {
	width: 77px;
	height: 30px;
	background-image: url('../images/show-btn.svg');

	position: absolute;
	top: 10px;
	right: 10px;

	display: none;
}

.ui-huddle-tile-filter__hide-btn {
	width: 30px;
	height: 30px;
	background-image: url('../images/hide-btn.svg');

	position: absolute;
	top: 10px;
	right: 10px;

	display: none;
}



.ui-huddle-tile-filter__m-active {
	background-color: #333542;
}

.ui-huddle-tile-filter__m-disabled {
	cursor: default;
}

.ui-huddle-tile-filter__m-disabled .ui-huddle-tile-filter__content{
	opacity: .7;
}

.ui-huddle-tile-filter:hover .ui-huddle-tile-filter__show-btn,
.ui-huddle-tile-filter__m-active .ui-huddle-tile-filter__show-btn,
.ui-huddle-tile-filter__m-active .ui-huddle-tile-filter__hide-btn,
.ui-huddle-tile-filter:hover .ui-huddle-tile-filter__hide-btn {
	display: block;
}

