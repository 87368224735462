.image-box {
    position: absolute;
    border: 2px #838383 solid;
	z-index: 1;
    box-sizing: border-box;
    opacity: 1;
    cursor: pointer;
}

.image-box__m-invisible {
    visibility: hidden;
}

.image-box__m-faded {
    opacity: .6;
}

.image-box__m-primary {
    border-style: dashed;
    opacity: .6;
}

.image-box__m-secondary {
    border-style: dotted;
}

.image-box__m-no-pathologies {
    border-color: #00ad66;
}

.image-box__m-has-pathologies {
    border-color: #ef5350;
}

.image-box__m-selected,
.image-box__m-highlighted {
	z-index: 2;
    border-width: 3px;
}

.image-box__m-selected {
    opacity: 1;
    z-index: 3;
}

.image-box__m-highlighted {
    opacity: .6;
}

.image-box-controls {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: move;

    display: none;
}

.image-box-resize-controls__left-top,
.image-box-resize-controls__right-top,
.image-box-resize-controls__left-bottom,
.image-box-resize-controls__right-bottom {
    width: 8px;
    height: 8px;
    background-color: #0db266;
}

.image-box-resize-controls__left-top {
    position: absolute;
    left: -2px;
    top: -2px;
    cursor: nw-resize;
}

.image-box-resize-controls__right-top {
    position: absolute;
    right: -2px;
    top: -2px;
    cursor: ne-resize;
}

.image-box-resize-controls__left-bottom {
    position: absolute;
    left: -2px;
    bottom: -2px;
    cursor: sw-resize;
}

.image-box-resize-controls__right-bottom {
    position: absolute;
    right: -2px;
    bottom: -2px;
    cursor: se-resize;
}

.image-box__m-selected .image-box-controls {
    display: block;
}

.image-box__m-has-pathologies .image-box-resize-controls__left-top,
.image-box__m-has-pathologies .image-box-resize-controls__right-top,
.image-box__m-has-pathologies .image-box-resize-controls__left-bottom,
.image-box__m-has-pathologies .image-box-resize-controls__right-bottom {
    background-color: #ef5350;
}

.alignment {
    width: 100%;
    height: 100%;
}

.alignment:after {
    content: '';
    display: inline-block;
    height: 100%;
    font-size: 0;
    vertical-align: middle;
}

.alignment__content {
    display: inline-block;
    text-align: left;
    position: relative;
}

.alignment__content__m-vertical-top {
    vertical-align: top;
}

.alignment__content__m-vertical-center {
    vertical-align: middle;
}

.alignment__content__m-vertical-bottom {
    vertical-align: bottom;
}

.alignment__m-horizontal-left {
    text-align: left;
}

.alignment__m-horizontal-center {
    text-align: center;
}

.alignment__m-horizontal-right {
    text-align: right;
}

.overlay {
    width: 100%;
    height: 100%;
}
.overlay__m-theme-dark {
    background: rgba(0,0,0,.7);
}

.overlay__m-theme-light {
    background: rgba(255, 255,255,.3);
}

.overlay__m-theme-white {
    background: #fff;
}

.overlay__m-theme-transparent {
    background: transparent;
}

.popup {
    width: 100%;
    height: 100%;
}

.popup-global-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
}

.popup-dialog-header {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-align-items: center;
	        align-items: center;
	box-sizing: border-box;
	overflow: hidden;
	padding: 15px 8px;
	background: #4c4d55;
	color: #fff;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	font-size: 14px;
}

.popup-dialog-header__title {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	display: inline-block;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.popup-dialog-header__icon {
	display: inline-block;
	margin-right: 10px;
	width: 20px;
	height: 20px;
}

.popup-dialog-header__close-button {
	padding: 5px;
	cursor: pointer;
}

.popup-dialog-header__icon__m-success {
	background: url(/static/media/success.384d5081.svg);
}

.popup-dialog-header__icon__m-error {
	background: url(/static/media/error.ff15fb73.svg);
}

.popup-dialog-header__icon__m-list {
	background: url(/static/media/list.6b34bc4f.svg);
}

.popup-dialog__content {
	padding: 20px 40px 10px;
	color: #fff;
	font-size: 14px;
}

.popup-dialog__content-without-padding {
	margin: -20px -40px -10px;
}

.popup-dialog__content-text {
	line-height: 1.3;
}

.button {
    display: inline-block;
    cursor: pointer;
    border-radius: 25px;
    border: 2px solid transparent;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    text-align: center;
    white-space: nowrap;
}

.button__m-block {
    display: block;
    width: 100%;
}

.button__m-uppercase {
    text-transform: uppercase;
}

.button__m-disabled {
    opacity: .5;
    cursor: default;
}

.button:active,
.button:focus {
    outline: none;
}

.button__m-size-large {
    padding: 12px 25px;
}

.button__m-size-medium {
    padding: 10px 12px;
    font-size: 14px;
}

.button__m-size-small {
    padding: 5px 10px;
    font-size: 12px;
}

.button__m-theme-primary {
    background: #349563;
    color: #fff;
    border-color: #349563;
}

.button__m-theme-secondary {
    background: transparent;
    color: #fff;
    border-color: #349563;
}

.button__m-theme-warn {
    background: #EF5350;
    color: #fff;
    border-color: #EF5350;
}

.button__m-pulsing {
    -webkit-animation: pulse 1.5s infinite;
            animation: pulse 1.5s infinite;
    box-shadow: 0 0 0 0 rgba(0, 173, 102, .5);
}

.button__m-pulsing:hover {
    -webkit-animation: none;
            animation: none;
}

.m-small-screen .button__m-size-large {
    padding: 12px 15px;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
    }
    70% {
        -webkit-transform: scale(1);
                transform: scale(1);
        box-shadow: 0 0 0 50px rgba(0, 173, 102, 0);
    }
    100% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        box-shadow: 0 0 0 0 rgba(0, 173, 102, 0);
    }
}

.popup-dialog-footer {
	padding: 10px 40px 17px;
}

.popup-dialog-footer__m-alignment-left {
	text-align: left;
}

.popup-dialog-footer__m-alignment-right {
	text-align: right;
}

.popup-dialog-footer__m-alignment-center {
	text-align: center;
}

.popup-dialog-footer .button {
	margin-right: 20px;
}

.popup-dialog-footer .button:last-child {
	margin-right: 0;
}

.popup-dialog {
	position: relative;
	background: #57585f;
	overflow: hidden;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	min-width: 300px;
}

.form-input {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    display: inline-block;
    font-size: 14px;
}

.form-input__element {
    background: transparent;
    color: #fff;
    border: none;
    padding: 5px 0;
}

.form-input__element::-webkit-input-placeholder {
    color: #fff;
}

.form-input__element::placeholder {
    color: #fff;
}

.form-input__element:disabled {
    opacity: .5;
}

.form-input__element:active,
.form-input__element:focus {
    outline: none;
}

.form-input__m-block {
    display: block;
}

.form-input__m-block .form-input__element {
    width: 100%;
}

.form-input__m-invalid {
    border-color: #ef5350;
}

.form-input__m-invalid .form-input__element {
    color: #ef5350;
}

.feedback-container {
    position: absolute;
    top: 16px;
    right: 353px;
    z-index: 1;
}

.feedback {
    border-radius: 5px;
    border: 1px solid #00AD66;
    width: 314px;
    height: 44px;
    background: #25272f;
}

.feedback-vote {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 10px;
}

.feedback-vote__text {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    font-size: 12px;
    line-height: 14px;

    color: #00AD66;
}
.feedback-vote__votes {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.feedback-vote__vote-button {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.feedback-vote__vote-button:not(:last-child) {
    margin-right: 16px;
}

.feedback-vote__vote-button__m-like {
    background: url(/static/media/like.f479cd85.svg) no-repeat;
}

.feedback-vote__vote-button__m-dislike {
    background: url(/static/media/dislike.1de85861.svg) no-repeat;
}

.feedback-comment {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 10px;
}

.feedback-comment__content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    font-size: 12px;
    line-height: 14px;
    padding-right: 20px;
    color: #00AD66;
}

.feedback-comment__buttons {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.feedback-comment__button {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.feedback-comment__button__m-agree {
    background: url(/static/media/agree.8cf6b2da.svg) no-repeat;
}

.feedback-comment__button__m-disagree {
    background: url(/static/media/disagree.3d40f6d7.svg) no-repeat;
}

.feedback-comment__label {
    color: #000;
}

.feedback-comment__input::-webkit-input-placeholder {
    font-size: 12px;
    color: #C9CBD2;
}

.feedback-comment__input::placeholder {
    font-size: 12px;
    color: #C9CBD2;
}

.logo {
    position: relative;
}

.logo__image {
    display: block;
}

.image-controls {
    display: -webkit-flex;
    display: flex;
}

.image-controls__button {
    display: inline-block;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 100%;
    cursor: pointer;

    box-sizing: border-box;

    margin-right: 12px;

    background-position: 0;
    background-color: transparent;
    background-repeat: no-repeat;

    background-size: 44px 44px;

    border: 1px solid #383a41;
}

/*HACK*/
label.image-controls__button {
    position: relative;
    top: 1px;
}

.image-controls__button:disabled {
    cursor: default;

    opacity: .5;
}

.image-controls__button:active,
.image-controls__button:focus {
    outline: none;
}

.image-controls__button__m-active,
.image-controls__button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.image-controls__button__m-text {
    color: #989898;
}

.image-controls__button__m-collections {
    background-image: url(/static/media/collections.6e8a2934.svg);
    background-size: 30px 30px;
    background-position: 6px 7px;
}

.image-controls__button__m-upload {
    background-image: url(/static/media/upload.9d507831.svg);
    background-position: -1px -2px;
}

.image-controls__button__m-open {
    background-image: url(/static/media/open.ff050d2d.svg);
    background-position: -1px -2px;
}

.image-controls__button__m-save {
    background-image: url(/static/media/save.834055e8.svg);
}

.image-controls__button__m-share {
    background-image: url(/static/media/share.08bed85d.svg);
}

.image-controls__button__m-copy {
    background-image: url(/static/media/copy.cb81563a.svg);
}


.image-controls__button__m-treatment-plan {
    background-image: url(/static/media/treatment-plan.57c0eb27.svg);
}

.image-controls__button__m-back {
    width: 44px;
    height: 44px
}

.image-controls__icon__m-collections {
    color: #97989c;
    width: 30px !important;
    height: auto !important;
    margin-top: 2px;
}

.avatar {
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
    overflow: hidden;
}

.avatar__image {
    display: block;
    width: 100%;
    height: 100%;
}

.user-info {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 10px;
    position: relative;
}

.user-info__auth-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}
.user-info__auth-button:not(:last-child) {
    margin-right: 10px;
}

.user-info__username {
    width: 153px;
    font-size: 14px;
    color: #acadb2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.user-info__user-menu-button {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.user-info__user-menu-button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -5px;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
    border-top: 5px rgba(255,255,255,.6) solid;
}

.user-info__user-menu-button__m-opened:before {
    border-top: none;
    border-bottom: 5px rgba(255,255,255,.6) solid;
}

.user-info__dropdown-menu {
    background-color: #57585f;
    position: absolute;
    min-width: 200px;
    top: 100%;
    right: 0;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 100;
    padding: 10px;
}

.user-info__dropdown-menu:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 41px;
    border-left: 10px transparent solid;
    border-right: 10px transparent solid;
    border-bottom: 10px #57585f solid;
}

.rounded-button {
    display: inline-block;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 100%;
    cursor: pointer;

    box-sizing: border-box;
    background-position: 0;
    background-color: transparent;
    background-repeat: no-repeat;

    background-size: 44px 44px;
    outline: none;
}

.rounded-button__m-disabled {
    cursor: default;
    opacity: .5;
}

.rounded-button__m-theme-primary {
    border: 1px solid #349563;
}

.rounded-button__m-theme-secondary {
    border: 1px solid #383a41;
}

.rounded-button__m-type-add {
    background-image: url(/static/media/add.d2b66828.svg);
    background-position: -1px -1px;
}

.rounded-button__m-type-add-thin {
    background-image: url(/static/media/add-thin.b46b8287.svg);
    background-size: 14px 14px;
    background-position: center center;
}


.rounded-button__m-type-stats {
    background-image: url(/static/media/stats.a287ccec.svg);
    background-position: -1px -1px;

}

.rounded-button__m-type-collections {
    background-image: url(/static/media/collections.6e8a2934.svg);
    background-size: 30px 30px;
    background-position: 6px 7px;
}

.rounded-button__m-type-undo {
    background-image: url(/static/media/undo.b1d236f8.svg);
    background-size: 15px 16px;
    background-position: center center;
}

.rounded-button__m-type-redo {
    background-image: url(/static/media/undo.b1d236f8.svg);
    background-size: 15px 16px;
    background-position: center center;
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
}

.rounded-button__m-size-large {
    width: 44px;
    height: 44px;
}

.rounded-button__m-size-medium {
    width: 40px;
    height: 40px;
}

.rounded-button__m-size-small {
    width: 30px;
    height: 30px;
}

.messenger__button {
	color: #00AD66;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
}

.edit_collection_form__checkbox {
    margin-left: -4px;
}

.edit_collection_form__checkbox-content {
    line-height: 23px;
    margin-left: 10px;
}

.collection-controls {
    display: -webkit-flex;
    display: flex;
}

.collection-controls__icon__m-collections {
    color: #97989c;
    width: 30px !important;
    height: auto !important;
    margin-top: 2px;
}

.disable-layout-draw-secondary-box-mode {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;
}

.header {
    width: 100%;
    height: 76px;
    background: #25272f;

    position: relative;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.header__version {
    position: absolute;
    left: 11px;
    bottom: 6px;
    font-size: 10px;
    color: #c9cbd2;
}

.header__button {
    display: inline-block;
}

.header__button:not(:last-child) {
    margin-right: 12px;
}


.header__image-controls {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;
}

.header__collection-controls {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;
    margin-right: auto;
}

.header__stats-controls {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;
    margin-right: auto;
}

.header__image-mode-controls {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.header__help {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0 16px;
}

.header__user-info {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.header__smth {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-right: 41px;
}

.header__edit-collection {
    height: 42px
}

.header__edit-collection a {
    display: inline-block;
}

.header__disable-layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;
}

.header-collection-search {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 20px;
}

.header-collection-search__input-wrapper {
    position: relative;
    top: -4px;
    padding-right: 24px;
}

.header-collection-search__button {
    width: 125px;
}
.header-collection-search__input {
    background: red;
}

.header-collection-search__input::-webkit-input-placeholder {
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
}

.header-collection-search__input::placeholder {
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
}

.form-label {
    display: -webkit-flex;
    display: flex;
}
.form-label__m-top {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

.form-label__m-left {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.form-label__m-right {
    -webkit-flex-direction: row;
            flex-direction: row;
}

.form-label__m-bottom {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.form-label__wrapper-element {

}

.form-label__wrapper-label-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #c9cbd2;
    font-size: 12px;
}

.form-label__m-top {
    padding-bottom: 10px;
}

.form-label__m-left {
    padding-right: 10px;
}

.form-label__m-right {
    padding-left: 10px;
}

.form-label__m-bottom {
    padding-top: 10px;
}

.form-label__m-no-padding {
    padding: 0;
}

.sign-in {
    background-color: #1b1d24;
    height: 100vh;
}

.sign-in__title {
    color: #fff;
    position: relative;
    padding-right: 25px;
    margin-right: 40px;
    font-size: 18px;
    font-weight: 500;
    height: 90px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;

}

.sign-in__title:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -2px;
    border-bottom: 5px #fff solid;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
}

.sign-in__form {
    padding: 35px 30px 30px;
    position: relative;
}

.sign-in__form-element {
    padding-top: 10px;
}

.sign-in__form-button {
    padding-top: 20px;
    text-align: center;
}

.sign-in__error {
    font-size: 12px;
    text-align: left;
    color: #97989c;
    padding: 0 30px;
    position: absolute;
    top: 0;
    left: 0;
}


.sign-in-form {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.sign-in-form__preface {
    font-size: 11px;
    padding: 0 0 20px;
    color: #fff;
}

.sign-in-form__wrapper {
    background: #57585f;
    overflow: hidden;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 32px;
    width: 300px;
}

.sign-in-form__header {
    color: #fff;
}

.sign-in-form__buttons {
    padding: 16px 0 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.sign-in-form__error {
    font-size: 12px;
    padding: 6px;
    color: #ef5350;
}

.guest-header {
    height: 90px;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    background-color: #25272f;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.guest-header__version {
    position: absolute;
    left: 11px;
    bottom: 6px;
    font-size: 10px;
    color: #c9cbd2;
}

.guest-header-logo {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
}

.guest-header-auth {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0 35px 0 0;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
}

.guest-header-auth__button {
    font-size: 18px;
    font-weight: 500;
    margin-right: 50px;
    cursor: pointer;
}

.guest-header-auth__button__m-sign-in {
    color: rgba(255,255,255,.5);
    position: relative;
    padding-right: 25px;
}

.guest-header-auth__button__m-sign-in:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    border-top: 5px rgba(255,255,255,.5) solid;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
}

.guest-header-auth__button__m-free-trial {
    color: #00ad66;
    margin-right: 0;
}


.guest-header__sign-in-menu {
    position: absolute;
    top: 0;
    right: 120px;
    width: 245px;
    background-color: #45464e;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.collection-card {
    cursor: pointer;
    min-height: 280px;
    min-width: 200px;
    margin: 5px;
    background: linear-gradient(rgba(73, 75, 88, 0.3) 0%,
        rgba(77, 77, 77, 0.7) 50%, rgba(80, 80, 80, 0.5) 100%);
}

.collection-card__loader {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    margin-top: auto;
    width:100%;
}

.collection-card__description {
    height: 30px;
    overflow: hidden;
    position: relative;
}
.collection-card__description-content {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.collection-card__description-content-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
    font-size: 14px;
}

.collection-card__badges {
    position: absolute;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.collection-card__images-num-badge {
    background: #1f1e1ecc;
    padding: 8px 5px 9px 10px;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.collection-card__default-badge {
    background: #134a29c4;
    padding: 8px 5px 9px 10px;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.collection-card__deleted-badge {
    background: #75070785;
    padding: 8px 5px 9px 10px;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.collection-card__shared-badge {
    background: #4a4113c4;
    padding: 8px 5px 9px 10px;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.m-small-screen .collection-card {
    min-height: auto;
}

.form-checkbox {
    position: relative;

    display: inline-block;
}
.form-checkbox__input {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    font-size: 20px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
}

.form-checkbox__visual {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;

    text-align: center;
    background: url(/static/media/checkbox-off.8fac8b52.svg);
}

.form-checkbox__visual__m-checked {
    background: url(/static/media/checkbox-on.7810f433.svg);
}

.loading {
    display: inline-block;
}

.loading__indicator {
    width: 100%;
    height: 100%;
    background: url(/static/media/loading.e70ee716.svg);
    background-size: cover;
}

.copy-collection-form__status {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.round-button {
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid 2px #349563;
    background: transparent;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.round-button__m-disabled {
    opacity: .5;
    cursor: default;
}

.pagination {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    color: #fff;
}

.pagination__position {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 28px 0 13px;
    font-size: 14px;
}

.pagination__control {
    margin-right: 15px;
}

.pagination__icon {
    display: inline-block;
}

.pagination__icon__m-prev {
    background-image: url(/static/media/prev.03420941.svg);
    width: 8px;
    height: 12px;
    position: relative;
    top: 1px;
    margin-right: 5px
}

.pagination__icon__m-next {
    background-image: url(/static/media/next.d5e15d29.svg);
    width: 8px;
    height: 12px;
    position: relative;
    top: 1px;
    margin-left: 5px
}

.pagination__icon__m-first {
    background-image: url(/static/media/first.4d425037.svg);
    width: 14px;
    height: 12px;
}

.pagination__icon__m-last {
    background-image: url(/static/media/last.2bfc5d19.svg);
    width: 14px;
    height: 12px;
}

.collections {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.collections__pagination {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 16px 0 32px 0;
}

.collections__no-items {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    font-size: 14px;
    padding: 32px 0;
}

.collections__status-indicator {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 32px;
}

.stats-card {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.stats-card__m-theme-dark {
    background: #25272F;
}

.stats-card__m-theme-light {
    background: #57585F;
}

.form-select {
    border-bottom: 1px #97989c solid;
    display: inline-block;
    position: relative;
    font-size: 14px;
}

.form-select__element {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
}

.form-select__visible {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;

    -webkit-flex-direction: row;

            flex-direction: row;
}

.form-select__visible-text {
    color: #fff;
    padding: 4px 0;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.form-select__visible-control {
    width: 25px;
    height: 25px;
    background: url(/static/media/arrow-drop-down.ec31e0fc.svg);
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.form-select__m-block {
    display: block;
}

.form-select__m-block .form-select__element {
    width: 100%;
}

.form-select__m-invalid {
    border-color: #ef5350;
}

.form-select__m-invalid .form-select__visible-text {
    color: #ef5350;
}

.form-select__m-disabled .form-select__visible-text,
.form-select__m-disabled .form-select__visible-control {
    opacity: .5;
}

.size-detector {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.stats-grid {
    position: relative;
    font-size: 12px;
}

.stats-grid-collection-name {
    text-decoration: underline;
    cursor: pointer;
}

.stats-grid__header-row {
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: stretch;
            align-items: stretch;

    background: #4C4D55;

    box-sizing: border-box;
}

.stats-grid__content-row {
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: stretch;
            align-items: stretch;
    position: relative;

    box-sizing: border-box;
    border-top: none;
}

.stats-grid__content-row:hover {
    background: #66676d;
}

.stats-grid__header-cell {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: center;
            justify-content: center;

    box-sizing: border-box;
    padding: 15px 10px;

    text-align: center;
    font-weight: 500;

    color: #c9cbd2;

    background: #4C4D55;
}

.stats-grid__header-cell[class*="value"],
.stats-grid__header-cell[class*="detected"] {
    color: #fff;
    font-weight: 500;
}

.stats-grid__content-cell {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: center;
            justify-content: center;

    box-sizing: border-box;
    padding: 5px 10px;

    color: #c9cbd2;

    text-align: center;
}

.stats-grid__content-cell[class*="value"],
.stats-grid__content-cell[class*="detected"] {
    color: #fff;
    font-weight: 500;
}

.stats-grid__header-cell-content {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.stats-grid__content-cell-content {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.stats {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.stats-header {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    background: #25272f;
    height: 48px;
    margin-top: 2px;
    padding: 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.stats-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
}

.stats-status {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.stats-user-selector {
    width: 200px;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.stats-content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
    overflow: auto;
}

.stats-error-message {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.stats-error-message__button {
    padding-top: 10px;
}

.stats-cards {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 24px 20px;
}

.stats-card-item {
    margin-right: 20px;
}

.stats-card-item-summary,
.stats-card-item-info {
    width: 280px;
    height: 160px;
}

.stats-card-item-summary__content,
.stats-card-item-info__content {
    padding: 16px 14px;
}

.stats-card-item-summary__header,
.stats-card-item-info__header {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #C9CBD2;
}

.stats-card-item-summary__price,
.stats-card-item-info__price {
    padding-top: 24px;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;/* identical to box height */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    color: #00AD66;
}

.stats-stats {
    border-radius: 5px;
    margin: 20px 20px 0;
}

.stats-stats__tabs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    background: #25272F;
}

.stats-stats__tab {
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 0 24px;
    cursor: pointer;
}

.stats-stats__tab__m-selected {
    background: #4C4D55;
    cursor: default;
}

.stats-stats__grid {
    overflow-x: auto;
    overflow-y: auto;
    background: #57585F;
}

.form-custom-select {
	position: relative;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	        user-select: none;
	border-radius: 5px;
	background-color: #45464e;
}

.form-custom-select.form-custom-select__m-opened {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	position: relative;
}

.form-custom-select.form-custom-select__m-opened.form-custom-select__m-axis-v__m-pos-start {
	border-radius: 0 0 5px 5px;
}

.form-custom-select.form-custom-select__m-opened.form-custom-select__m-axis-v__m-pos-end {
	border-radius: 5px 5px 0 0;
}

.form-custom-select__m-disabled:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(69,70,78,.7);
	border-radius: 5px;
	z-index: 2;
	cursor: default;
}

.form-custom-select__header {
	height: 28px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}


.form-custom-select__header-content {
	position: relative;
	padding: 0 30px 0 11px;
	width: 100%;
	font-weight: 500;
}

.form-custom-select__indicator-wrapper {
	position: absolute;
	top: 50%;
	right: 9px;
	margin-top: -2px;
}


.form-custom-select__indicator {
	border-top: 5px rgba(255, 255, 255, 0.3) solid;
	border-left: 5px transparent solid;
	border-right: 5px transparent solid;
}

.form-custom-select.form-custom-select__m-opened .form-custom-select__indicator {
	border-top: none;
	border-bottom: 5px rgba(255, 255, 255, 0.3) solid;
	border-left: 5px transparent solid;
	border-right: 5px transparent solid;
}

.form-custom-select__items {
	background-color: #45464e;
	max-height: 250px;
	box-sizing: border-box;
	overflow: auto;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	padding: 3px 0;
	font-size: 12px;
	color: #fff;
}

.form-custom-select__items__m-axis-v__m-pos-start {
	border-radius: 5px 5px 0 0;
}

.form-custom-select__items__m-axis-v__m-pos-end {
	border-radius: 0 0 5px 5px;
}

.form-custom-select__item {
	cursor: pointer;
	-webkit-user-select: none;
	        user-select: none;
}

.form-custom-select__item__m-selected {
	background: #25272f;
	cursor: default;
}

.form-custom-select__item-content {
	position: relative;
	padding: 5px 0 5px 11px;
	white-space: normal;

	display: -webkit-flex;

	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-align-items: center;
	        align-items: center;
}

.form-custom-select__item-content:hover {
	background-color: #66676d;
}

.form-custom-select__item-content__m-disabled {
	opacity: .7;
	cursor: not-allowed;
}

.ui-huddle-input {
	background-color: #25272F;
	border: 1px #414249 solid;
	border-radius: 3px;
	height: 45px;
	color: #fff;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	cursor: pointer;
}

.ui-huddle-input__m-active {
	background-color: #1B1D24;
}

.ui-huddle-input__content {
	padding: 0 8px 0 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 15px;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.ui-huddle-input__arrow {
	width: 41px;
	height: 41px;
	background-image: url(/static/media/arrow.b52f2550.svg);
}

.ui-huddle-input__m-active .ui-huddle-input__arrow {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.ui-huddle-input__item {
	padding: 0 8px;
	height: 40px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-align-items: center;
	        align-items: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #999;
	cursor: pointer;
}

.ui-huddle-input__item:hover {
	background-color: #1B1D24;
}

.ui-huddle-input__item__m-selected {
	background-color: #1B1D24;
	cursor: default;
}

.ui-huddle-input-bg {
	background: transparent;
	box-shadow: none !important;
}

.ui-huddle-input-header {
	height: auto;
}

.ui-huddle-input-items {
	background-color: #25272F;
	border: 1px #414249 solid;
	border-radius: 3px;
	box-shadow: none !important;
}

.ui-huddle-input__tx-code {
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	padding: 0 8px 0 0;
}

.datepicker-month {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: column nowrap;
	        flex-flow: column nowrap;
	-webkit-user-select: none;
	        user-select: none;

	margin: 0 16px 16px 0;
}

.datepicker-month:focus {
	outline: none;
}

.datepicker-navbar {
	position: absolute;
	left: 0;
	right: 0;
	top: 2px;
}

.datepicker-navbar__nav-button {
	position: absolute;
	width: 25px;
	height: 25px;
	cursor: pointer;
	line-height: 25px;
	box-sizing: border-box;
}

.datepicker-navbar__nav-button:before {
	font-size: 22px;
	vertical-align: middle;
}

.datepicker-navbar__nav-button__m-prev {
	left: 5px;
}

.datepicker-navbar__nav-button__m-prev:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: url(/static/media/arrow-thin-left.1773e6ca.svg) no-repeat;
	background-size: 22px 22px;
}

.datepicker-navbar__nav-button__m-next {
	right: 21px;
	text-align: right;
}

.datepicker-navbar__nav-button__m-next:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: url(/static/media/arrow-thin-right.b6822757.svg) no-repeat;
	background-size: 22px 22px;
}

.datepicker-navbar__nav-button__m-disabled {
	cursor: default;
	opacity: .5;
}

.datepicker-navbar__nav-button__m-disabled:before {
	 color: #ccc;
}

.datepicker-navbar__nav-button:focus,
.datepicker-navbar__nav-button:active {
	 outline: none;
}

.datepicker-caption {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
	padding: 0 20px;
}

.datepicker-caption__controls {
	 display: -webkit-flex;
	 display: flex;
	 -webkit-flex-flow: row nowrap;
	         flex-flow: row nowrap;
	 -webkit-justify-content: center;
	         justify-content: center;
	 -webkit-align-items: center;
	         align-items: center;
 }

.datepicker-caption__selector {
	box-sizing: border-box;
	padding: 0 5px;
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
}

.datepicker-caption:first-child {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.datepicker-caption:last-child {
	width: 90px;
}

.datepicker-weekdays {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
}

.datepicker-weekdays__row {
	display: table-row;
}

.datepicker-weekday {
	display: table-cell;
	padding: 8px 0;
	min-width: 40px;
	font-size: 12px;
	text-align: center;
	color: #97989c;
}

.datepicker-weekday abbr {
	text-decoration: none;
}

.datepicker-body {
	display: table-row-group;
}

.datepicker-week {
	display: table-row;

}
.datepicker-week:last-child .datepicker-day {
	border-bottom-color: #4c4d55;
}

.datepicker-day {
	display: table-cell;
	padding: 8px 0;
	min-width: 40px;
	border: 1px solid #4c4d55;
	border-bottom-color: transparent;
	border-right-color: transparent;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	position: relative;
}

.datepicker-day.disabled {
	cursor: default;
	background-color: #25272f;
	opacity: .7;
}

.datepicker-day.outside {
	cursor: default;
}

.datepicker-day.today {
	color: #00ad66;
	font-weight: 500;
 }

.datepicker-day.selected:not(.disabled):not(.outside) {
	 background-color: #00ad66;
	 border-right: 1px #00ad66 solid;
	 border-bottom: 1px #00ad66 solid;
 }

.datepicker-day.disabled {
	cursor: default;
	background-color: #25272f;
	opacity: .5;
	color: #dce0e0;
}

.datepicker-day.outside {
	cursor: default;
	color: #dce0e0;
}

.datepicker-day.selected:not(.disabled):not(.outside) {
	color: #fff;
}

.datepicker-day:focus,
.datepicker-day:active {
	border: 1px #4c4d55 solid;
}

.datepicker-day.selected:not(.disabled):not(.outside) {
	border-top: 1px #4c4d55 solid;
	border-left: 1px #4c4d55 solid;
}

.datepicker-day:last-child {
	border-right-color: #4c4d55;
}

.datepicker-day:focus,
.datepicker-day:active {
	outline: none;
}

.datepicker-week-number {
	display: table-cell;
	padding: 8px;
	text-align: right;
	vertical-align: middle;
	min-width: 16px;
	font-size: 12px;
	cursor: pointer;
	color: #8b9898;
}

.datepicker-footer {
	padding-top: 10px;
	text-align: center;
}

.datepicker {
	display: inline-block;
	padding-top: 10px;
}

.datepicker__inner {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	position: relative;
	-webkit-user-select: none;
	        user-select: none;

	margin-right: -16px;
	margin-bottom: -16px;
}

.datepicker:focus,
.datepicker:active,
.datepicker__inner:focus {
	 outline: none;
}

.datepicker__m-interaction-disabled .datepicker-day {
	cursor: default;
}


/* DayPicker styles */

.DayPicker {
	display: inline-block;
}

.DayPicker-wrapper {
	position: relative;
	-webkit-user-select: none;
	        user-select: none;
	padding-bottom: 1rem;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.DayPicker-Months {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: center;
	        justify-content: center;
}

.DayPicker-Month {
	display: table;
	border-collapse: collapse;
	border-spacing: 0;
	-webkit-user-select: none;
	        user-select: none;
	margin: 0 1rem;
	margin-top: 1rem;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
	position: absolute;
	cursor: pointer;
	top: 1rem;
	right: 1.5rem;
	margin-top: 2px;
	color: #8b9898;
	width: 1.25rem;
	height: 1.25rem;
	display: inline-block;
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: center;
}

.DayPicker-NavButton:hover {
	opacity: 0.8;
}

.DayPicker-NavButton--prev {
	margin-right: 1.5rem;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
	display: none;
}

.DayPicker-Caption {
	padding: 0 0.5rem;
	display: table-caption;
	text-align: left;
	margin-bottom: 0.5rem;
}

.DayPicker-Caption > div {
	font-size: 1.15rem;
	font-weight: 500;
}

.DayPicker-Weekdays {
	margin-top: 1rem;
	display: table-header-group;
}

.DayPicker-WeekdaysRow {
	display: table-row;
}

.DayPicker-Weekday {
	display: table-cell;
	padding: 0.5rem;
	font-size: 0.875em;
	text-align: center;
	color: #8b9898;
}

.DayPicker-Weekday abbr[title] {
	border-bottom: none;
	text-decoration: none;
}

.DayPicker-Body {
	display: table-row-group;
}

.DayPicker-Week {
	display: table-row;
}

.DayPicker-Day {
	display: table-cell;
	padding: 0.5rem;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	outline: none;
}

.DayPicker-WeekNumber {
	display: table-cell;
	padding: 0.5rem;
	text-align: right;
	vertical-align: middle;
	min-width: 1rem;
	font-size: 0.75em;
	cursor: pointer;
	color: #8b9898;
	border-right: 1px solid #eaecec;
}

.DayPicker--interactionDisabled .DayPicker-Day {
	cursor: default;
}

.DayPicker-Footer {
	padding-top: 0.5rem;
}

.DayPicker-TodayButton {
	border: none;
	background-image: none;
	background-color: transparent;
	box-shadow: none;
	cursor: pointer;
	color: #4a90e2;
	font-size: 0.875em;
}

/* Default modifiers */

.DayPicker-Day--today {
	color: #d0021b;
	font-weight: 700;
}

.DayPicker-Day--outside {
	cursor: default;
	color: #8b9898;
}

.DayPicker-Day--disabled {
	color: #dce0e0;
	cursor: default;
	/* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
	background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
	color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	position: relative;
	color: #00ad66;
	background-color: #4a90e2;
	border-radius: 100%;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
	background-color: #00ad66;
	border-radius: 50%;
}

/* DayPickerInput */

.DayPickerInput input{
	background: transparent;
	border: none;
	color: white;
	padding: 5px 0;
	max-width: 75px;
}


.DayPickerInput input:focus {
	outline: none;
	border-bottom: green;
}

.DayPickerInput-OverlayWrapper {
	position: relative;
}

.DayPickerInput-Overlay {
	left: 0;
	z-index: 1;
	position: absolute;
	background: #4c4d55;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPickerInput-OverlayWrapper {
    position: absolute;
    right: 20px;
    z-index: 101;
}

.DayPickerInput-Overlay {
    right: 20px;
    left: auto;
    position: absolute;
    padding: 5px;
    background: #4c4d55;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPickerInput__m-invalid {
    border-bottom: 2px #f44336 solid;
}

.ui-huddle-datepicker__datepicker-icon {
	width: 41px;
	height: 41px;
	background-image: url(/static/media/datepicker.6fbb89e3.svg);
}

.ui-huddle-tile-filter {
	position: relative;
	background-color: #25272F;
	padding: 30px 20px;
	border-radius: 3px;
	cursor: pointer;
}

.ui-huddle-tile-filter__show-btn {
	width: 77px;
	height: 30px;
	background-image: url(/static/media/show-btn.723e9143.svg);

	position: absolute;
	top: 10px;
	right: 10px;

	display: none;
}

.ui-huddle-tile-filter__hide-btn {
	width: 30px;
	height: 30px;
	background-image: url(/static/media/hide-btn.21bf9b51.svg);

	position: absolute;
	top: 10px;
	right: 10px;

	display: none;
}



.ui-huddle-tile-filter__m-active {
	background-color: #333542;
}

.ui-huddle-tile-filter__m-disabled {
	cursor: default;
}

.ui-huddle-tile-filter__m-disabled .ui-huddle-tile-filter__content{
	opacity: .7;
}

.ui-huddle-tile-filter:hover .ui-huddle-tile-filter__show-btn,
.ui-huddle-tile-filter__m-active .ui-huddle-tile-filter__show-btn,
.ui-huddle-tile-filter__m-active .ui-huddle-tile-filter__hide-btn,
.ui-huddle-tile-filter:hover .ui-huddle-tile-filter__hide-btn {
	display: block;
}


.ui-huddle-tile-filter-counter {
	position: relative;
}

.ui-huddle-tile-filter-counter__count {
	color: #009B6D;
	font-weight: 300;
	font-size: 42px;
	line-height: 41px;
	padding: 0 0 6px 0;
}

.ui-huddle-tile-filter-counter__text {
	color: #FFF;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
}

.huddle-grid {
	font-size: 12px;
	position: absolute;
	inset: 0;
}

.huddle-grid__header-row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: stretch;
	        align-items: stretch;

	box-sizing: border-box;
}

.huddle-grid__content-row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	position: relative;

	box-sizing: border-box;
	border-top: none;

	min-height: 45px;
}

.huddle-grid__content-row:hover {
	background: #25272F;
}

.huddle-grid__header-cell {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;

	display: -webkit-flex;

	display: flex;
	-webkit-flex-flow: column nowrap;
	        flex-flow: column nowrap;
	-webkit-align-items: stretch;
	        align-items: stretch;
	-webkit-justify-content: center;
	        justify-content: center;

	box-sizing: border-box;
	padding: 15px 10px;

	font-weight: 700;
	font-size: 12px;
	line-height: 16px;

	color: #ccc;

	background: #333542;
}

.huddle-grid__header-cell:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.huddle-grid__header-cell:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.huddle-grid__header-cell[class*="value"],
.huddle-grid__header-cell[class*="detected"] {
	color: #fff;
	font-weight: 500;
}

.huddle-grid__content-cell {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;

	display: -webkit-flex;

	display: flex;
	-webkit-flex-flow: column nowrap;
	        flex-flow: column nowrap;
	-webkit-align-items: stretch;
	        align-items: stretch;
	-webkit-justify-content: center;
	        justify-content: center;

	box-sizing: border-box;
	padding: 5px 10px;

	color: #c9cbd2;
}

.huddle-grid__content-cell[class*="value"],
.huddle-grid__content-cell[class*="detected"] {
	color: #fff;
	font-weight: 500;
}

.huddle-grid__header-cell-content {
	-webkit-flex: 0 1 auto;
	        flex: 0 1 auto;

	overflow: hidden;
	text-overflow: ellipsis;
}

.huddle-grid__content-cell-content {
	-webkit-flex: 0 1 auto;
	        flex: 0 1 auto;

	overflow: hidden;
	text-overflow: ellipsis;
}

.huddle-grid__label {
	height: 28px;
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	border-radius: 3px;
	background-color: #C9C2C2;
	color: #000;
	-webkit-align-items: center;
	        align-items: center;
	padding: 0 10px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	margin: 2px 3px 2px 0;
}

.huddle-grid__label-inner {
	height: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	border-radius: 3px;
	color: #FFF;
	background-color: rgba(0, 0, 0, .2);
	-webkit-align-items: center;
	        align-items: center;
	padding: 0 10px;
	margin-left: 5px;
}

.huddle-grid-last-visit-summary {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
	border-radius: 3px;
	-webkit-align-items: center;
	        align-items: center;
	padding: 2px 10px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	margin: 2px 3px 2px 0;
	color: #000;
}

.huddle-grid-last-visit-summary__name {
	margin-right: 10px;
}

.huddle-grid-last-visit-summary__label {
	height: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	border-radius: 3px;
	color: rgba(255, 255, 255, .7);
	-webkit-align-items: center;
	        align-items: center;
	padding: 0 5px;
	margin: 2px 1px;
	background-color: rgba(0, 0, 0, .2);
}

.huddle-grid-last-visit-summary__label-name {
	color: #FFF;
}

.huddle-grid-last-visit-summary__m-pathological {
	background-color: #CDB3D2;
}

.huddle-grid-last-image {
	display: block;
	outline: none;
	border: 1px #8A8B94 solid;
	border-radius: 3px;
	padding: 3px 6px;
}

.huddle-grid-last-image__date {
	font-size: 12px;
	color: #999;
}

.huddle-grid-last-image__summary {
	font-size: 12px;
	color: #FFF;
}

.rc-slider {
    position: relative;
    height: 12px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #e9e9e9;
    height: 4px;
    border-radius: 6px;
}
.rc-slider-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: #fcfcfc;
}
.rc-slider-handle {
    position: absolute;
    margin-left: -7px;
    margin-top: -5px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #fbfbfb;
    background-color: #fff;
    touch-action: pan-x;
}
.rc-slider-handle:focus {
    outline: none;
}
.rc-slider-handle-click-focused:focus {
    border-color: #fbfbfb;
    box-shadow: unset;
}
.rc-slider-handle:hover {
    border-color: #f9f9f9;
}
.rc-slider-handle:active {
    border-color: #f9f9f9;
    box-shadow: 0 0 5px #f9f9f9;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}
.rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
}
.rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;
}
.rc-slider-mark-text-active {
    color: #666;
}
.rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
}
.rc-slider-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
}
.rc-slider-dot-active {
    border-color: #fbfbfb;
}
.rc-slider-disabled {
    background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
    border-color: #ccc;
    box-shadow: none;
    background-color: #fff;
    cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
}
.rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
    height: 100%;
    width: 4px;
}
.rc-slider-vertical .rc-slider-track {
    left: 5px;
    bottom: 0;
    width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
    margin-left: -5px;
    margin-bottom: -7px;
    touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
}
.rc-slider-vertical .rc-slider-step {
    height: 100%;
    width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
    left: 2px;
    margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
    margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
    margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    -webkit-animation-duration: .3s;
            animation-duration: .3s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    display: block !important;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
    -webkit-animation-duration: .3s;
            animation-duration: .3s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    display: block !important;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    -webkit-animation-name: rcSliderTooltipZoomDownIn;
            animation-name: rcSliderTooltipZoomDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    -webkit-animation-name: rcSliderTooltipZoomDownOut;
            animation-name: rcSliderTooltipZoomDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes rcSliderTooltipZoomDownIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
    }
    100% {
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
}
@keyframes rcSliderTooltipZoomDownIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
    }
    100% {
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
    0% {
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
    100% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
    }
}
@keyframes rcSliderTooltipZoomDownOut {
    0% {
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
    100% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
    }
}
.rc-slider-tooltip {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
    display: none;
}
.rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
}

.rc-slider-disabled {
    background: transparent;
}

.ui-huddle-layout {
	padding: 10px 40px 0;
	
	display: -webkit-flex;
	
	display: flex;
	-webkit-flex-flow: column nowrap;
	        flex-flow: column nowrap;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	box-sizing: border-box;
}

.ui-huddle-layout__top-row {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	margin: 36px 0 22px 0
}

.ui-huddle-layout__patients {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
	font-weight: 700;
	font-size: 34px;
	line-height: 41px;
	min-width: 200px;
}

.ui-huddle-layout__filters {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
}

.ui-huddle-layout__filter {
	/*flex: 1 1 auto;*/
	max-width: 270px;
	width: 25%;
}

.ui-huddle-layout__filter:not(:first-child) {
	margin-left: 15px;
}

.ui-huddle-layout__cards {
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
}

.ui-huddle-layout__items {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	margin-top: 12px;
	min-height: 300px;
	position: relative;
}

.ui-huddle-layout__empty {
	padding: 24px;
	text-align: center;
}

.ui-huddle-layout__slider-header {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	font-size: 15px;
	color: #FFF;
	margin-top: 5px;
	-webkit-user-select: none;
	        user-select: none;
}

.huddle-grid__content-cell__m-findings .huddle-grid__content-cell-content {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
}

 .magnifying-glass-image {
     position: relative;
 }

.magnifying-glass-image img {
    display: block;
}

.magnifying-glass {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.magnifying-glass__viewport {
    position: absolute;
    left: 0;
    top: 0;
}

.magnifying-glass-image-status {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.magnifying-glass-image-status__content {
    margin: auto;
}

.image-shape-box__m-highlighted {
    stroke-opacity: .5;
}

.image-shape-box__controls {
    cursor: move;
}

.image-shape-box__resize-control {
    cursor: -webkit-grab;
    cursor: grab;
}

.image-shape-box__action-control {
    pointer-events: bounding-box;
}
.image-shape-box__action-control,
.image-shape-box__action-control-box {
    cursor: pointer;
}

.image-shape-box__action-control:hover {
    stroke-opacity: .5;
    stroke-width: 2.8;
}

.draw-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: crosshair;
}


.draw-box__svg {
    width: 100%;
    height: 100%;
    /*pointer-events: bounding-box;*/
}

.image-shape-polygon {

}

.image-shape-polygon__m-highlighted {
    stroke-opacity: .5;
}

.image-shape-polygon__controls__m-move {
    cursor: move;
}

.image-shape-polygon__resize-control {
    cursor: -webkit-grab;
    cursor: grab;
}

.image-shape-polygon__resize-control__m-deny-edit {
    cursor: default;
}

.draw-polygon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: default;
}

.draw-polygon__svg {
    width: 100%;
    height: 100%;
    pointer-events: bounding-box;
}

.image-shape-boneloss__label {
    fill: #fff;
    font: 11px monospace;
    pointer-events: none;
}

.draw-boneloss {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: default;
}

.draw-boneloss__svg {
    width: 100%;
    height: 100%;
    pointer-events: bounding-box;
}

.draw-boneloss__hint {
    position: absolute;
    width: 20px;
    height: 20px;
    font: 11px monospace;

    margin: -15px 0 0 -15px;
}

.history-state-button__icon-redo {
    position: relative;
    padding-right: 15px;
}

.history-state-button__icon-redo:before {
    content: '';
    background: url(/static/media/redo.82c42fa9.svg) no-repeat;
    width: 15px;
    height: 16px;
    position: absolute;
    top: 0;
    right: -7px;
}

.history-state-button__icon-undo {
    position: relative;
    padding-left: 15px;
}

.history-state-button__icon-undo:before {
    content: '';
    background: url(/static/media/undo.fa8cbc00.svg) no-repeat;
    width: 15px;
    height: 16px;
    position: absolute;
    top: 0;
    left: -7px;
}

.canvas-controls {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    position: relative;
}

.canvas-controls__image-position {
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 500;
    padding: 0 10px;
}
.canvas-controls__history {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 8px;
}

.canvas-controls__history-button {
    padding-left: 12px;
}

.canvas-controls__history-button__m-reset {
    padding-left: 12px;
}

.canvas-controls__images-navigation {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-left: 10px;
}

.canvas-controls__controls {
    padding-right: 12px;
}

.canvas-controls__controls:nth-child(2) {
    padding-right: 0;
}

.canvas-controls__images-navigation-button:last-child {
    padding-right: 0;
}

.canvas-controls__prev-image-button {
    background: url(/static/media/prev.03420941.svg) no-repeat;
    width: 8px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.canvas-controls__next-image-button {
    background: url(/static/media/next.d5e15d29.svg) no-repeat;
    width: 8px;
    height: 12px;
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 1px;
}

.canvas-controls__controls-button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: solid 2px #349563;
    background: transparent;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.canvas-controls__controls-button__m-small {
    width: 30px;
    height: 30px;
}

.canvas-controls__controls-button:focus {
    outline: none;
}

.canvas-controls__controls-button__m-disabled {
    opacity: .5;
    cursor: default;
}

.canvas-controls__controls-button__m-first-image {
    background: url(/static/media/first.4d425037.svg) center center no-repeat;
}

.canvas-controls__controls-button__m-last-image {
    background: url(/static/media/last.2bfc5d19.svg) center center no-repeat;
}
.canvas-controls__image-position-input {
    display: inline-block;
    background: none;
    color: #fff;
    width: 30px;
    font-size: 12px;
    font-weight: 500;
    border: none;
    text-align: right;
}

.canvas-controls-fda-complete__icon-complete {
    position: relative;
    padding-right: 15px;
}

.canvas-controls-fda-complete__icon-complete:before {
    content: '';
    background: url(/static/media/tick.6cb0ff85.svg) no-repeat;
    width: 15px;
    height: 16px;
    position: absolute;
    top: 0;
    right: -7px;
}

.label-list-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
}


.label-list-wrapper__items {
    position: relative;
    height: 100%;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    margin: 16px 0 0 0;
}

.label-list-wrapper__items__m-with-controls {
    margin-top: 0;
}

.label-list-wrapper__items__m-with-filters {
    margin-top: 0;
}

.label-list-wrapper__filters {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    height: 30px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 0 21px;
}

.label-list-wrapper__filters__m-with-confidence-filter {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.label-list-wrapper__filters__m-with-offset {
    margin: 16px 0 0 0;
}

.label-list-wrapper__filter {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
    font-size: 14px;
    line-height: 16px;
    max-width: 50%;
}

.label-list-wrapper__filter__m-confidence {
    width: 150px;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.label-list-wrapper__filter-label {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 11px;
    color: #c9cbd2;
}

.label-list-wrapper__filter-value {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #c9cbd2;
}

.label-list-wrapper__filter-control {
    position: relative;
    top: 7px;
}

.label-list-wrapper__m-disabled:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
}

.label-list-wrapper__controls {
    height: 61px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 21px;
    position: relative;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.findings-wrapper__controls {
    height: 61px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 21px;
    position: relative;
}

.label-list-wrapper__controls:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: rgba(0,0,0,.5);
    left: 21px;
    right: 21px;
    bottom: 13px;
}

.label-list-wrapper__show-all-classes-toggle {
    font-size: 14px;
    color: #c9cbd2;
    font-weight: 500;
    cursor: pointer;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.label-list-wrapper__confirm-all-classes-toggle {
    font-size: 14px;
    color: #00ad66;
    font-weight: 500;
    cursor: pointer;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;

}

.label-list-wrapper__remove-all-classes-toggle {
    font-size: 14px;
    color: #ef5350;
    font-weight: 500;
    cursor: pointer;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    padding: 0 0 0 20px;
}

.label-list-wrapper-class-filter-dropdown {
    background: #2F313A;
    font-size: 12px;
}

.label-list-wrapper-class-filter-dropdown__item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    -webkit-align-items: center;
            align-items: center;

    font-size: 12px;
    padding: 2px 3px;
    word-break: break-all;
}

.label-list-wrapper-class-filter-dropdown__item-control {
    padding-right: 2px;
}

.diagnosis-select {
	width: 180px;
	height: 28px;
}

.diagnosis-select__header {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	position: relative;
	padding: 0 20px 0 10px;
	width: 100%;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 12px;
}

.diagnosis-select__header-value {
	margin-right: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.diagnosis-select__indicator {
	position: absolute;
	top: 50%;
	right: 9px;
	margin-top: -2px;
}

.diagnosis-select__item {
	position: relative;
	padding: 5px 0 5px 11px;
	white-space: normal;

	display: -webkit-flex;

	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 12px;
	color: #fff;
	word-break: break-all;
	cursor: pointer;
}

.diagnosis-select__item__m-required {
	padding-right: 27px;
}

.diagnosis-select__item__m-disabled {
	opacity: .5;
	cursor: default;
}

.diagnosis-select__item:hover {
	background-color: #66676d;
}

.diagnosis-select__required-box {
	position: absolute;
	right: 10px;
	top: 5px;
	width: 14px;
	height: 14px;
	background-size: 14px;
}

.diagnosis-select__hot-key {
	background: #97989c;
	border-radius: 4px;
	color: #000;
	font-size: 11px;
	height: 15px;
	width: 17px;
	box-sizing: border-box;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-right: 5px;
	border-bottom: 2px #1b1d24 solid;
	border-right: 2px #1b1d24 solid;
	-webkit-flex: 0 0 auto;
	        flex: 0 0 auto;
}

.label-tooth-selector__header {
    position: relative;
    padding: 0 0 0 4px;
    width: 100%;
    font-weight: 500;
    font-size: 10px;
}

.label-tooth-selector__indicator {
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -2px;
}

.label-tooth-selector__item {
    position: relative;
    padding: 5px 0 5px 4px;
    font-size: 10px;
}

.label-tooth-selector__item:hover {
    background-color: #66676d;
}

.label-tooth-selector__required-box {
    position: absolute;
    right: 10px;
    top: 7px;
    width: 14px;
    height: 14px;
    background-image: url(/static/media/required-box.9d33e9e2.svg);
    background-size: 14px;
}

.label-tooth-selector__select {
    width: 36px;
    height: 28px
}

.label-tooth-controls {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    height: 40px;
}

.label-tooth-controls__tooth-key-select {
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: 10px;
}

.label-tooth-controls__form {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.label-tooth-controls__remove-tooth-button {
    font-size: 14px;
    color: #ef5350;
    font-weight: 500;
    cursor: pointer;
    padding: 0 0 0 5px;
}

.label-tooth-controls-diagnosis-selector {
    font-size: 14px;
}

.tooth-info-tooth-select__header {
    position: relative;
    padding: 0 0 0 4px;
    width: 100%;
    font-weight: 500;
    font-size: 10px;
}

.tooth-info-tooth-select__indicator {
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -2px;
}

.tooth-info-tooth-select__item {
    position: relative;
    padding: 5px 0 5px 4px;
    font-size: 10px;
}

.tooth-info-tooth-select__item:hover {
    background-color: #66676d;
}

.tooth-info-tooth-select__required-box {
    position: absolute;
    right: 10px;
    top: 7px;
    width: 14px;
    height: 14px;
    background-image: url(/static/media/required-box.9d33e9e2.svg);
    background-size: 14px;
}

.tooth-info-tooth-select__select {
    width: 36px;
    height: 28px
}

.label-tags-selector {
    width: 180px;
    height: 28px;
}

.label-tags-selector__item {
    position: relative;
    padding: 5px 0 5px 11px;
    white-space: normal;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.label-tags-selector__item:hover {
    background-color: #66676d;
}

.label-tags-selector__item__m-disabled {
    opacity: .5;
    cursor: default;
}

.label-tags-selector__hot-key {
    background: #97989c;
    border-radius: 4px;
    color: #000;
    font-size: 11px;
    height: 15px;
    width: 17px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 5px;
    border-bottom: 2px #1b1d24 solid;
    border-right: 2px #1b1d24 solid;
}

.label-tags {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
}

.label-tags__row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 5px 0;
}

.label-tags__tag-icon {
    width: 16px;
    height: 16px;
    background: url(/static/media/tag.433ddbf2.svg) no-repeat center;

    background-size: contain;
    -webkit-align-self: center;
            align-self: center;
}

.label-tags__tag-value {
    padding: 3px 0;
    font-size: 12px;
    color: #acadb2;
}

.label-tags__select {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 0 30px 0 14px;
}

.label-tags__select__m-add-new {
    padding: 0 54px 0 14px;
}

.label-tags__controls {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.label-tags__remove-button {
    width: 24px;
    height: 24px;
    background: url(/static/media/remove-button.fb31c727.svg) no-repeat center;
    cursor: pointer;
}

.tooltip {
    border-radius: 5px;
    background-color: #acadb2;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #66676d;
    font-size: 12px;
    color: #45464e;
    padding: 7px;

    margin: 10px 0 0 0;
    text-align: left;
}

.tooltip__m-global {
    margin: 0;
    position: relative;
}

.tooltip-global-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 120;
    opacity: 0;
}

.tooltip__m-cursor-x-left.tooltip__m-cursor-y-top:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 10px;
    border-bottom: 5px #66676d solid;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
}

.tooltip__m-cursor-x-right.tooltip__m-cursor-y-top:before {
    content: '';
    position: absolute;
    top: -5px;
    right: 10px;
    border-bottom: 5px #66676d solid;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
}

.controlled-tooltip {
    display: inline-block;
}

.label {
    font-size: 14px;
    cursor: pointer;
}

.label-main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-align-items: center;
            align-items: center;
}

.label-name {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 8px 3px;
}

.label-selector {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 35px;
}

.label-measure-value {
    text-align: right;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    padding-right: 7px;
}

.label-confirm {
    width: 24px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.label-remove {
    width: 24px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.label-remove-button {
    width: 24px;
    height: 24px;
    background: url(/static/media/remove-button.fb31c727.svg) no-repeat center;
    cursor: pointer;
}

.label-measure-value-indicator {
    height: 1px;
    background: #25272e;
    position: relative;
}

.label-measure-value-indicator-line {
    width: 20%;
    height: 100%;
    margin-top: 2px;
}

.label-list-item {
    display: -webkit-flex;
    display: flex;
    padding: 4px 15px 4px 20px;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.label-list-item__m-edit-mode {
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
}

.label-list-item__tooth {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 36px;
    padding-right: 10px;
}

.label-list-item__tooth-missing {
    width: 28px;
    height: 28px;
    border: 2px #fff dashed;
    border-radius: 50%;
    cursor: pointer;
}

.label-list-item__main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.label-list-item__tooth-key {
    width: 28px;
    height: 28px;
    border: 2px transparent solid;
    border-radius: 50%;
    font-size: 11px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
}

.label-list-item__label-child {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    padding: 4px 0;
}

.label-list-item__label-child__m-selected {
    background: #15171d;
    margin: 0 -15px 0 -20px;
    padding: 0 15px 0 20px;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.label-list-item__label-child-controls {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 36px;
    padding-right: 10px;
    padding-top: 4px;
}

.label-list-item__label-child-main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.label-list-item__label-children {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.label-list-item__label-control {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 36px;
    padding-right: 10px;
    padding-top: 4px;
}


.label-list-item__label-content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.label-list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
}

.label-list__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.label-list__label__m-selected {
    background-color: #2f313a;
}

.shift-numbering-button {
    width: 22px !important;
    height: 22px !important;
    padding: 0 !important;
}

.shift-numbering-button__content {
    width: 7px;
    height: 13px;
}
.shift-numbering-button__content__m-left {
    background: url(/static/media/prev.03420941.svg) no-repeat;
    margin: 1px 0 0 4px;
}

.shift-numbering-button__content__m-right {
    background: url(/static/media/next.d5e15d29.svg) no-repeat;
    margin: 1px 0 0 6px;
}

.dental-notation-diagram {
    position: relative;
    background-color: #25272f;
}

.dental-notation-diagram__image {
    display: block;
    cursor: pointer;
    padding-left: 15px;
}

.dental-notation-diagram__top-row,
.dental-notation-diagram__bottom-row {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding: 10px 12px;
    display: -webkit-flex;
    display: flex;
}

.dental-notation-diagram__tooth-key {
    display: inline-block;
    font-size: 10px;

    color: #838383;

    border: 2px transparent solid;
    border-radius: 50%;

    width: 20px;
    height: 20px;
    box-sizing: border-box;
    text-align: center;
    line-height: 16px;
}

.dental-notation-diagram__tooth-key__m-with-pathologies {
    color: #ef5350;
}

.dental-notation-diagram__tooth-key__m-selected {
    border-color: #97989c;
    color: #97989c;
}

.dental-notation-diagram__tooth-key__m-selected.dental-notation-diagram__tooth-key__m-has-primary-box {
    color: #00ad66;
    border-color: #00ad66;
}

.dental-notation-diagram__tooth-key__m-selected.dental-notation-diagram__tooth-key__m-no-pathologies:not(.dental-notation-diagram__tooth-key__m-has-primary-box) {
    border-color: #979797;
}

.dental-notation-diagram__tooth-key__m-selected.dental-notation-diagram__tooth-key__m-with-pathologies {
    color: #ef5350;
    border-color: #ef5350;
}

.dental-notation-diagram__tooth-key__m-notified {
    color: #FFF024;
}

.dental-notation-diagram__tooth-finding {
    white-space: nowrap;
    padding: 2px 0;
}

.dental-notation-diagram__tooltip-bottom {
    padding-top: 7px;
}

.dental-notation-diagram__shift-numbering {
    padding: 10px 15px 0 15px;
}

.dental-notation-diagram__shift-numbering-buttons {
    position: absolute;
    top: 86px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.dental-notation-diagram__shift-numbering-button-wrapper:first-child {
    margin-right: 10px;
}

.sidebar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
}

.sidebar_upper-block {
    position: relative;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.sidebar__dental-notation-diagram {
    -webkit-flex-basis: auto;
            flex-basis: auto;
}

.sidebar__dental-notation-diagram__m-disabled:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(27, 29, 36,.7);
}

.sidebar__add-diagnosis {
    -webkit-flex-basis: 82px;
            flex-basis: 82px;
    background-color: #25272f;
}

.sidebar__findings {
    overflow: hidden;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
}

.sidebar__show-all-findings-toggle {
    font-size: 14px;
    color: #97989c;
    padding: 10px 10px 10px 45px;
    cursor: pointer;
}

.sidebar__footer {
    height: 88px;
    min-height: 88px;
    -webkit-flex-basis: 88px;
            flex-basis: 88px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.sidebar__footer-controls {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;

    -webkit-align-items: center;

            align-items: center;
    padding-right: 20px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.sidebar__footer-controls__m-disabled:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
}

.sidebar__footer-controls-button {
    min-width: 145px;
    margin: 0 0 0 20px;
    box-sizing: border-box;
}

.sidebar__footer-controls-button:last-child {
    margin-right: 0;
}

.sidebar__drawing-finding {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 500;
}



.current-image-info {
    position: relative;
    color: #fff;
}

.current-image-info__item {
    display: inline-block;
    font-size: 12px;
    width: 150px;
    height: 60px;
    margin-right: 10px;
}


.current-image-info__input {
    font-size: 12px !important;
}

.current-image-info__item:last-child {
    margin-right: 0;
}

.current-image-info__property {
    color: #c9cbd2;
    padding-right: 5px;
}

.current-image-info__value {
    color: #fff;
    padding-left: 5px;
}

.current-image-info__value-text {
    font-size: 13px;
}

.current-image-info__text {
    margin-right: 5px;
    font-size: 11px;
}

.current-image-info__close-image-button {
    position: relative;
    height: 16px;
    width: 16px;
    padding: 0;
    font-size: 14px;
    border-radius: 100%;
    background: url(/static/media/close.f3ece3c2.svg) no-repeat center;
    color: #97989c;
    border: none;
    cursor: pointer;
}

.current-image-info__close-image-button:active,
.current-image-info__close-image-button:focus {
    outline: none;
}

.current-image-info__dropdown {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 151px;
    padding: 15px;
    background-color: #2f313a;
    z-index: 99;
}

.current-image-info__short {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;

    padding-left: 15px;
    -webkit-align-items: center;
            align-items: center;
    font-size: 11px;
}

.current-image-info__short-content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.current-image-info__short-content__m-first-col-padding .current-image-info__short-content-item:first-child {
    width: 45%;
}

.current-image-info__dropdown-indicator {
    background-image: url(/static/media/info.d0f54692.svg);
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
}

.current-image-info__dropdown-indicator-wrapper {
    width: 50px;
    height: 20px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.current-image-info__value-wrapper {
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.top-pane {
    width: 100%;
    position: relative;
    height: 40px;
    background-color: #2f313a;
}

.top-pane__current-image-info {
    height: 100%;
}

.toolbar {
    height: 40px;
    color: #fff;
}

.toolbar__items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.toolbar__item {
    min-width: 35px;
    height: 40px;
    background-color: #25272f;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.toolbar__item__m-small {
    min-width: 35px;
}

.toolbar__item-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.toolbar_item__dark {
    background-color: #25272f;
}

.toolbar__item__light {
    background-color: rgba(72, 73, 78, 0.61);
    border: 2px solid;
    border-color: rgb(87, 88, 94);
}

.toolbar__item__m-selected,
.toolbar__item:hover {
    background-color: #45464e;
}

.toolbar__item__m-disabled {
    min-width: 40px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: transparent;
    pointer-events: none;
}

.toolbar__item-group__m-draw-mode,
.toolbar__item__m-zoom-default,
.toolbar__item__m-rotate-right {
    margin-right: 2px;
}

.toolbar__item__m-select-mode:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/select.ba1dd39b.svg) no-repeat center center;
}

.toolbar__item__m-zoom:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/zoom.c0ad5365.svg) no-repeat center center;
}

.toolbar__item__m-zoom-in:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/zoomin.37137509.svg) no-repeat center center;
}

.toolbar__item__m-zoom-out:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/zoomout.d6630e39.svg) no-repeat center center;
}

.toolbar__item__m-zoom-default:after {
    content: '1:1';

    font-size: 12px;
}

.toolbar__item__m-draw-mode:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/edit.589f42f2.svg) no-repeat center center;
}

.toolbar__item__m-brightness {
    font-size: 10px;
    color: #c9cbd2;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding-left: 10px;
    padding-right: 10px;
}

.toolbar__item__m-contrast {
    font-size: 10px;
    color: #c9cbd2;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding-left: 10px;
    padding-right: 10px;
}


.toolbar__item__m-enhanced-image-filter {
    font-size: 10px;
    color: #c9cbd2;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding-left: 10px;
    padding-right: 10px;
    width: 120px
}

.toolbar__item__m-enhanced-image-filter-select .form-custom-select__header {
    height: 20px;
}


.toolbar__item__m-flip-horizontal:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/flip-horizontal.6490119e.svg) no-repeat center center;
}

.toolbar__item__m-flip-vertical:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/flip-vertical.0c09c68c.svg) no-repeat center center;
}

.toolbar__item__m-rotate-left:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url(/static/media/rotate-left.430a5249.svg) no-repeat center center;
}

.toolbar__item__m-rotate-right:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url(/static/media/rotate-right.fc2bd329.svg) no-repeat center center;
}

.toolbar__item-label__m-contrast,
.toolbar__item-label__m-brightness
{
    padding-bottom: 4px;
}

.toolbar__item__m-invert:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/invert.d1ad739e.svg) no-repeat center center;
    background-size: 16px 16px;
}

.toolbar__item__m-image-sharpen:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url(/static/media/image-sharpen.ba61a0a8.svg) no-repeat center center;
}

.toolbar__item__m-grid:after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url(/static/media/grid.9c937602.svg) no-repeat center center;
    background-size: 16px 16px;
}

.toolbar__item__m-bone-levels:after {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background: url(/static/media/bone_levels.7b28a268.svg) no-repeat center center;
    background-size: 32px 32px;
}

.toolbar__item__m-hide-findings:after {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background: url(/static/media/hide-findings.523ec92d.svg) no-repeat center center;
    background-size: 18px 16px;
}

.toolbar__item__m-magnifying-glass:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/magnifying-glass.24cb4132.svg) no-repeat center center;
}

.toolbar__item__m-findings-masks:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/polygon.cc02f267.svg) no-repeat center center;
    background-size: 24px;
}

.toolbar__item__m-heat-map:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/static/media/polygon.cc02f267.svg) no-repeat center center;
    background-size: 20px;
}

.toolbar__item__m-show-tissue-findings:after {
	content: '';
	display: block;
	width: 20px;
	height: 20px;
	background: url(/static/media/tissue.baeca392.svg) no-repeat center center;
}

.toolbar__item__m-analyze{
    -webkit-flex: auto;
            flex: auto;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-right: 20px;
}
.toolbar__item__m-analyze:hover {
    background-color: #25272f;
}
.toolbar__item-label {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.toolbar__item-reset-button {
    width: 10px;
    height: 10px;
    background: #97989c url(/static/media/close.b63bf912.svg) no-repeat;
    background-size: contain;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin: 1px 0 0 2px;
}

.toolbar__item-reset-button:hover {
    background-color: #eee;
}

.add-diagnosis__select {
    width: 170px;
    height: 30px;
    background-color: #2F313A;
    border-radius: 0 !important;
    font-size: 12px;
}

.add-diagnosis__select-header {
    background-color: #2F313A;
    padding-left: 10px;
}

.add-diagnosis__wrapper {
    padding: 5px;
    background-color: #25272f;
}

.add-diagnosis__select-items {
    border-radius: 0 !important;
}

.add-diagnosis__select-item {
    font-size: 12px;
}

.registry {
    min-width: 1050px;
    -webkit-user-select: none;
            user-select: none;
}

.registry__no-images {
    min-height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-justify-content: center;
            justify-content: center;
}

.registry__content {
    position: relative;
    min-height: 200px;
}

.registry__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
}

.registry__removing-error-message {
    padding: 10px 0;
}

.registry-grid {
	position: absolute;
	inset: 0;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 12px;
}

.registry-grid__header-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: stretch;
            align-items: stretch;

    box-sizing: border-box;
}

.registry-grid__content-row {
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: stretch;
            align-items: stretch;
    position: relative;

    box-sizing: border-box;
    border-top: none;
}

.registry-grid__content-row:hover {
    background: #66676d;
}

.registry-grid__header-cell {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;

    box-sizing: border-box;
    padding: 15px 10px;

    text-align: left;
    font-weight: 500;

    color: #c9cbd2;
}

.registry-grid__content-cell {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: center;
            justify-content: center;

    box-sizing: border-box;
    padding: 5px 10px;

    text-align: left;
}

.registry-grid__content-cell__m-image-date .registry-grid__content-cell-content,
.registry-grid__content-cell__m-patients-id .registry-grid__content-cell-content,
.registry-grid__content-cell__m-birthday .registry-grid__content-cell-content {
    text-overflow: clip;
}

.registry-grid__content-cell__m-index,
.registry-grid__content-cell__m-image-id,
.registry-grid__content-cell__m-file-name,
.registry-grid__content-cell__m-last-opening,
.registry-grid__content-cell__m-last-editing {
    color: #c9cbd2;
}

.registry-grid__content-cell__m-checked {
    padding-right: 0;
}


.registry-grid__header-cell-content {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.registry-grid__content-cell-content {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.registry__divider {
    border-bottom: solid 1px #4c4d55;
}

.registry__buttons {
    display: -webkit-flex;
    display: flex;
    padding: 20px 40px;

    -webkit-flex-direction: row;

            flex-direction: row;
}

.registry__buttons-main,
.registry__buttons-secondary {
    width: 50%;
}

.registry__buttons-main {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.registry__buttons-left {
    display: -webkit-flex;
    display: flex;

    -webkit-justify-content: flex-start;

            justify-content: flex-start;
}

.registry__buttons .button {
    margin-right: 10px;
}

.registry__buttons .button:last-child {
    margin-right: 0;
}

.registry__status-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 200px;
}

.registry__error{
    color: red;
    font-size: 10px;
    top: -1px;
    position: absolute
}

.registry__filters {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    border-bottom: 1px solid #45464E;
}

.registry__filter {
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.registry__filter-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: right;

    color: #00AD66;
}

.registry__filter-link__m-disabled {
    color: #333;
}

.registry__filter-label {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.registry__filter-label-text {
    font-size: 12px;
    padding-left: 5px;
    display: inline-block;
}

.registry__filter-classes-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    font-size: 12px;
}

.registry__filter-classes-item-control {
    margin-right: 5px;
}

.hint {
    position: absolute;
    border-radius: 5px;
    background-color: #25272f;
    border: solid 1px #00ad66;
    z-index: 100;
}

.hint__content {
    font-size: 12px;
    font-weight: 500;
    color: #00ad66;
    white-space: nowrap;
    padding: 8px 8px;
    background: #25272f;
    position: relative;
    border-radius: 5px;
    z-index: 1;
}

.hint__indicator {
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -9px;
    width: 18px;
    height: 18px;
    background-color: #25272f;
    border: solid 1px #00ad66;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.hint__global-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: #1b1d24;
    -webkit-user-select: none;
            user-select: none;
}

.canvas__image {
    width: 100%;
    height: 100%;
    font-size: 0;
    position: absolute;
    left: 0;
    top: 0;
}

.canvas__image-viewport {
    display: inline-block;
}

.canvas__image-viewport__m-moving {
    cursor: move;
}

.canvas__image-viewport__m-magnifying {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

.canvas__zoom-range-container {
    position: fixed;
    left: 10px;
    top: 120px;
    background-color: #000;
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
}

.canvas__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.canvas-grid {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}

.canvas__auto-rotate {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: rgb(51,51,51);
    background-image: url(/static/media/reverse-auto-rotate.93335643.svg);
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.viewport {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
}

.viewport__canvas {
    display: inline-block;
}

.image-shapes {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.image-shapes__point {
    position: absolute;

    width: 10px;
    height: 10px;
    background: #fff;

    margin: -5px 0 0 -5px;

    border-radius: 50%;

    z-index: 3;
}

.image-shapes__svg {
    width: 100%;
    height: 100%;
    /*pointer-events: bounding-box;*/
}

.canvas-objects {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.canvas-objects svg {
    width: 100%;
    height: 100%;
    overflow: visible;
}

.box-tooltip {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.box-tooltip__tooth-key {
    width: 20px;
    height: 20px;
    border: 2px #00ad66 solid;
    border-radius: 50%;
    font-size: 11px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.box-tooltip__tooth-key__m-no-pathologies {
    border-color: #00ad66;
}

.box-tooltip__tooth-key__m-has-pathologies {
    border-color: #ef5350;
}

.box-tooltip__tooth + .box-tooltip__findings {
    padding: 0 0 0 5px;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.box-tooltip__findings-item {
    padding: 1px 0;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
}

.box-tooltip__findings-item-tags {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding-top: 3px;
}

.box-tooltip-findings-item-tag {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.box-tooltip-findings-item-tag__icon {
    width: 12px;
    height: 12px;
    background: url(/static/media/tag.433ddbf2.svg) no-repeat center;

    background-size: contain;
    -webkit-align-self: center;
            align-self: center;
}

.box-tooltip-findings-item-tag__value {
    padding: 1px 0 1px 5px;
    font-size: 12px;
}

.resolver-finding-viewer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    position: relative;
}

.resolver-finding-viewer__main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 300px;
    box-sizing: border-box;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
}

.resolver-finding-viewer__secondary {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    position: relative;
    background: #ACADB2;
    border-radius: 8px 0 8px 8px;
}

.resolver-finding-viewer__preview-container {
    background: #45464E;
    margin: 8px ;
}

.resolver-finding-viewer__tooth-container {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
}

.resolver-finding-viewer__tooth {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    background: #45464E;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
}

.resolver-finding-viewer-list {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
}

.resolver-finding-viewer-list__inner {
    position: absolute;
    left: -1px;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.resolver-finding-viewer-list__inner::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.resolver-finding-viewer-list__inner::-webkit-scrollbar-thumb {
    background: #45464E;
    border-radius: 4px;
}

.resolver-finding-viewer-image {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 8px 16px 8px 8px;
    background: #ACADB2;
}

.resolver-finding-viewer-image:first-child {
    border-radius: 0 8px 0 0;
}

.resolver-finding-viewer-image:last-child {
    border-bottom-right-radius: 8px;
}

.resolver-finding-viewer__image-type {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
}

.resolver-finding-viewer-image__preview {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    margin: 0 8px 0 0;
}

.resolver-finding-viewer-image__preview img {
    display: block;
    width: 88px;
}

.resolver-finding-viewer-image__preview.resolver-finding-viewer-image__preview__m-current img {
    -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.75));
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.75));
}

.resolver-finding-viewer-content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
}

.resolver-finding-viewer-findings {
    position: relative;
}

.resolver-finding-viewer-finding {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 2px 0;
}

.resolver-finding-viewer-finding__name {
    font-size: 12px;
    line-height: 16px;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    color: #25272F;
}

.resolver-finding-viewer-finding__actions {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-finding-viewer-finding__action {
    width: 16px;
    height: 16px;
    cursor: pointer;
    background-repeat: no-repeat;
}

.resolver-finding-viewer-finding__action {
    background-size: 100%;
    background-image: url(/static/media/remove.77d59e98.svg);
}

.resolver-finding-viewer__viewport {
    position: relative;
    overflow: hidden;
}

.resolver-finding-viewer__viewport img {
    position: absolute;
    top: 0;
    left: 0;
}

.resolver-finding-viewer__image-shapes {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.resolver-finding-viewer__image-shapes svg {
    width: 100%;
    height: 100%;
    /*pointer-events: bounding-box;*/
}

.resolver-finding-viewer__close {
    position: absolute;
    top: 0;
    right: 8px;
    width: 24px;
    height: 24px;
    background-image: url(/static/media/close.b63bf912.svg);
    cursor: pointer;
}

.resolver-finding-viewer__current {
    font-size: 11px;
    padding: 3px 0;
    text-align: center;
}

.tfv-tooth-action {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 1));
            filter: drop-shadow(0 0 2px rgba(0, 0, 0, 1));
    color: #fff;
    pointer-events: auto;
    cursor: pointer;
}

.tfv-tooth-action__menu {
    color: #fff;
}

.tfv-tooth-action__remove-action {
    position: absolute;
    top: 0;
    left: 20px;
    cursor: pointer;
}

.tfv-tooth-action__remove-icon {
    background-image: url(/static/media/remove.5634d3f6.svg);
    background-size: 16px;
    width: 16px;
    height: 16px;
}

.tfv-tooth-action__tooth-key__m-with-conflict {
    color: #ef5350;
}

.tooth-actions {
    position: absolute;
    inset: 0;
    pointer-events: none;
}

.tooth-actions__tooth {
    position: absolute;
    left:0;
    top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    pointer-events: none;
}

.resolver-image-notification {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    background-color: #45464e;
    -webkit-align-items: center;
            align-items: center;
}

.resolver-image-notification__content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    color: #c9cbd2;
    font-size: 10px;
    text-align: center;
}

.resolver-image-notification__m-type-error .resolver-image-notification__content {
    color: rgb(239, 83, 80);
}

.resolver-image-notification__close {
    position: relative;
}

.resolver-image-notification__close-button {
    position: relative;
    height: 16px;
    width: 16px;
    padding: 0;
    font-size: 14px;
    border-radius: 100%;
    background: url(/static/media/close.10ccb135.svg) no-repeat center;
    color: #97989c;
    border: none;
    cursor: pointer;
}

.resolver-image-notification__close-button:active,
.resolver-image-notification__close-button:focus {
    outline: none;
}

.main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-user-select: none;
            user-select: none;
}

.main__main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-right: 2px #1b1d24 solid;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.main__sidebar {
    min-width: 350px;
    box-sizing: border-box;
    background-color: #45464e;
    position: relative;
}

.main__main-canvas {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
}

.main__main-canvas-controls {
    height: 88px;
    background: #1b1d24;
}

.main__messenger {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 4;
    cursor: pointer;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border: #00ad66 2px solid;
    background: #1b1d24;
    font-weight: 500;
}

.main__toolbar {
    background-color: #2f313a;
    border-top: 2px #1b1d24 solid;
    border-bottom: 2px #1b1d24 solid;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.main__toolbar-button {
    height: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0 16px 0 0;
}

.main__notifications {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.main__notifications > *:not(:first-child) {
    margin: 2px 0;
}

.collection__controls {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.collection__controls-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
}

.collection__controls-item {
    cursor: pointer;
    color: #00ad66;
    text-align: center;
    padding-right: 15px;
}

.collection__no_image {
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 40px;
    letter-spacing: 0.8px;
    color: #3d6053;
}

.collection__upload_text {
    font-size: 17px;
    padding: 12px;
    letter-spacing: 0.5px;
}

.collection__controls-item-icon {
    display: inline-block;
    width: 100px;
    height: 100px;
    padding-bottom: 10px;

    position: relative;
}

.collection__controls-item-icon__m-upload-image {
    background: url(/static/media/button-upload-image.809e73c0.svg) no-repeat;
}

.collection__controls-item-icon__m-open-last-image {
    background: url(/static/media/button-open-recent.202eef26.svg) no-repeat;
}

.collection__user-menu-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.collection-select-tooth {
    position: relative;
}

.collection-select-tooth__diagram {
    position: absolute;
    right: 0;
    top: 120px;
    min-width: 350px;
}

.collection__disable-header-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 76px;
    cursor: pointer;
}

.collection__disable-sidebar-layer {
    position: absolute;
    right: 0;
    top: 120px;
    width: 350px;
    bottom: 0;
    cursor: pointer;
}

.collection__disable-canvas-controls-layer {
    position: absolute;
    left: 0;
    right: 350px;
    bottom: 0;
    height: 88px;
    cursor: pointer;
}

.collection-tooth-selector-notification {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.collection-tooth-selector-notification__element {
    margin-left: 5px;
}

.collection__items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 20px;
}

.collection__top-panel {
    background: #25272fa3;
    box-shadow: rgb(0, 0, 0) -3px -1px 6px 1px, rgba(0, 0, 0, 0) 0 1px 4px;
    padding: 5px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.collection__top-panel-text {
    color: #48b148;
    font-size: 17px;
    margin-right: 40px;
    font-weight: 400;
    letter-spacing: 0.3px;
    vertical-align: middle;
}

.collection__top-panel-img {
    width: 50px;
    height: 50px;
    background-size: cover;
    margin-right: 5px;
}

.change-email {
    width: 500px;
}

.change-email__form {
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: row;

            flex-direction: row;
}

.change-email__form-main,
.change-email__form-secondary {
    width: 50%;
    color: #acadb2;
}

.change-email__message {
    text-align: center;
}
.change-email__message-text {
    padding: 10px 0;
}


.change-password {
    width: 500px;
}

.change-password__form {
    display: -webkit-flex;
    display: flex;

    -webkit-flex-direction: row;

            flex-direction: row;
}

.change-password__form-main,
.change-password__form-secondary {
    width: 50%;
    color: #acadb2;
}

.change-password__message {
    text-align: center;
}
.change-password__message-text {
    padding: 10px 0;
}


.user-sidebar {
    width: 420px;
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    position: relative;
    overflow: auto;

    background-color: #45464e;
}

.user-sidebar__info {
    height: 76px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    box-sizing: border-box;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.user-sidebar__editor {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 22px 31px;
}

.user-sidebar__form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.user-sidebar__form-secondary {
    width: 90px;
    padding-right: 20px
}

.user-sidebar__form-main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.user-sidebar__form__m-personal {
    padding-bottom: 30px;
}

.user-sidebar__form__m-job {
    padding-top: 30px;
}

.user-sidebar__form__m-links {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 2px 0;
}

.user-sidebar__form__m-links a {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
}

.user-sidebar__footer {
    height: 88px;
    min-height: 88px;
    -webkit-flex-basis: 88px;
            flex-basis: 88px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.user-sidebar__footer-controls {
    border-top: solid 1px #4c4d55;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;

    -webkit-align-items: center;

            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.user-sidebar__footer-controls-button {
    width: 145px;
    margin: 0 0 0 20px;
    box-sizing: border-box;
}

.user-sidebar__footer-controls-button:last-child {
    margin-right: 0;
}

.user-sidebar__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
}

.user-sidebar__status-message {
    text-align: center;
    color: #fff;
}

.user-sidebar__status-message-text {
    padding: 10px 0;
}

.user-sidebar__upload-clinic-logo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 12px;
    border: 1px #b2b2b2 solid;
    color: #b2b2b2;
    text-align: center;
    cursor: pointer;
}

.user-sidebar__upload-clinic-logo input {
    display: none;
}

.user-sidebar__email-label {
    position: static !important;
}

.user-sidebar__email-field {
   margin-top: 0 !important;
}

.editor {
    min-height: 700px;
    width: 100%;
    height: 100%;

    background-color: #1b1d24;
    color: #fff;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.editor__main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
}

.editor__current-image-analyzing {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.editor__current-image-analyzing-content {
    text-align: center;
    color: #fff;
}

.editor__user-menu-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.editor-select-tooth {
    position: relative;
}

.editor-select-tooth__diagram {
    position: absolute;
    right: 0;
    top: 118px;
    min-width: 350px;
}

.editor__disable-header-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 76px;
    cursor: pointer;
}

.editor__disable-sidebar-layer {
    position: absolute;
    right: 0;
    top: 118px;
    width: 350px;
    bottom: 0;
    cursor: pointer;
}

.editor__disable-canvas-controls-layer {
    position: absolute;
    left: 0;
    right: 350px;
    bottom: 0;
    height: 88px;
    cursor: pointer;
}

.editor-tooth-selector-notification {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.editor-tooth-selector-notification__element {
    margin-left: 5px;
}

.editor-magnifying-glass {
    width: 300px;
    height: 300px;
    box-sizing: border-box;
    background: #1b1d24;
    border: 2px #25272f solid;
    position: absolute;
    top: 76px;
    right: 25px;
    overflow: hidden;
    -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
            filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
}

.editor-tooth-selector__header {
    position: relative;
    padding: 0 0 0 4px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
}

.editor-tooth-selector__indicator {
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -2px;
}

.editor-tooth-selector__item {
    position: relative;
    padding: 5px 0 5px 4px;
    font-size: 12px;
    color: #fff;
}

.editor-tooth-selector__item:hover {
    background-color: #66676d;
}

.editor-tooth-selector__select {
    width: 120px;
    height: 28px
}

.image-uploading-form {
    min-width: 900px;
}

.image-uploading-form__image-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.image-uploading-form__image {
    max-height: 400px;
    display: block;
}

.image-uploading-form__form {
}

.image-uploading-form__form-row {
    padding: 20px 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.image-uploading-form__form-item {
    padding: 0 30px 0 0;
    box-sizing: border-box;
}

.image-uploading-form__form-item:last-child {
    padding-right: 0;
}

.image-uploading-form__form-requirements {
    color: #c9cbd2;
    padding: 10px 0;
}

.image-uploading-form__notification {
    color: #ffef2a;
}

.upload-image {
    color: #fff;
}

.upload-image__progress {
    text-align: center;
    padding-bottom: 30px;
}

.report {
    padding: 5px;
    width: 595px;
    box-sizing: border-box;
    overflow: auto;

    -webkit-user-select: initial;

            user-select: initial;
}

.report-wrapper {
    background-color: #fff;
}

.report-logo {
    height: 100%;
    padding: 0 0 20px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;
}

.report-logo img {
    max-width: 100%;
}

.report-name {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.report-name__value {
    font-size: 20px;
}

.report-name select {
    font-size: 20px;
}

.report__snapshot-link,
.report__report-link {
    font-size: 10px;
    padding: 10px 0;
}

.report__report-link {
    padding-top: 5px;
}

.report__snapshot-link span,
.report__report-link span {
    font-weight: 500;
    color: #25272f;
}

.report__snapshot-link a,
.report__report-link a {
    text-decoration: underline;
    opacity: .6;
}

.report-info {
    margin: 20px 0 0;
    border-collapse: collapse;
    width: 100%;
    color: #25272f;
}

.report-info__value {
    display: none;
}

.report-info__control {
    margin: 5px 0;
}

.report-info__control::-webkit-input-placeholder {
    font-size: 12px;
}

.report-info__control::placeholder {
    font-size: 12px;
}

.report-info td {
    padding: 0;
    margin: 0;
    vertical-align: top;
}

.report__patient {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.report__patient-name {
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.report__patient-id {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 10px;
    font-weight: 500;
    padding-right: 10px;
}

.report__patient-info {
    font-size: 10px;
    font-weight: 500;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.report__patient-info-item {
    margin-right: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.report__clinic-name {
    font-weight: 500;
    font-size: 11px;
    position: relative;
    top: -3px;
}

.report-doctor-info {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.6;
    position: relative;
    top: -4px;
}

.report__normal-appearance-title {
    padding: 10px 0;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
}
.report__normal-appearance {
    font-size: 12px;
}

.report__missing-teeth-title {
    padding: 10px 0;
    font-weight: 500;
    font-size: 12px;
}
.report__missing-teeth {
    font-size: 12px;
}

.report__findings-title {
    padding: 10px 0 5px;
    font-weight: 500;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.report__findings {
    padding: 0 0 10px 0;
}

.report__finding {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 2px 0;
}

.report__finding-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 1px 0;
}

.report__finding-comment {
    padding: 0 0 0 10px;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    font-size: 12px;
}

.report__finding-comment textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin-right: 5px;
}

.report__finding-number input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}

.report__finding-number__m-status-normal {
    border-color: #b0db72;
}

.report__finding-number__m-status-pathology {
    border-color: #d0011b;
}

.report__finding-tooth-key {
    width: 15px;
    height: 15px;
    border: 2px #ccc solid;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 13px;
    font-size: 9px;
    font-weight: 500;
}

.report__finding-tooth-key__m-status-normal {
    border-color: #b0db72;
}

.report__finding-tooth-key__m-status-pathology {
    border-color: #d0011b;
}

.report__finding-tooth-key-control {
    padding: 0;
    width: 100%;
    border: none;
    border-bottom: 1px #ccc solid;
    font-size: 10px;
    background: transparent;
    text-align: center;
    font-weight: 500;
}

.report__finding-column {
    width: 50px;
}

.report__finding-diagnosis {
    padding-left: 5px;
    font-size: 12px;
    text-align: left;
    padding-top: 2px;
    color: #25272f;
}

.report__teeth {
    width: 483px;
    height: 143px;
    margin: 10px 0;
    background: url(/static/media/jaw.9ecbb099.svg) no-repeat;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.report__teeth-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.report__teeth-row__m-top {
    position: absolute;
    top: 68px;
}

.report__teeth-row__m-bottom {
    position: absolute;
    top: 132px;
}

.report__tooth {
    margin: 0 2px;
    position: relative;
}

.report__tooth__m-key-18 {
    left: 2px;
}
.report__tooth__m-key-17 {
    left: -1px;
}
.report__tooth__m-key-16 {
    left: -6px;
    top: -2px;
}
.report__tooth__m-key-15 {
    top: -4px;
    left: -7px;
}
.report__tooth__m-key-14 {
    top: -4px;
    left: -6px;
}
.report__tooth__m-key-13 {
    top: -8px;
    left: -4px;
}
.report__tooth__m-key-12 {
    top: -6px;
    left: -1px;
}
.report__tooth__m-key-11 {
    top: -4px;
    left: 2px;
}
.report__tooth__m-key-21 {
    top: -4px;
}
.report__tooth__m-key-22 {
    top: -6px;
    left: 2px;
}
.report__tooth__m-key-23 {
    top: -8px;
    left: 4px;
}
.report__tooth__m-key-24 {
    top: -4px;
    left: 5px;
}
.report__tooth__m-key-25 {
    top: -4px;
    left: 6px;
}
.report__tooth__m-key-26 {
    top: -2px;
    left: 5px;
}
.report__tooth__m-key-27 {
    left: 1px;
}
.report__tooth__m-key-28 {
    left: -2px;
}
.report__tooth__m-key-48 {
    left: -2px;
}
.report__tooth__m-key-47 {
    left: -7px;
    top: -1px;
}
.report__tooth__m-key-46 {
    left: -12px;
    top: -1px;
}
.report__tooth__m-key-45 {
    left: -14px;
    top: -1px;
}
.report__tooth__m-key-44 {
    left: -13px;
    top: -1px;
}
.report__tooth__m-key-43 {
    left: -11px;
    top: -1px;
}
.report__tooth__m-key-42 {
    left: -8px;
    top: -1px;
}
.report__tooth__m-key-41 {
    left: -2px;
    top: -1px;
}
.report__tooth__m-key-31 {
    left: 3px;
    top: -1px;
}
.report__tooth__m-key-32 {
    left: 8px;
    top: -1px;
}
.report__tooth__m-key-33 {
    left: 10px;
    top: -1px;
}
.report__tooth__m-key-34 {
    left: 13px;
    top: -1px;
}
.report__tooth__m-key-35 {
    left: 14px;
    top: -1px;
}
.report__tooth__m-key-36 {
    left: 12px;
    top: -1px;
}
.report__tooth__m-key-37 {
    left: 7px;
    top: -1px;
}
.report__tooth__m-key-38 {
    left: 2px;
}

.report__teeth-row-keys {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    top: 43px;
    font-size: 10px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #838383;
}

.report__teeth-row-keys__m-top {
    top: 43px;
}

.report__teeth-row-keys__m-bottom {
    top: 172px;
}

.report__teeth-row-keys-key {
    width: 24px;
    height: 11px;
}

.report__status-indicator {
    width: 15px;
    height: 15px;
}

.report__legend {
    text-align: center;
    padding-top: 35px;
}

.report__legend-item {
    display: inline-block;
    margin-right: 19px;
}

.report__legend-item:last-child {
    margin-right: 0;
}

.report__legend-item-title {
    display: inline-block;
    font-size: 9px;
    line-height: 1.56;
    color: #9b9b9b;
    vertical-align: middle;
}

.report__status-indicator {
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
}

.report__status-indicator {
    width: 15px;
    height: 15px;
    margin-right: 7px;
}

.report__prepared {
    opacity: 0.6;
    font-size: 10px;
    color: #25272f;
    padding: 10px 0;
}

.report__prepared-highlight {
    color: #00ad66;
}

.report__powered-by {
    font-weight: 500;
}

.report__screens:after {
    content: '';
    display: table;
    clear: both;
}

.report-screen {
    width: 50%;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding-bottom: 5px;
}

.report-screen__image {
    width: 90px;
    height: 90px;
}

.report-screen__image canvas {
    display: block;
}

.report-screen__content {
    padding-left: 15px;
}

.report-screen__findings {
    padding-top: 10px;
}

.report-screen-finding {
    padding-right: 5px;
    font-size: 10px;
    line-height: 1.4;
    text-align: left;
    padding-top: 2px;
    color: #25272f;
    word-wrap: break-word;
}

.report-screen-finding:last-child {
    padding-left: 0;
}

.report-controls {
    padding-bottom: 20px;
    border-bottom: 1px #ccc solid;
    margin-bottom: 20px;
    position: relative;
}
.report-controls__locale-label {
    color: #000;
}
.report-controls__show-links-label {
    color: #000;
}

.report-controls__locale-element {
    padding-left: 5px;
}

.report-controls__print-button {
    position: absolute;
    right: 0;
    top: 0;
}

.report__user-info {
    width: 100%;
    margin: 20px 0 5px;
}

.report__user-info td {
    font-size: 11px;
    vertical-align: top;
}

.report__user-info td:last-child {
    text-align: right;
}

.report-footer-comment {
    font-size: 13px;
}

.report-spec {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    position: relative;
}

.report-spec-item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    position: relative;
}

.report-spec-add {
    position: absolute;
    left: -17px;
    top: 0;
    font-size: 20px;
    cursor: pointer;
}

.report-spec-remove {
    position: absolute;
    left: -15px;
    top: 4px;
    font-size: 13px;
    cursor: pointer;
}

.report__canvas-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;

    -webkit-align-items: center;

            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    margin-top: 5px;
}

.report__canvas {
    display: block;

    width: 100%;
    height: 100%;
}

.report__image {
    display: none;

    max-width: 100%;
    max-height: 100%;
}

.report-summary {
    padding: 5px 0 0;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
}

.report-summary__title {
    padding: 10px 0 5px;
    font-weight: 500;
    font-size: 12px;
}

.report-summary__text {
    border: 1px #ccc solid;
}

.report-teeth-numbers {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.report-teeth-numbers__title {
    padding: 10px 0 5px;
    font-weight: 500;
    font-size: 12px;
}

.report-teeth-numbers__control input {
    padding: 0 5px;
    width: 100%;
    border: none;
    border-bottom: 1px #ccc solid;
    font-size: 12px;
}

.report-combined-findings {
    padding-bottom: 10px;
}

@media print
{
    body {
        overflow: auto !important;
    }
    .report {
        width: 100%;
    }

    .report__teeth {
        margin: 70px auto;
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
    }

    .report__legend-item-title {
        font-size: 12px;
    }

    .report-controls {
        display: none !important;
    }

    .report-info__value {
        display: inline;
    }

    .report-info__control {
        display: none;
    }

    .report__finding-comment__control input {
        padding: 2px 0;
        width: 100%;
        border: none;
        color: #999;
        font-size: 12px;
    }

    .report__finding-comment__control input::-webkit-input-placeholder {
        color: transparent;
    }

    .report__finding-comment__control input::placeholder {
        color: transparent;
    }

    .report__finding-number input::-webkit-input-placeholder {
        color: transparent;
    }

    .report__finding-number input::placeholder {
        color: transparent;
    }

    .report__finding {
        page-break-inside: avoid;
    }

    .report__screen {
        page-break-inside: avoid;
    }

    .report-spec-add {
        display: none;
    }

    .report-spec-remove {
        display: none;
    }

    .report-summary__text {
        border: none;
    }

    .report-teeth-numbers__control input {
        border: none;
    }

    .report__finding-tooth-key-control {
        border: none;
    }

    .report-name select {
        border: none;
        background: transparent;
    }
}

.report-specialist {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.report-specialist-primary {
    font-size: 12px;
    font-weight: 500;
}

.report-specialist-primary__control select {
    height: 24px;
}

.report-specialist-primary__value {
    display: none;
    padding: 4px;
}

.report-specialist-secondary {
    font-size: 12px;
    font-weight: 500;
}

.report-specialist-secondary__control {
    height: 20px;
    padding: 0;
}

.report-specialist__m-read-only .report-specialist-secondary__control {
    border: none;
    background: transparent;
}

.report-specialist__m-read-only .report-specialist-secondary__control::-webkit-input-placeholder {
    color: transparent;
}

.report-specialist__m-read-only .report-specialist-secondary__control::placeholder {
    color: transparent;
}

@media print
{
    .report-specialist-primary__control {
        display: none;
    }

    .report-specialist-primary__value {
        display: block;
    }

    .report-specialist-secondary__control {
        border: none;
        background: transparent;
    }

    .report-specialist-secondary__control::-webkit-input-placeholder {
        color: transparent;
    }

    .report-specialist-secondary__control::placeholder {
        color: transparent;
    }
}

.resolver-header {
    width: 100%;
    height: 76px;
    background: #25272f;

    position: relative;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.resolver-header__version {
    position: absolute;
    left: 11px;
    bottom: 6px;
    font-size: 10px;
    color: #c9cbd2;
}

.resolver-header__help {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0 16px;
}

.resolver-header__user-info {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.resolver-header__smth {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-right: 41px;
}

.resolver-header__buttons {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.resolver-header__grow {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.resolver-collection {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;

    min-width: 1080px;
}

.resolver-collection__header {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    border-bottom: 2px #1b1d24 solid;
}

.resolver-collection__main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.resolver-image__loading-error {
    color: #fff;
}

.resolver-history-state-button {
    min-width: 40px;
    height: 40px;
    background-color: #25272f;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.resolver-history-state-button:not(.resolver-history-state-button__m-disabled):hover {
    background-color: #45464e;
}

.resolver-history-state-button__m-disabled {
    opacity: .5;
    cursor: auto;
}

.resolver-history-state-button__m-undo:after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: url(/static/media/undo.dd4fa63b.svg) no-repeat 50%;
}

.resolver-history-state-button__m-redo:after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: url(/static/media/undo.dd4fa63b.svg) no-repeat 50%;
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
}

.resolver-surface-editor-locator-global-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    opacity: 0;
    padding: 3px;
    overflow: hidden;
}

.resolver-surface-editor {
    width: 94px;
    height: 94px;

    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);

    -webkit-transform: rotate(-45deg);

            transform: rotate(-45deg);
    border-radius: 50%;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
}
.resolver-surface-editor__control {
    position: absolute;
    box-sizing: border-box;
    width: 47px;
    height: 47px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: #57585F;
    cursor: pointer;
}

.resolver-surface-editor__control__m-selected {
    background-color: #25272f;
}

.resolver-surface-editor__control:not(.resolver-surface-editor__control__m-selected):hover {
    background-color: #2f313a;
}

.resolver-surface-editor__control-content {
    color: #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    position: relative;
}

.resolver-surface-editor__control__m-index-1 {
    left: 0;
    border-bottom: 1px #45464E solid;
}

.resolver-surface-editor__control__m-index-1 .resolver-surface-editor__control-content {
    top: 3px;
    left: 2px;
}

.resolver-surface-editor__control__m-index-2 {
    right: 0;
    border-bottom: 1px #45464E solid;
    border-left: 1px #45464E solid;
}

.resolver-surface-editor__control__m-index-2 .resolver-surface-editor__control-content {
    top: 3px;
    left: -5px;
}

.resolver-surface-editor__control__m-index-3 {
    left: 0;
    bottom: 0;
}

.resolver-surface-editor__control__m-index-3 .resolver-surface-editor__control-content {
    top: -4px;
    left: 2px;
}

.resolver-surface-editor__control__m-index-4 {
    right: 0;
    bottom: 0;
    border-left: 1px #45464E solid;
}

.resolver-surface-editor__control__m-index-4 .resolver-surface-editor__control-content {
    top: -3px;
    left: -3px;
}

.resolver-surface-editor__control__m-index-5 {
    left: 31px;
    top: 31px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px #45464E solid;
}

.resolver-config-popup {
    background-color: #2f313a;
    padding: 8px;
    width: 250px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 2px 2px 3px rgba(0,0,0,.5);
}

.resolver-config-popup__heading {
    padding: 0 0 8px;
    font-size: 11px;
    color: #c9cbd2;
    line-height: 16px;
}

.resolver-config-popup__buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.resolver-config-popup__button {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    margin-right: 16px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.resolver-config-popup__button__m-agree {
    background-image: url(/static/media/agree.7810f433.svg);
}

.resolver-config-popup__button__m-cancel {
    background-image: url(/static/media/remove-button.fb31c727.svg);
}

.resolver-findings-badge-selector {
    background-color: #AEBAB8;

}

.resolver-findings-badge-selector__items {
    background-color: #AEBAB8;
    border-top: 1px #8d8f96 solid;
    box-shadow: none;
}
.resolver-findings-badge-selector__header {
    height: auto;
}

.resolver-findings-badge-selector__header-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    position: relative;
    padding: 2px 20px 2px 4px;
    width: 100%;
    font-size: 10px;
    color: rgb(69, 70, 78);
}

.resolver-findings-badge-selector__header-value {
    margin-right: 5px;
}

.resolver-findings-badge-selector__indicator {
    position: absolute;
    top: 50%;
    right: 9px;
    margin-top: -2px;
    width: 10px;
    height: 6px;
}

.resolver-findings-badge-selector__indicator svg {
    display: block;
    -webkit-transform: scale(1, -1);
            transform: scale(1, -1);
}

.resolver-findings-badge-selector__indicator svg path {
    fill: #45464e;
}

.resolver-findings-badge-selector__indicator__m-opened svg {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
}

.resolver-findings-badge-selector__item {
    position: relative;
    padding: 0 4px;
    white-space: normal;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    font-size: 10px;
    color: rgb(69, 70, 78);
    word-break: break-all;
    cursor: pointer;
}

.resolver-findings-badge-selector__item:hover {
    background-color: #99aba8;
}

.switch {
    border: 1px solid #989898;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    height: 16px;
    position: relative;
}

.switch__handle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #989898;
    transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
}

.switch__m-checked {
    background: #349563;
    border-color: transparent;
}

.switch__m-checked .switch__handle {
    background: #45464E;
    -webkit-transform: translate(26px, 2px);
            transform: translate(26px, 2px);
}

.switch__m-disabled {
    cursor: not-allowed;
}

.resolver-findings-validation-wrapper {

}

.resolver-findings-validation-wrapper__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    font-size: 14px;
    line-height: 24px;
}

.resolver-findings-validation-wrapper__icon {
    width: 16px;
    height: 16px;
    background-image: url(/static/media/error.7ffbc0f2.svg);
}

.resolver-findings-validation-wrapper__icon__m-theme-gray {
    width: 10px;
    height: 10px;
    background-image: url(/static/media/warn-gray.03a820bf.svg);
}

.resolver-findings-validation-wrapper__icon__m-position-left {
    margin: 0 4px 0 0;
}

.resolver-findings-validation-wrapper__icon__m-position-right {
    margin: 0 0 0 4px;
}

.resolver-findings-validation-wrapper__icon__m-position-right-near {
    position: absolute;
    right: 1px;
    top: 3px;
    width: 12px;
    height: 12px;
    background-size: cover;
}

.resolver-findings-badge {
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 10px;
    line-height: 12px;
}

.resolver-findings-category__header {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
	-webkit-align-items: center;
	        align-items: center;
	padding: 16px 0;
}

.resolver-findings-category__header__m-with-expand {
	cursor: pointer;
}

.resolver-findings-category__expand-button {
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
	background-image: url(/static/media/arrow.8055cf70.svg);
	cursor: pointer;
	margin: 0 8px 0 0;
}

.resolver-findings-category__expand-button__m-expanded {
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
}

.resolver-findings-category__title {
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #989898;
}

.resolver-findings {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.resolver-findings__remove-button {
    width: 24px;
    height: 24px;
    background: url(/static/media/remove-button.fb31c727.svg) no-repeat center;
    cursor: pointer;
}

.resolver-findings__tooth {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 36px;
    padding-right: 10px;
}

.resolver-findings__tooth-key {
    width: 28px;
    height: 28px;
    border: 2px transparent solid;
    border-radius: 50%;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
}

.resolver-findings-grid {
    position: relative;
    font-size: 12px;
    height: 100%;
}

.resolver-findings-grid-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    height: 100%;
}

.resolver-findings-grid-rows-wrapper {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
}
.resolver-findings-grid-rows-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.resolver-findings-grid-collection-name {
    text-decoration: underline;
    cursor: pointer;
}

.resolver-findings-grid__header-row {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: stretch;
            align-items: stretch;

    box-sizing: border-box;

    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

.resolver-findings-grid__content-row {
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: stretch;
            align-items: stretch;
    position: relative;

    box-sizing: border-box;
    border-top: none;
}


.resolver-findings-grid__content-row__m-highlighted {
    background-color: #25272f;
}

.resolver-findings-grid__content-row__m-selected {
    background-color: #25272f;
}

.resolver-findings-grid__content-row__m-selected .switch__m-checked .switch__handle {
    background: #25272f;
}

.resolver-findings-grid__content-row:hover:not(.resolver-findings-grid__content-row__m-selected) {
    background-color: #66676d;
}

.resolver-findings-grid__header-cell {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-justify-content: center;
            justify-content: center;

    box-sizing: border-box;
    padding: 7px 2px;

    text-align: left;
    font-weight: 500;

    color: #c9cbd2;
}

.resolver-findings-grid__content-cell {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;

    box-sizing: border-box;
    padding: 6px 2px;

    color: #c9cbd2;
}

.resolver-findings-grid__content-cell__m-tooth .resolver-findings-grid__content-cell-content {
    width: 40px;
}

.resolver-findings-grid__content-cell__m-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}


.resolver-findings-grid__content-cell__m-surface .resolver-findings-grid__content-cell-content {
    min-width: 20px;
    cursor: pointer;
}

.resolver-findings-grid__content-cell__m-params .resolver-findings-grid__content-cell-content,
.resolver-findings-grid__content-cell__m-date .resolver-findings-grid__content-cell-content {
    width: 100%;
}

.resolver-findings-grid__header-cell-content {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.resolver-findings-grid__content-cell-content {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.resolver-findings-grid-selector .form-custom-select__header-content {
    padding: 0 25px 0 5px;
}

.resolver-findings__badges {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}

.resolver-findings__badge {
    display: -webkit-inline-flex;
    display: inline-flex;
    margin: 4px 4px 0 0;
}
.resolver-findings__badge .resolver-findings-validation-wrapper__content {
    font-size: 10px;
    line-height: 12px;
}

.resolver-findings__treatment-button {
    width: 24px;
    height: 24px;
    background: url(/static/media/add-treatment.4376af64.svg) no-repeat center;
    cursor: pointer;
}


.resolver-findings-grid__content-cell__m-surface,
.resolver-findings-grid__content-cell__m-treatment {
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.resolver-findings-grid__content-cell__m-surface .resolver-findings-validation-wrapper__content {
    -webkit-justify-content: center;
            justify-content: center;
}

.resolver-findings-grid__content-cell__m-surface .resolver-findings-grid__content-cell-content,
.resolver-findings-grid__content-cell__m-treatment .resolver-findings-grid__content-cell-content {
    width: 100%;
}


.resolver-findings-grid__header-cell__m-surface,
.resolver-findings-grid__header-cell__m-treatment {
    -webkit-align-items: center;
            align-items: center;
}

.resolver-auto-chart {
    width: 400px;
    padding: 0 24px 24px;
}

.resolver-auto-chart__findings {
    height: 350px;
    position: relative;
    overflow: hidden;
}

.resolver-auto-chart__findings-items {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.resolver-auto-chart__buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 5px 0 0 0;
}

.resolver-auto-chart__buttons-primary {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.resolver-auto-chart__buttons-secondary {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-auto-chart__button {
    margin: 0 10px 0 0;
}

.resolver-auto-chart__loader {
    margin-bottom: -2.5px;
    margin-right: 5px;
    -webkit-filter: invert(1);
            filter: invert(1);
}

.resolve-add-label-date {
    cursor: pointer;
}

.resolve-add-label__date {
    /*font-size: 15px;*/
    padding: 5px 0;
    border-bottom: 1px #97989c solid;
}

.resolve-add-label-surface-dropdown {
    background: #2F313A;
    font-size: 12px;
}

.resolve-add-label-surface-dropdown__item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
            flex-flow: row;
    -webkit-align-items: center;
            align-items: center;

    font-size: 12px;
    padding: 2px 3px;
    word-break: break-all;
}

.resolve-add-label-surface-dropdown__item-control {
    padding-right: 5px;
}

.resolve-add-label {
    min-height: 300px;
}
.resolve-add-label__setting__m-surfaces {
    padding: 7px 0;
}

.resolve-add-label__setting-label {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #c9cbd2;
    font-size: 12px;
    padding: 0 0 5px;
}

.resolve-add-label__surfaces {
    min-width: 50px;
    cursor: pointer;
    padding: 5px 0;
    border-bottom: 1px #97989c solid;
}

.resolve-layout-buttons__row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.resolve-layout-buttons__row:not(:first-child) {
    margin: 5px 0 0;
}

.resolve-layout-buttons__primary {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.resolve-layout-buttons__secondary {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.resolve-layout-buttons__button:not(:last-child) {
    padding-right: 8px;
}

.resolve-layout-buttons__export-button {
    padding: 0 15px;
}

.resolve-findings-filter {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    position: relative;
    height: 30px;
    -webkit-align-items: center;
            align-items: center;
}

.resolve-findings-filter__remove-control {
    font-size: 14px;
    color: #ef5350;
    font-weight: 500;
    cursor: pointer;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    padding: 0 0 0 20px;
}

.resolve-findings-filter__filter {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
    font-size: 14px;
    line-height: 16px;
    max-width: 50%;
}

.resolve-findings-filter__filter__m-confidence {
    width: 150px;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    height: 25px;
}

.resolve-findings-filter__filter-label {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 11px;
    color: #c9cbd2;
}

.resolve-findings-filter__filter-value {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #c9cbd2;
}

.resolve-findings-filter__filter-control {
    position: relative;
    top: 7px;
}

.resolver-sidebar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    height: 100%;
    overflow: hidden;
}

.resolver-sidebar__image-info {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-sidebar__dental-notation {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-sidebar-findings {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding: 0 16px 16px;
    background: #45464E;
}

.resolver-sidebar-findings-filters {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    position: relative;
}

.resolver-sidebar-findings-filters::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #25272F;
}

.resolver-sidebar-findings-filters__primary {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.resolver-sidebar-findings-filters__secondary {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-sidebar-findings__list {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
}

.resolver-sidebar-findings__footer {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-sidebar-findings-items {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.resolver-sidebar__dental-notation {
    width: 350px;
    height: 175px;
}

.resolver-sidebar__dental-notation .dental-notation-diagram {
    -webkit-transform: scale(1.17);
            transform: scale(1.17);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
}

.resolver-sidebar__dental-notation .dental-notation-diagram__top-row,
.resolver-sidebar__dental-notation .dental-notation-diagram__bottom-row {
    padding-top: 0;
    padding-bottom: 0;
}

.resolver-sidebar__dental-notation .dental-notation-diagram__shift-numbering-buttons {
    top: 66px;
}

.resolver-sidebar-panel__button {
    width: 72px;
    background: #25272F;
    padding: 0 4px 8px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
}

.resolver-sidebar-panel__button__m-pointer {
    cursor: pointer;
}

.resolver-sidebar-panel__button__m-disabled {
    cursor: not-allowed;
}

.resolver-sidebar-panel__button__m-no-bottom-padding {
    padding-bottom: 0;
}

.resolver-sidebar-panel__button:not(:last-child) {
    margin: 0 0 2px;
}

.resolver-sidebar-panel__buttons {
    padding: 0;
}

.resolver-sidebar-panel__button:before {
   /* content: '';
    position: absolute;
    height: 100%;
    box-shadow: 0 0 4px #000;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;*/
}

.resolver-sidebar-panel__text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 10px;
    line-height: 7px;
}

.resolver-sidebar-panel__icon {
    display: block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin: 0 auto;
}

.resolver-sidebar-panel__icon__m-arrow-right {
    background-image: url(/static/media/arrow-right.ddf7ff1d.svg);
}

.resolver-sidebar-panel__icon__m-arrow-left {
    background-image: url(/static/media/arrow-left.8995b34c.svg);
}

.resolver-sidebar-panel__icon__m-magnifying-glass {
    background-image: url(/static/media/magnifying-glass.24cb4132.svg);
}

.resolver-sidebar-panel__icon__m-hide-findings {
    background-image: url(/static/media/hide-findings.523ec92d.svg);
}

.resolver-sidebar-panel__icon__m-masks {
    background-size: 20px 20px;
    background-image: url(/static/media/polygon.cc02f267.svg);
}

.resolver-sidebar-panel__icon__m-disabled {
    cursor: not-allowed;
}

.resolver-sidebar-panel__switch {
    width: 40px;
    margin: 0 auto;
}

.resolver-editor-toolbar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 0 2px 0 0;
}

.resolver-editor-toolbar__toolbar {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.resolver-editor-toolbar__actions {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.examination-select {
    max-width: 200px;
    height: 26px;
    background: none;
}

.examination-select__header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    padding: 0 20px 0 5px;
    height: 26px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    box-sizing: border-box;
}

.examination-select__header__m-opened {
    background: #45464E;
}

.examination-select__header-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.examination-select__item {
    position: relative;
    padding: 5px 0 5px 10px;
    white-space: normal;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    font-size: 12px;
    color: #fff;
    word-break: break-all;
    cursor: pointer;
}

.examination-select__item__m-required {
    padding-right: 27px;
}

.examination-select__item:hover {
    background-color: #57585F;
}

.examination-select__empty {
    padding: 6px;
    text-align: center;
    font-size: 14px;
    color: #fff;
}

.examination-select__indicator {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2px;
    width: 20px;
    height: 20px;
}

.examination-select__indicator::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(/static/media/dropdown-indicator.751d113a.svg);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-position: 50% 50%;
}

.examination-select__header__m-opened .examination-select__indicator::before {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}

.resolver-navigation {
    background: #1B1D24;
    padding: 0 0 8px 0;
    overflow: hidden;
}
.resolver-navigation-top-panel {
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.resolver-navigation-top-panel__main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 16px;
}

.resolver-navigation-top-panel__secondary {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 8px 0 0;
}

.resolver-navigation-top-panel__name {
    color: #FFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.resolver-navigation-top-panel__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #989898;
}

.resolver-navigation-top-panel__separator {
    color: #FFF;
    display: inline-block;
    width: 16px;
    text-align: center;
}

.resolver-navigation-top-panel__toggle-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.resolver-navigation-top-panel__toggle-button__m-show {
    background-image: url(/static/media/hide-thumbs.82d3892c.svg);
    -webkit-transform: scale(1, -1);
            transform: scale(1, -1);
}

.resolver-navigation-top-panel__toggle-button__m-hide {
    background-image: url(/static/media/hide-thumbs.82d3892c.svg);
}

.resolver-navigation-content {
    position: relative;
}

.resolver-navigation-previews {
    overflow: auto;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 16px 0 6px 0;
    margin: -16px 0 0 0;
}

.resolver-navigation-previews__items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    margin: 0 auto;
}

.resolver-navigation-previews-item {
    height: 104px;
    -webkit-user-select: none;
            user-select: none;
}

.resolver-navigation-previews-item__m-selected {
    -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
            filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
}

.resolver-navigation-previews-item__content {
    cursor: pointer;
}

.resolver-navigation-previews-item__image {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    background-color: #000;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.resolver-navigation-previews-item__description {
    height: 16px;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
}

.resolver-navigation-previews-item__description-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.resolver-navigation-previews-control {
    position: absolute;
    top: 16px;
    width: 60px;
    height: 88px;
    cursor: pointer;
}

.resolver-navigation-previews-control__arrow {
    position: absolute;
    top: 24px;
    width: 22px;
    height: 40px;
    background-image: url(/static/media/arrow.81f9a5ed.svg);
    background-repeat: no-repeat;
    background-size: 22px 40px;
}

.resolver-navigation-previews-control__m-hidden {
    opacity: 0;
}

.resolver-navigation-previews-control__m-disabled {
    cursor: auto;
}

.resolver-navigation-previews-control__m-disabled .resolver-navigation-previews-control__arrow {
    opacity: .8;
}

.resolver-navigation-previews-control__m-prev {
    background: linear-gradient(90deg, #1B1D24 0%, rgba(27, 29, 36, 0) 100%);
    left: 0;
}

.resolver-navigation-previews-control__m-prev .resolver-navigation-previews-control__arrow {
    left: 21px;
}

.resolver-navigation-previews-control__m-next {
    background: linear-gradient(270deg, #1B1D24 0%, rgba(27, 29, 36, 0) 100%);
    right: 0;
}

.resolver-navigation-previews-control__m-next .resolver-navigation-previews-control__arrow {
    right: 21px;
    -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
}

.resolver-navigation-positions {
    position: absolute;
    top: 180px;
    left: 50%;
    height: 8px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.resolver-navigation-position {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #45464E;
}

.resolver-navigation-position:not(:first-child) {
    margin: 0 0 0 8px;
}

.resolver-navigation-position__m-active {
    width: 24px;
}

.resolver-navigation-scroll::-webkit-scrollbar {
    height: 8px;
}

.resolver-navigation-scroll::-webkit-scrollbar-thumb {
    background: #45464E;
    border-radius: 4px;
}

.fmx-viewer-rotation-buttons {
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.fmx-viewer-rotation-buttons__m-position-down {
    top: auto;
    bottom: 0px;
}

.fmx-viewer-rotation-buttons__button {
    width: 24px;
    height: 24px;
    background-color: #25272f;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.fmx-viewer-rotation-buttons__button:hover {
    background-color: #45464e;
}

.fmx-viewer-rotation-buttons__button__m-left:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url(/static/media/rotate-left.430a5249.svg) no-repeat center center;
    background-size: 16px;
}

.fmx-viewer-rotation-buttons__button__m-right:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url(/static/media/rotate-right.fc2bd329.svg) no-repeat center center;
    background-size: 16px;
}

.fmx-viewer {
    position: absolute;
    left: 0;
    top: 0;
    right: 72px;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    overflow: hidden;
    -webkit-user-select: none;
            user-select: none;
}

.fmx-viewer-canvas {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.fmx-viewer-row {
    height: 30%;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.fmx-viewer-row__m-top {
    -webkit-align-content: flex-start;
            align-content: flex-start;
}

.fmx-viewer-row__m-middle {
    height: 40%;
    -webkit-align-items: center;
            align-items: center;
   /* padding: 5px 0;*/
    box-sizing: border-box;
    padding: 10px 0;
}

.fmx-viewer-row__m-bottom {
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.fmx-viewer-cell {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    width: 32%;
    height: 100%;
}

.fmx-viewer-cell__m-main {
    width: 36%;
    /*padding: 0 10px;*/
    box-sizing: border-box;
}

.fmx-viewer-image {
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    padding: 0 10px;
    box-sizing: border-box;
    margin: auto;
    position: relative;
}

.fmx-viewer-image__preview {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    /*overflow: hidden;*/
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
}

.fmx-viewer-image__preview-wrapper {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 100%;
    height: 100%;
}

.fmx-viewer-image__preview img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    margin: auto;
}

.fmx-viewer-image__preview__m-loaded img {
    position: static;
}

.fmx-viewer-image__teeth {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    font-size: 8px;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.fmx-viewer-image__m-text-orientation-bottom .fmx-viewer-image__preview {
    -webkit-order: 1;
            order: 1;
}

.fmx-viewer-image__m-text-orientation-bottom .fmx-viewer-image__teeth {
    -webkit-order: 2;
            order: 2;
}

.fmx-viewer-image__m-text-orientation-top .fmx-viewer-image__preview {
    -webkit-order: 2;
            order: 2;
}

.fmx-viewer-image__m-text-orientation-top .fmx-viewer-image__teeth {
    -webkit-order: 1;
            order: 1;
}

.fmx-viewer-image__shapes {
    position: absolute;
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    overflow: hidden;
}

.fmx-viewer-image__shapes-inner {
    position: absolute;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
}

.fmx-viewer-image__shapes svg {
    width: 100%;
    height: 100%;
    overflow: visible;
}

.fmx-viewer-rotation-buttons-container {
    position: absolute;
    left: 0;
    top: -24px;
    right: 0;
    bottom: -24px;
}

.fmx-viewer-highlighted-image {
    -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
            filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
}

.fmx-viewer-highlighted-image__m-error {
    -webkit-filter: drop-shadow(0px 0px 8px rgb(239, 83, 80));
            filter: drop-shadow(0px 0px 8px rgb(239, 83, 80));
}

.resolver-layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;

    min-width: 1080px;
}

.resolver-layout__header {
    border-bottom: 2px #1b1d24 solid;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-layout-top-panel {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    z-index: 1;
}

.resolver-layout-main-content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    position: relative;
}

.resolver-layout-bottom-panel {
    position: relative;
    height: 166px;
   /* flex: 0 0 auto;

    position: relative;
    min-height: 300px;
    height: 40%;*/
}

.resolver-layout-bottom-panel__m-shrinked {
    height: 48px;
}

.resolver-layout-bottom-panel__content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.resolver-layout-bottom-panel-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
}

.resolver-layout-bottom-panel-content__filter {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-layout-bottom-panel-content__findings {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
    border-top: 1px solid #45464E;
    border-bottom: 1px solid #45464E;
}

.resolver-layout-bottom-panel-content__controls {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-layout__image-editor {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
}

.resolver-layout__editor-toolbar {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    height: 40px;
    background-color: #2f313a;
    border-bottom: 2px solid #1b1d24;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.resolver-layout__canvas {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    background-color: #1b1d24;
    position: relative;
}

.resolver-layout__sidebar {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 413px;
    background-color: #25272f;
    position: relative;
    border-left: 2px #1b1d24 solid;
}

.resolver-layout__sidebar__m-collapsed {
    width: 0;
}

.resolver-layout__sidebar-panel {
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    margin-right: 2px;
}

.resolver-layout__sidebar-panel__m-bottom {
    bottom: 48px;
}

.resolver-layout--image-info {
    height: 40px;
    border-top: 2px solid #1b1d24;
}

.resolver-layout--dental-notation {
    width: 350px;
    height: 290px;
}


.resolver-layout--dental-notation .dental-notation-diagram__shift-numbering-buttons {
    margin-left: -25px;
}

.resolver-layout-__sidebar .current-image-info__dropdown {
    height: 145px;
    background-color: #25272f;
}

.resolver-layout-__sidebar .current-image-info__short-content-item:first-child {
    width: auto;
}

.resolver-layout-__sidebar .current-image-info__item {
    width: 120px;
}

.resolver-layout__notifications {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.resolver-layout__notifications > *:not(:first-child) {
    margin: 2px 0;
}

.resolver-layout-magnifying-glass {
    width: 400px;
    height: 400px;
    box-sizing: border-box;
    background: #1b1d24;
    border: 2px #25272f solid;
    position: absolute;
    z-index: 99;
    top: 76px;
    right: 15px;
    overflow: hidden;
    -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
            filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
}

.resolver-layout-magnifying-glass__m-padded {
    right: 90px;
}

.resolver-layout__sequential-mode-button {
    position: absolute;
    right: 510px;
    top: 25px;
    z-index: 90;
}

.resolver-layout__sequential-mode-button__m-top-panel {
    right: 10px;
    top: -37px;
}

.resolver-layout__analyze-button {
    height: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0 16px 0 0;
}

.resolver-report {
    padding: 40px;
    width: 800px;
    box-sizing: border-box;
}

.resolver-report__caption {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #45464E;
}

.resolver-report-button {
    outline: none;
    display: -webkit-inline-flex;
    display: inline-flex;
    background: #349563;
    border-radius: 22px;
    height: 36px;
    padding: 0 11px;

    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    -webkit-align-items: center;

            align-items: center;
    text-align: center;

    color: #FFFFFF;

    cursor: pointer;
}

.resolver-report-button__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.resolver-report-button__icon {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    margin: 0 6px 0 0;
}

.resolver-report-button__icon__m-icon-preview {
    background-image: url(/static/media/preview.516a2074.svg);
}

.resolver-report-button__icon__m-icon-email {
    background-image: url(/static/media/email.84401104.svg);
}

.resolver-report-button__icon__m-icon-print {
    background-image: url(/static/media/print.d87c3252.svg);
}

.resolver-report-button__icon__m-icon-edit {
    background-image: url(/static/media/edit.8406f1a8.svg);
}

.resolver-report-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.resolver-report-header-client-logo {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;

    color: #000000;
}

.resolver-report-header-client-logo__image {
    position: absolute;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%);
}

.resolver-report-header-buttons {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0 24px 0 0;
    -webkit-align-items: center;
            align-items: center;
}

.resolver-report-header__button:not(:last-child) {
    margin-right: 8px;
}

.resolver-report-header-our-logo {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}


.resolver-report-patient-data__caption {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #1B1D24;
}

.resolver-report-patient-data__row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 24px 0 0 0;
}

.resolver-report-patient-data__cell {
    overflow: hidden;
}

.resolver-report-patient-data__cell__m-long {
    width: 50%;
}

.resolver-report-patient-data__cell__m-medium {
    width: 25%;
}

.resolver-report-patient-data__cell__m-short {
    width: 16.6%;
}

.resolver-report-patient-data-field__label {
    color: #989898 !important;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
}

.resolver-report-patient-data-field__input {
    color: #25272F !important;
    font-size: 14px;
    line-height: 100% !important;
    height: 100% !important;
    border-bottom: 1px solid #989898;
}

.resolver-report-patient-data-field__value {
    color: #25272F;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}



.resolver-report-dental-chart {
    width: 720px;
    height: 225px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.resolver-report-dental-chart__row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-transform: scale(1.45);
            transform: scale(1.45);
}

.resolver-report-dental-chart__row__m-top {
    position: absolute;
    top: 68px;
}

.resolver-report-dental-chart__row__m-bottom {
    position: absolute;
    top: 132px;
}

.resolver-report-dental-chart__tooth {
    margin: 0 2px;
    position: relative;
}

.resolver-report-dental-chart__tooth__m-key-16 {
    top: -2px;
}

.resolver-report-dental-chart__tooth__m-key-15 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-14 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-13 {
    top: -8px;
}

.resolver-report-dental-chart__tooth__m-key-12 {
    top: -6px;
}

.resolver-report-dental-chart__tooth__m-key-11 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-21 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-22 {
    top: -6px;
}

.resolver-report-dental-chart__tooth__m-key-23 {
    top: -8px;
}

.resolver-report-dental-chart__tooth__m-key-24 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-25 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-26 {
    top: -2px;
}

.resolver-report-dental-chart__tooth__m-key-47 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-46 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-45 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-44 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-43 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-42 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-41 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-31 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-32 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-33 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-34 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-35 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-36 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-37 {
    top: -1px;
}

.resolver-report-dental-chart__row-keys {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    top: 43px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #838383;
}

.resolver-report-dental-chart__row-keys__m-top {
    top: 20px;
}

.resolver-report-dental-chart__row-keys__m-bottom {
    top: 190px;
}

.resolver-report-dental-chart__row-keys-key {
    width: 34px;
}

.resolver-report-dental-chart-legend {
    text-align: center;
}

.resolver-report-dental-chart-legend__item {
    display: inline-block;
    margin-right: 19px;
}

.resolver-report-dental-chart-legend__item:last-child {
    margin-right: 0;
}

.resolver-report-dental-chart-legend__title {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: #45464E;
}

.resolver-report-dental-chart-legend__indicator {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}



.resolver-report-findings__row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 0 0 16px 0;
}

.resolver-report-findings__left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-report-findings__right {
    /*display: flex;*/
    /*flex-flow: row nowrap;*/
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.resolver-report-findings__image {
    display: block;
    width: 90px;
    height: 90px;
    background: #000;
}

.resolver-report-findings__tooth {
    padding: 0 16px 0 24px;
}

.resolver-report-findings__tooth-indicator {
    width: 32px;
    height: 32px;
    border: 2px #ccc solid;
    box-sizing: border-box;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: #45464E;
}

.resolver-report-findings__findings {
    padding: 5px 0 0 0;
}

.resolver-report-findings__finding {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #45464E;
}

.resolver-report-findings-finding-comment {
    padding: 12px 0 0;
}

.resolver-report-findings-finding-comment__label {
    color: #989898 !important;
    font-size: 12px;
    line-height: 14px;
}

.resolver-report-findings-finding-comment__input {
    color: #25272F !important;
    font-size: 14px;
    line-height: 30px;
    border-bottom: 1px solid #989898;
}

.resolver-report-findings-finding-comment__value {
    color: #25272F;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
}




.resolver-report-bottom__links {
    padding: 32px 0 0;
}

.resolver-report-bottom__link {
    font-size: 12px;
    line-height: 14px;
    padding: 0 0 5px 0;
}

.resolver-report-bottom__info {
    font-size: 12px;
    line-height: 14px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    color: #1B1D24;
    padding: 32px 0 0;
}

.resolver-report-bottom__info-left {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.resolver-report-bottom__info-right {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.resolver-report-bottom__logo-start {
    display: inline;
    font-weight: 500;
}

.resolver-report-bottom__logo-end {
    display: inline;
    color: #00AD66;;
    font-weight: 500;
}


@media print {
    .resolver-report {
        padding: 0;
    }

    .resolver-report-findings__row {
        page-break-inside: avoid;
    }

    .resolver-report-header-buttons > * {
        display: none;
    }
}

.tfv-toolbar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    padding: 0 2px 0 0;
}

.tfv-toolbar__toolbar {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
}

.tfv-toolbar__actions {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.tfv-list {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    position: relative;
}

.tfv-list__canvas {
    position: absolute;
    top: 0;
    right: 104px;
    left: 32px;
    bottom: 0;
}

.tfv-list__images {
    position: absolute;
    top: 0;
    right: 104px;
    left: 32px;
    bottom: 0;
}
.tfv-list__images-inner {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    position: absolute;
    overflow: auto;
    left: 32px;
    top: 0;
    bottom: 0;
    right: 32px;
}

.tfv-list__slider {
    position: absolute;
    width: 32px;
    left: -32px;
    top: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.tfv-list__slider__m-right {
    left: auto;
    right: -32px;
}

.tfv-list__slider-action {
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/static/media/slider-left-arrow.b0623bb3.svg);
}

.tfv-list__slider-action__m-right {
    background-image: url(/static/media/slider-right-arrow.467e9b67.svg);
}

.tfv-list__slider-action__m-disabled {
    opacity: .5;
    cursor: default;
}

.tfv-list__image-tooth-key {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 12px;
    color: #fff;
    left: 50%;
    top: 50%;
}

.tfv-main-layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;

    min-width: 1080px;
}

.tfv-main-layout__header {
    border-bottom: 2px #1b1d24 solid;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
}

.tfv-main-layout-top-panel {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
}

.tfv-main-layout-main-content {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    position: relative;
}

.tfv-main-layout__navigation {
    position: relative;
    height: 48px;
    /* flex: 0 0 auto;
 
     position: relative;
     min-height: 300px;
     height: 40%;*/
}

.tfv-main-layout__image-editor {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    position: relative;
}

.tfv-main-layout__editor-toolbar {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    height: 40px;
    background-color: #2f313a;
    border-bottom: 2px solid #1b1d24;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
}

.tfv-main-layout__list {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    background-color: #1b1d24;
    position: relative;
    border-right: 2px solid #1b1d24;
    display: -webkit-flex;
    display: flex;
}

.tfv-main-layout__sidebar {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 413px;
    background-color: #25272f;
    position: relative;
    border-left: 2px #1b1d24 solid;
}

.tfv-main-layout__sidebar__m-collapsed {
    width: 0;
}

.tfv-main-layout__sidebar-panel {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    overflow-y: auto;
    margin-right: 2px;
}

.tfv-main-layout__sidebar-panel__m-bottom {
    bottom: 48px;
}

.tfv-main-layout--image-info {
    height: 40px;
    border-top: 2px solid #1b1d24;
}

.tfv-main-layout--dental-notation {
    width: 350px;
    height: 290px;
}


.tfv-main-layout--dental-notation .dental-notation-diagram__shift-numbering-buttons {
    margin-left: -25px;
}

.tfv-main-layout__sequential-mode-button {
    position: absolute;
    right: 10px;
    top: -37px;
    z-index: 90;
}


.tfv-main-layout__analyze-button {
    height: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 0 16px 0 0;
}

.tfv-main-layout__notifications {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.tfv-main-layout__notifications > *:not(:first-child) {
    margin: 2px 0;
}

.password-reset {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.password-reset__wrapper {
    background: #57585f;
    overflow: hidden;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 32px;
    width: 300px;
}

.password-reset__header {
    color: #fff;
}

.password-reset__buttons {
    padding: 16px 0 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.password-reset__error {
    font-size: 12px;
    padding: 6px;
    color: #ef5350;
}

.password-reset__success {
    color: #c9cbd2;
    font-size: 12px
}

.password-forgot {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.password-forgot__wrapper {
    background: #57585f;
    overflow: hidden;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 32px;
    width: 300px;
}

.password-forgot__header {
    color: #fff;
}

.password-forgot__buttons {
    padding: 16px 0 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.password-forgot__error {
    font-size: 12px;
    padding: 6px;
    color: #ef5350;
}

.password-forgot__success {
    color: #c9cbd2;
    font-size: 12px;
}

.app-entry {

}

.app-entry__error {
	background-color: #1b1d24;
	color: #fff;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: -webkit-flex;
	display: flex;
}

/*all*/
body {
	margin: 0;
    padding: 0 !important;
    -moz-user-select: none;
    overflow: hidden;
}
a {
	text-decoration: none;
	cursor: pointer;
    color: #00ad66;
}
body, html {
    height: 100%;
    background-color: #1b1d24;

}

html body, button {
    font-family: 'Roboto', sans-serif;
}

body {
    overflow-y: scroll;
    overflow-x: auto;
}

div:focus {
    outline: none;
}

.main-customs-scroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.main-customs-scroll::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .3);
}

#app-content {
    height: 100%;
    margin: 0 !important;
}

#app-content.m-small-screen {
    min-width: 860px;
}

