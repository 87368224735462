.resolver-layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-flow: column nowrap;

    min-width: 1080px;
}

.resolver-layout__header {
    border-bottom: 2px #1b1d24 solid;
    flex: 0 0 auto;
}

.resolver-layout-top-panel {
    flex: 0 0 auto;

    display: flex;
    flex-flow: row nowrap;
    z-index: 1;
}

.resolver-layout-main-content {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
}

.resolver-layout-bottom-panel {
    position: relative;
    height: 166px;
   /* flex: 0 0 auto;

    position: relative;
    min-height: 300px;
    height: 40%;*/
}

.resolver-layout-bottom-panel__m-shrinked {
    height: 48px;
}

.resolver-layout-bottom-panel__content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.resolver-layout-bottom-panel-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
}

.resolver-layout-bottom-panel-content__filter {
    flex: 0 0 auto;
}

.resolver-layout-bottom-panel-content__findings {
    flex: 1 1 auto;
    position: relative;
    border-top: 1px solid #45464E;
    border-bottom: 1px solid #45464E;
}

.resolver-layout-bottom-panel-content__controls {
    flex: 0 0 auto;
}

.resolver-layout__image-editor {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
}

.resolver-layout__editor-toolbar {
    flex: 1 1 auto;
    height: 40px;
    background-color: #2f313a;
    border-bottom: 2px solid #1b1d24;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.resolver-layout__canvas {
    flex: 1 1 auto;
    background-color: #1b1d24;
    position: relative;
}

.resolver-layout__sidebar {
    flex: 0 0 auto;
    width: 413px;
    background-color: #25272f;
    position: relative;
    border-left: 2px #1b1d24 solid;
}

.resolver-layout__sidebar__m-collapsed {
    width: 0;
}

.resolver-layout__sidebar-panel {
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    margin-right: 2px;
}

.resolver-layout__sidebar-panel__m-bottom {
    bottom: 48px;
}

.resolver-layout--image-info {
    height: 40px;
    border-top: 2px solid #1b1d24;
}

.resolver-layout--dental-notation {
    width: 350px;
    height: 290px;
}


.resolver-layout--dental-notation .dental-notation-diagram__shift-numbering-buttons {
    margin-left: -25px;
}

.resolver-layout-__sidebar .current-image-info__dropdown {
    height: 145px;
    background-color: #25272f;
}

.resolver-layout-__sidebar .current-image-info__short-content-item:first-child {
    width: auto;
}

.resolver-layout-__sidebar .current-image-info__item {
    width: 120px;
}

.resolver-layout__notifications {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.resolver-layout__notifications > *:not(:first-child) {
    margin: 2px 0;
}

.resolver-layout-magnifying-glass {
    width: 400px;
    height: 400px;
    box-sizing: border-box;
    background: #1b1d24;
    border: 2px #25272f solid;
    position: absolute;
    z-index: 99;
    top: 76px;
    right: 15px;
    overflow: hidden;
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
}

.resolver-layout-magnifying-glass__m-padded {
    right: 90px;
}

.resolver-layout__sequential-mode-button {
    position: absolute;
    right: 510px;
    top: 25px;
    z-index: 90;
}

.resolver-layout__sequential-mode-button__m-top-panel {
    right: 10px;
    top: -37px;
}

.resolver-layout__analyze-button {
    height: 30px;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding: 0 16px 0 0;
}
