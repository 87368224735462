.datepicker-month {
	display: flex;
	flex-flow: column nowrap;
	user-select: none;

	margin: 0 16px 16px 0;
}

.datepicker-month:focus {
	outline: none;
}

.datepicker-navbar {
	position: absolute;
	left: 0;
	right: 0;
	top: 2px;
}

.datepicker-navbar__nav-button {
	position: absolute;
	width: 25px;
	height: 25px;
	cursor: pointer;
	line-height: 25px;
	box-sizing: border-box;
}

.datepicker-navbar__nav-button:before {
	font-size: 22px;
	vertical-align: middle;
}

.datepicker-navbar__nav-button__m-prev {
	left: 5px;
}

.datepicker-navbar__nav-button__m-prev:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: url('../images/arrow-thin-left.svg') no-repeat;
	background-size: 22px 22px;
}

.datepicker-navbar__nav-button__m-next {
	right: 21px;
	text-align: right;
}

.datepicker-navbar__nav-button__m-next:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: url('../images/arrow-thin-right.svg') no-repeat;
	background-size: 22px 22px;
}

.datepicker-navbar__nav-button__m-disabled {
	cursor: default;
	opacity: .5;
}

.datepicker-navbar__nav-button__m-disabled:before {
	 color: #ccc;
}

.datepicker-navbar__nav-button:focus,
.datepicker-navbar__nav-button:active {
	 outline: none;
}

.datepicker-caption {
	flex: 0 0 auto;
	padding: 0 20px;
}

.datepicker-caption__controls {
	 display: flex;
	 flex-flow: row nowrap;
	 justify-content: center;
	 align-items: center;
 }

.datepicker-caption__selector {
	box-sizing: border-box;
	padding: 0 5px;
	flex: 0 0 auto;
}

.datepicker-caption:first-child {
	flex: 1 1 auto;
}

.datepicker-caption:last-child {
	width: 90px;
}

.datepicker-weekdays {
	flex: 0 0 auto;
}

.datepicker-weekdays__row {
	display: table-row;
}

.datepicker-weekday {
	display: table-cell;
	padding: 8px 0;
	min-width: 40px;
	font-size: 12px;
	text-align: center;
	color: #97989c;
}

.datepicker-weekday abbr {
	text-decoration: none;
}

.datepicker-body {
	display: table-row-group;
}

.datepicker-week {
	display: table-row;

}
.datepicker-week:last-child .datepicker-day {
	border-bottom-color: #4c4d55;
}

.datepicker-day {
	display: table-cell;
	padding: 8px 0;
	min-width: 40px;
	border: 1px solid #4c4d55;
	border-bottom-color: transparent;
	border-right-color: transparent;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	position: relative;
}

.datepicker-day.disabled {
	cursor: default;
	background-color: #25272f;
	opacity: .7;
}

.datepicker-day.outside {
	cursor: default;
}

.datepicker-day.today {
	color: #00ad66;
	font-weight: 500;
 }

.datepicker-day.selected:not(.disabled):not(.outside) {
	 background-color: #00ad66;
	 border-right: 1px #00ad66 solid;
	 border-bottom: 1px #00ad66 solid;
 }

.datepicker-day.disabled {
	cursor: default;
	background-color: #25272f;
	opacity: .5;
	color: #dce0e0;
}

.datepicker-day.outside {
	cursor: default;
	color: #dce0e0;
}

.datepicker-day.selected:not(.disabled):not(.outside) {
	color: #fff;
}

.datepicker-day:focus,
.datepicker-day:active {
	border: 1px #4c4d55 solid;
}

.datepicker-day.selected:not(.disabled):not(.outside) {
	border-top: 1px #4c4d55 solid;
	border-left: 1px #4c4d55 solid;
}

.datepicker-day:last-child {
	border-right-color: #4c4d55;
}

.datepicker-day:focus,
.datepicker-day:active {
	outline: none;
}

.datepicker-week-number {
	display: table-cell;
	padding: 8px;
	text-align: right;
	vertical-align: middle;
	min-width: 16px;
	font-size: 12px;
	cursor: pointer;
	color: #8b9898;
}

.datepicker-footer {
	padding-top: 10px;
	text-align: center;
}

.datepicker {
	display: inline-block;
	padding-top: 10px;
}

.datepicker__inner {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	position: relative;
	user-select: none;

	margin-right: -16px;
	margin-bottom: -16px;
}

.datepicker:focus,
.datepicker:active,
.datepicker__inner:focus {
	 outline: none;
}

.datepicker__m-interaction-disabled .datepicker-day {
	cursor: default;
}


/* DayPicker styles */

.DayPicker {
	display: inline-block;
}

.DayPicker-wrapper {
	position: relative;
	user-select: none;
	padding-bottom: 1rem;
	flex-direction: row;
}

.DayPicker-Months {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.DayPicker-Month {
	display: table;
	border-collapse: collapse;
	border-spacing: 0;
	user-select: none;
	margin: 0 1rem;
	margin-top: 1rem;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
	position: absolute;
	cursor: pointer;
	top: 1rem;
	right: 1.5rem;
	margin-top: 2px;
	color: #8b9898;
	width: 1.25rem;
	height: 1.25rem;
	display: inline-block;
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: center;
}

.DayPicker-NavButton:hover {
	opacity: 0.8;
}

.DayPicker-NavButton--prev {
	margin-right: 1.5rem;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
	display: none;
}

.DayPicker-Caption {
	padding: 0 0.5rem;
	display: table-caption;
	text-align: left;
	margin-bottom: 0.5rem;
}

.DayPicker-Caption > div {
	font-size: 1.15rem;
	font-weight: 500;
}

.DayPicker-Weekdays {
	margin-top: 1rem;
	display: table-header-group;
}

.DayPicker-WeekdaysRow {
	display: table-row;
}

.DayPicker-Weekday {
	display: table-cell;
	padding: 0.5rem;
	font-size: 0.875em;
	text-align: center;
	color: #8b9898;
}

.DayPicker-Weekday abbr[title] {
	border-bottom: none;
	text-decoration: none;
}

.DayPicker-Body {
	display: table-row-group;
}

.DayPicker-Week {
	display: table-row;
}

.DayPicker-Day {
	display: table-cell;
	padding: 0.5rem;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	outline: none;
}

.DayPicker-WeekNumber {
	display: table-cell;
	padding: 0.5rem;
	text-align: right;
	vertical-align: middle;
	min-width: 1rem;
	font-size: 0.75em;
	cursor: pointer;
	color: #8b9898;
	border-right: 1px solid #eaecec;
}

.DayPicker--interactionDisabled .DayPicker-Day {
	cursor: default;
}

.DayPicker-Footer {
	padding-top: 0.5rem;
}

.DayPicker-TodayButton {
	border: none;
	background-image: none;
	background-color: transparent;
	box-shadow: none;
	cursor: pointer;
	color: #4a90e2;
	font-size: 0.875em;
}

/* Default modifiers */

.DayPicker-Day--today {
	color: #d0021b;
	font-weight: 700;
}

.DayPicker-Day--outside {
	cursor: default;
	color: #8b9898;
}

.DayPicker-Day--disabled {
	color: #dce0e0;
	cursor: default;
	/* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
	background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
	color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	position: relative;
	color: #00ad66;
	background-color: #4a90e2;
	border-radius: 100%;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
	background-color: #00ad66;
	border-radius: 50%;
}

/* DayPickerInput */

.DayPickerInput input{
	background: transparent;
	border: none;
	color: white;
	padding: 5px 0;
	max-width: 75px;
}


.DayPickerInput input:focus {
	outline: none;
	border-bottom: green;
}

.DayPickerInput-OverlayWrapper {
	position: relative;
}

.DayPickerInput-Overlay {
	left: 0;
	z-index: 1;
	position: absolute;
	background: #4c4d55;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
