.image-uploading-form {
    min-width: 900px;
}

.image-uploading-form__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-uploading-form__image {
    max-height: 400px;
    display: block;
}

.image-uploading-form__form {
}

.image-uploading-form__form-row {
    padding: 20px 0 0;
    display: flex;
    flex-direction: row;
}

.image-uploading-form__form-item {
    padding: 0 30px 0 0;
    box-sizing: border-box;
}

.image-uploading-form__form-item:last-child {
    padding-right: 0;
}

.image-uploading-form__form-requirements {
    color: #c9cbd2;
    padding: 10px 0;
}

.image-uploading-form__notification {
    color: #ffef2a;
}
