.editor {
    min-height: 700px;
    width: 100%;
    height: 100%;

    background-color: #1b1d24;
    color: #fff;

    display: flex;
    flex-direction: column;
}

.editor__main {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
}

.editor__current-image-analyzing {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.editor__current-image-analyzing-content {
    text-align: center;
    color: #fff;
}

.editor__user-menu-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.editor-select-tooth {
    position: relative;
}

.editor-select-tooth__diagram {
    position: absolute;
    right: 0;
    top: 118px;
    min-width: 350px;
}

.editor__disable-header-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 76px;
    cursor: pointer;
}

.editor__disable-sidebar-layer {
    position: absolute;
    right: 0;
    top: 118px;
    width: 350px;
    bottom: 0;
    cursor: pointer;
}

.editor__disable-canvas-controls-layer {
    position: absolute;
    left: 0;
    right: 350px;
    bottom: 0;
    height: 88px;
    cursor: pointer;
}

.editor-tooth-selector-notification {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.editor-tooth-selector-notification__element {
    margin-left: 5px;
}

.editor-magnifying-glass {
    width: 300px;
    height: 300px;
    box-sizing: border-box;
    background: #1b1d24;
    border: 2px #25272f solid;
    position: absolute;
    top: 76px;
    right: 25px;
    overflow: hidden;
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
}
