.resolver-history-state-button {
    min-width: 40px;
    height: 40px;
    background-color: #25272f;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resolver-history-state-button:not(.resolver-history-state-button__m-disabled):hover {
    background-color: #45464e;
}

.resolver-history-state-button__m-disabled {
    opacity: .5;
    cursor: auto;
}

.resolver-history-state-button__m-undo:after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: url('../images/undo.svg') no-repeat 50%;
}

.resolver-history-state-button__m-redo:after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: url('../images/undo.svg') no-repeat 50%;
    transform: scale(-1, 1);
}
