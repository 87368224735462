.label-tags-selector {
    width: 180px;
    height: 28px;
}

.label-tags-selector__item {
    position: relative;
    padding: 5px 0 5px 11px;
    white-space: normal;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.label-tags-selector__item:hover {
    background-color: #66676d;
}

.label-tags-selector__item__m-disabled {
    opacity: .5;
    cursor: default;
}

.label-tags-selector__hot-key {
    background: #97989c;
    border-radius: 4px;
    color: #000;
    font-size: 11px;
    height: 15px;
    width: 17px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-bottom: 2px #1b1d24 solid;
    border-right: 2px #1b1d24 solid;
}
