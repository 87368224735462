.resolver-sidebar-panel__button {
    width: 72px;
    background: #25272F;
    padding: 0 4px 8px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    user-select: none;
}

.resolver-sidebar-panel__button__m-pointer {
    cursor: pointer;
}

.resolver-sidebar-panel__button__m-disabled {
    cursor: not-allowed;
}

.resolver-sidebar-panel__button__m-no-bottom-padding {
    padding-bottom: 0;
}

.resolver-sidebar-panel__button:not(:last-child) {
    margin: 0 0 2px;
}

.resolver-sidebar-panel__buttons {
    padding: 0;
}

.resolver-sidebar-panel__button:before {
   /* content: '';
    position: absolute;
    height: 100%;
    box-shadow: 0 0 4px #000;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;*/
}

.resolver-sidebar-panel__text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 10px;
    line-height: 7px;
}

.resolver-sidebar-panel__icon {
    display: block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin: 0 auto;
}

.resolver-sidebar-panel__icon__m-arrow-right {
    background-image: url('../images/arrow-right.svg');
}

.resolver-sidebar-panel__icon__m-arrow-left {
    background-image: url('../images/arrow-left.svg');
}

.resolver-sidebar-panel__icon__m-magnifying-glass {
    background-image: url('../images/magnifying-glass.svg');
}

.resolver-sidebar-panel__icon__m-hide-findings {
    background-image: url('../images/hide-findings.svg');
}

.resolver-sidebar-panel__icon__m-masks {
    background-size: 20px 20px;
    background-image: url('../images/polygon.svg');
}

.resolver-sidebar-panel__icon__m-disabled {
    cursor: not-allowed;
}

.resolver-sidebar-panel__switch {
    width: 40px;
    margin: 0 auto;
}
