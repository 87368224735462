.resolve-findings-filter {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    height: 30px;
    align-items: center;
}

.resolve-findings-filter__remove-control {
    font-size: 14px;
    color: #ef5350;
    font-weight: 500;
    cursor: pointer;
    flex: 0 1 auto;
    padding: 0 0 0 20px;
}

.resolve-findings-filter__filter {
    flex: 1 1 auto;
    position: relative;
    font-size: 14px;
    line-height: 16px;
    max-width: 50%;
}

.resolve-findings-filter__filter__m-confidence {
    width: 150px;
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    height: 25px;
}

.resolve-findings-filter__filter-label {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 11px;
    color: #c9cbd2;
}

.resolve-findings-filter__filter-value {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #c9cbd2;
}

.resolve-findings-filter__filter-control {
    position: relative;
    top: 7px;
}
