.popup-dialog-footer {
	padding: 10px 40px 17px;
}

.popup-dialog-footer__m-alignment-left {
	text-align: left;
}

.popup-dialog-footer__m-alignment-right {
	text-align: right;
}

.popup-dialog-footer__m-alignment-center {
	text-align: center;
}

.popup-dialog-footer .button {
	margin-right: 20px;
}

.popup-dialog-footer .button:last-child {
	margin-right: 0;
}
