.stats-card {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.stats-card__m-theme-dark {
    background: #25272F;
}

.stats-card__m-theme-light {
    background: #57585F;
}
