.password-forgot {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.password-forgot__wrapper {
    background: #57585f;
    overflow: hidden;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 32px;
    width: 300px;
}

.password-forgot__header {
    color: #fff;
}

.password-forgot__buttons {
    padding: 16px 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.password-forgot__error {
    font-size: 12px;
    padding: 6px;
    color: #ef5350;
}

.password-forgot__success {
    color: #c9cbd2;
    font-size: 12px;
}
