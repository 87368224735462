.sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar_upper-block {
    position: relative;
    flex-shrink: 0;
}

.sidebar__dental-notation-diagram {
    flex-basis: auto;
}

.sidebar__dental-notation-diagram__m-disabled:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(27, 29, 36,.7);
}

.sidebar__add-diagnosis {
    flex-basis: 82px;
    background-color: #25272f;
}

.sidebar__findings {
    overflow: hidden;
    flex: 1 1 auto;
    position: relative;
}

.sidebar__show-all-findings-toggle {
    font-size: 14px;
    color: #97989c;
    padding: 10px 10px 10px 45px;
    cursor: pointer;
}

.sidebar__footer {
    height: 88px;
    min-height: 88px;
    flex-basis: 88px;
    flex-shrink: 0;
}

.sidebar__footer-controls {
    height: 100%;
    display: flex;
    position: relative;

    align-items: center;
    padding-right: 20px;
    justify-content: flex-end;
}

.sidebar__footer-controls__m-disabled:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
}

.sidebar__footer-controls-button {
    min-width: 145px;
    margin: 0 0 0 20px;
    box-sizing: border-box;
}

.sidebar__footer-controls-button:last-child {
    margin-right: 0;
}

.sidebar__drawing-finding {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}


