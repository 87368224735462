.current-image-info {
    position: relative;
    color: #fff;
}

.current-image-info__item {
    display: inline-block;
    font-size: 12px;
    width: 150px;
    height: 60px;
    margin-right: 10px;
}


.current-image-info__input {
    font-size: 12px !important;
}

.current-image-info__item:last-child {
    margin-right: 0;
}

.current-image-info__property {
    color: #c9cbd2;
    padding-right: 5px;
}

.current-image-info__value {
    color: #fff;
    padding-left: 5px;
}

.current-image-info__value-text {
    font-size: 13px;
}

.current-image-info__text {
    margin-right: 5px;
    font-size: 11px;
}

.current-image-info__close-image-button {
    position: relative;
    height: 16px;
    width: 16px;
    padding: 0;
    font-size: 14px;
    border-radius: 100%;
    background: url('../images/close.svg') no-repeat center;
    color: #97989c;
    border: none;
    cursor: pointer;
}

.current-image-info__close-image-button:active,
.current-image-info__close-image-button:focus {
    outline: none;
}

.current-image-info__dropdown {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: 151px;
    padding: 15px;
    background-color: #2f313a;
    z-index: 99;
}

.current-image-info__short {
    display: flex;
    flex-flow: row nowrap;

    padding-left: 15px;
    align-items: center;
    font-size: 11px;
}

.current-image-info__short-content {
    flex: 1 1 auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.current-image-info__short-content__m-first-col-padding .current-image-info__short-content-item:first-child {
    width: 45%;
}

.current-image-info__dropdown-indicator {
    background-image: url(../images/info.svg);
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
}

.current-image-info__dropdown-indicator-wrapper {
    width: 50px;
    height: 20px;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.current-image-info__value-wrapper {
    height: 40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
