.diagnosis-select {
	width: 180px;
	height: 28px;
}

.diagnosis-select__header {
	display: flex;
	flex-flow: row nowrap;
	position: relative;
	padding: 0 20px 0 10px;
	width: 100%;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 12px;
}

.diagnosis-select__header-value {
	margin-right: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.diagnosis-select__indicator {
	position: absolute;
	top: 50%;
	right: 9px;
	margin-top: -2px;
}

.diagnosis-select__item {
	position: relative;
	padding: 5px 0 5px 11px;
	white-space: normal;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	font-size: 12px;
	color: #fff;
	word-break: break-all;
	cursor: pointer;
}

.diagnosis-select__item__m-required {
	padding-right: 27px;
}

.diagnosis-select__item__m-disabled {
	opacity: .5;
	cursor: default;
}

.diagnosis-select__item:hover {
	background-color: #66676d;
}

.diagnosis-select__required-box {
	position: absolute;
	right: 10px;
	top: 5px;
	width: 14px;
	height: 14px;
	background-size: 14px;
}

.diagnosis-select__hot-key {
	background: #97989c;
	border-radius: 4px;
	color: #000;
	font-size: 11px;
	height: 15px;
	width: 17px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	border-bottom: 2px #1b1d24 solid;
	border-right: 2px #1b1d24 solid;
	flex: 0 0 auto;
}
