.popup-dialog__content {
	padding: 20px 40px 10px;
	color: #fff;
	font-size: 14px;
}

.popup-dialog__content-without-padding {
	margin: -20px -40px -10px;
}

.popup-dialog__content-text {
	line-height: 1.3;
}
