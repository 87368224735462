.sign-in {
    background-color: #1b1d24;
    height: 100vh;
}

.sign-in__title {
    color: #fff;
    position: relative;
    padding-right: 25px;
    margin-right: 40px;
    font-size: 18px;
    font-weight: 500;
    height: 90px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.sign-in__title:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -2px;
    border-bottom: 5px #fff solid;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
}

.sign-in__form {
    padding: 35px 30px 30px;
    position: relative;
}

.sign-in__form-element {
    padding-top: 10px;
}

.sign-in__form-button {
    padding-top: 20px;
    text-align: center;
}

.sign-in__error {
    font-size: 12px;
    text-align: left;
    color: #97989c;
    padding: 0 30px;
    position: absolute;
    top: 0;
    left: 0;
}


.sign-in-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.sign-in-form__preface {
    font-size: 11px;
    padding: 0 0 20px;
    color: #fff;
}

.sign-in-form__wrapper {
    background: #57585f;
    overflow: hidden;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .3);
    border-radius: 5px;
    padding: 32px;
    width: 300px;
}

.sign-in-form__header {
    color: #fff;
}

.sign-in-form__buttons {
    padding: 16px 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.sign-in-form__error {
    font-size: 12px;
    padding: 6px;
    color: #ef5350;
}
