.resolver-editor-toolbar {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 2px 0 0;
}

.resolver-editor-toolbar__toolbar {
    flex: 1 1 auto;
}

.resolver-editor-toolbar__actions {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
}
