.toolbar {
    height: 40px;
    color: #fff;
}

.toolbar__items {
    display: flex;
    flex-direction: row;
}

.toolbar__item {
    min-width: 35px;
    height: 40px;
    background-color: #25272f;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolbar__item__m-small {
    min-width: 35px;
}

.toolbar__item-group {
    display: flex;
    flex-flow: row nowrap;
}

.toolbar_item__dark {
    background-color: #25272f;
}

.toolbar__item__light {
    background-color: rgba(72, 73, 78, 0.61);
    border: 2px solid;
    border-color: rgb(87, 88, 94);
}

.toolbar__item__m-selected,
.toolbar__item:hover {
    background-color: #45464e;
}

.toolbar__item__m-disabled {
    min-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    pointer-events: none;
}

.toolbar__item-group__m-draw-mode,
.toolbar__item__m-zoom-default,
.toolbar__item__m-rotate-right {
    margin-right: 2px;
}

.toolbar__item__m-select-mode:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/select.svg') no-repeat center center;
}

.toolbar__item__m-zoom:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/zoom.svg') no-repeat center center;
}

.toolbar__item__m-zoom-in:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/zoomin.svg') no-repeat center center;
}

.toolbar__item__m-zoom-out:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/zoomout.svg') no-repeat center center;
}

.toolbar__item__m-zoom-default:after {
    content: '1:1';

    font-size: 12px;
}

.toolbar__item__m-draw-mode:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/edit.svg') no-repeat center center;
}

.toolbar__item__m-brightness {
    font-size: 10px;
    color: #c9cbd2;
    flex-flow: column nowrap;
    padding-left: 10px;
    padding-right: 10px;
}

.toolbar__item__m-contrast {
    font-size: 10px;
    color: #c9cbd2;
    flex-flow: column nowrap;
    padding-left: 10px;
    padding-right: 10px;
}


.toolbar__item__m-enhanced-image-filter {
    font-size: 10px;
    color: #c9cbd2;
    flex-flow: column nowrap;
    padding-left: 10px;
    padding-right: 10px;
    width: 120px
}

.toolbar__item__m-enhanced-image-filter-select .form-custom-select__header {
    height: 20px;
}


.toolbar__item__m-flip-horizontal:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/flip-horizontal.svg') no-repeat center center;
}

.toolbar__item__m-flip-vertical:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/flip-vertical.svg') no-repeat center center;
}

.toolbar__item__m-rotate-left:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url('../images/rotate-left.svg') no-repeat center center;
}

.toolbar__item__m-rotate-right:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url('../images/rotate-right.svg') no-repeat center center;
}

.toolbar__item-label__m-contrast,
.toolbar__item-label__m-brightness
{
    padding-bottom: 4px;
}

.toolbar__item__m-invert:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/invert.svg') no-repeat center center;
    background-size: 16px 16px;
}

.toolbar__item__m-image-sharpen:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url('../images/image-sharpen.svg') no-repeat center center;
}

.toolbar__item__m-grid:after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url('../images/grid.svg') no-repeat center center;
    background-size: 16px 16px;
}

.toolbar__item__m-bone-levels:after {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background: url('../images/bone_levels.svg') no-repeat center center;
    background-size: 32px 32px;
}

.toolbar__item__m-hide-findings:after {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background: url('../images/hide-findings.svg') no-repeat center center;
    background-size: 18px 16px;
}

.toolbar__item__m-magnifying-glass:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/magnifying-glass.svg') no-repeat center center;
}

.toolbar__item__m-findings-masks:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/polygon.svg') no-repeat center center;
    background-size: 24px;
}

.toolbar__item__m-heat-map:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url('../images/polygon.svg') no-repeat center center;
    background-size: 20px;
}

.toolbar__item__m-show-tissue-findings:after {
	content: '';
	display: block;
	width: 20px;
	height: 20px;
	background: url('../images/tissue.svg') no-repeat center center;
}

.toolbar__item__m-analyze{
    flex: auto;
    justify-content: flex-end;
    padding-right: 20px;
}
.toolbar__item__m-analyze:hover {
    background-color: #25272f;
}
.toolbar__item-label {
    display: flex;
    flex-flow: row nowrap;
}

.toolbar__item-reset-button {
    width: 10px;
    height: 10px;
    background: #97989c url("../images/close.svg") no-repeat;
    background-size: contain;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin: 1px 0 0 2px;
}

.toolbar__item-reset-button:hover {
    background-color: #eee;
}
