.top-pane {
    width: 100%;
    position: relative;
    height: 40px;
    background-color: #2f313a;
}

.top-pane__current-image-info {
    height: 100%;
}
