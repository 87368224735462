.label-list-item {
    display: flex;
    padding: 4px 15px 4px 20px;
    flex-flow: row nowrap;
}

.label-list-item__m-edit-mode {
    flex-flow: column nowrap;
}

.label-list-item__tooth {
    flex: 0 0 auto;
    width: 36px;
    padding-right: 10px;
}

.label-list-item__tooth-missing {
    width: 28px;
    height: 28px;
    border: 2px #fff dashed;
    border-radius: 50%;
    cursor: pointer;
}

.label-list-item__main {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
}

.label-list-item__tooth-key {
    width: 28px;
    height: 28px;
    border: 2px transparent solid;
    border-radius: 50%;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.label-list-item__label-child {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 4px 0;
}

.label-list-item__label-child__m-selected {
    background: #15171d;
    margin: 0 -15px 0 -20px;
    padding: 0 15px 0 20px;
    align-items: flex-start;
}

.label-list-item__label-child-controls {
    flex: 0 0 auto;
    width: 36px;
    padding-right: 10px;
    padding-top: 4px;
}

.label-list-item__label-child-main {
    flex: 1 1 auto;
}

.label-list-item__label-children {
    flex: 1 1 auto;
}

.label-list-item__label-control {
    flex: 0 0 auto;
    width: 36px;
    padding-right: 10px;
    padding-top: 4px;
}


.label-list-item__label-content {
    flex: 1 1 auto;
}
