.overlay {
    width: 100%;
    height: 100%;
}
.overlay__m-theme-dark {
    background: rgba(0,0,0,.7);
}

.overlay__m-theme-light {
    background: rgba(255, 255,255,.3);
}

.overlay__m-theme-white {
    background: #fff;
}

.overlay__m-theme-transparent {
    background: transparent;
}
