.main {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    user-select: none;
}

.main__main {
    display: flex;
    flex-direction: column;
    border-right: 2px #1b1d24 solid;
    flex-grow: 1;
}

.main__sidebar {
    min-width: 350px;
    box-sizing: border-box;
    background-color: #45464e;
    position: relative;
}

.main__main-canvas {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.main__main-canvas-controls {
    height: 88px;
    background: #1b1d24;
}

.main__messenger {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 4;
    cursor: pointer;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border: #00ad66 2px solid;
    background: #1b1d24;
    font-weight: 500;
}

.main__toolbar {
    background-color: #2f313a;
    border-top: 2px #1b1d24 solid;
    border-bottom: 2px #1b1d24 solid;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.main__toolbar-button {
    height: 30px;
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding: 0 16px 0 0;
}

.main__notifications {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.main__notifications > *:not(:first-child) {
    margin: 2px 0;
}
