.label-tags {
    display: flex;
    flex-flow: column nowrap;
}

.label-tags__row {
    display: flex;
    flex-flow: row nowrap;
    padding: 5px 0;
}

.label-tags__tag-icon {
    width: 16px;
    height: 16px;
    background: url('../../../images/tag.svg') no-repeat center;

    background-size: contain;
    align-self: center;
}

.label-tags__tag-value {
    padding: 3px 0;
    font-size: 12px;
    color: #acadb2;
}

.label-tags__select {
    flex: 1 1 auto;
    padding: 0 30px 0 14px;
}

.label-tags__select__m-add-new {
    padding: 0 54px 0 14px;
}

.label-tags__controls {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
}

.label-tags__remove-button {
    width: 24px;
    height: 24px;
    background: url('../../../images/remove-button.svg') no-repeat center;
    cursor: pointer;
}
