.round-button {
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid 2px #349563;
    background: transparent;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.round-button__m-disabled {
    opacity: .5;
    cursor: default;
}
