.ui-huddle-input {
	background-color: #25272F;
	border: 1px #414249 solid;
	border-radius: 3px;
	height: 45px;
	color: #fff;
	display: flex;
	align-items: center;
	flex-flow: row nowrap;
	flex: 1 1 auto;
	cursor: pointer;
}

.ui-huddle-input__m-active {
	background-color: #1B1D24;
}

.ui-huddle-input__content {
	padding: 0 8px 0 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	font-size: 15px;
	flex: 1 1 auto;
}

.ui-huddle-input__arrow {
	width: 41px;
	height: 41px;
	background-image: url('../images/arrow.svg');
}

.ui-huddle-input__m-active .ui-huddle-input__arrow {
	transform: rotate(180deg);
}

.ui-huddle-input__item {
	padding: 0 8px;
	height: 40px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #999;
	cursor: pointer;
}

.ui-huddle-input__item:hover {
	background-color: #1B1D24;
}

.ui-huddle-input__item__m-selected {
	background-color: #1B1D24;
	cursor: default;
}

.ui-huddle-input-bg {
	background: transparent;
	box-shadow: none !important;
}

.ui-huddle-input-header {
	height: auto;
}

.ui-huddle-input-items {
	background-color: #25272F;
	border: 1px #414249 solid;
	border-radius: 3px;
	box-shadow: none !important;
}

.ui-huddle-input__tx-code {
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	padding: 0 8px 0 0;
}
