.resolve-layout-buttons__row {
    display: flex;
    flex-flow: row nowrap;
}

.resolve-layout-buttons__row:not(:first-child) {
    margin: 5px 0 0;
}

.resolve-layout-buttons__primary {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.resolve-layout-buttons__secondary {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.resolve-layout-buttons__button:not(:last-child) {
    padding-right: 8px;
}

.resolve-layout-buttons__export-button {
    padding: 0 15px;
}
