.resolver-auto-chart {
    width: 400px;
    padding: 0 24px 24px;
}

.resolver-auto-chart__findings {
    height: 350px;
    position: relative;
    overflow: hidden;
}

.resolver-auto-chart__findings-items {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.resolver-auto-chart__buttons {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding: 5px 0 0 0;
}

.resolver-auto-chart__buttons-primary {
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
}

.resolver-auto-chart__buttons-secondary {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    flex: 0 0 auto;
}

.resolver-auto-chart__button {
    margin: 0 10px 0 0;
}

.resolver-auto-chart__loader {
    margin-bottom: -2.5px;
    margin-right: 5px;
    filter: invert(1);
}
