.examination-select {
    max-width: 200px;
    height: 26px;
    background: none;
}

.examination-select__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    padding: 0 20px 0 5px;
    height: 26px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    box-sizing: border-box;
}

.examination-select__header__m-opened {
    background: #45464E;
}

.examination-select__header-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.examination-select__item {
    position: relative;
    padding: 5px 0 5px 10px;
    white-space: normal;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 12px;
    color: #fff;
    word-break: break-all;
    cursor: pointer;
}

.examination-select__item__m-required {
    padding-right: 27px;
}

.examination-select__item:hover {
    background-color: #57585F;
}

.examination-select__empty {
    padding: 6px;
    text-align: center;
    font-size: 14px;
    color: #fff;
}

.examination-select__indicator {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2px;
    width: 20px;
    height: 20px;
}

.examination-select__indicator::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../../images/dropdown-indicator.svg');
    transform: rotate(180deg);
    background-position: 50% 50%;
}

.examination-select__header__m-opened .examination-select__indicator::before {
    transform: rotate(0);
}
