.resolver-header {
    width: 100%;
    height: 76px;
    background: #25272f;

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.resolver-header__version {
    position: absolute;
    left: 11px;
    bottom: 6px;
    font-size: 10px;
    color: #c9cbd2;
}

.resolver-header__help {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0 16px;
}

.resolver-header__user-info {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
}

.resolver-header__smth {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-right: 41px;
}

.resolver-header__buttons {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.resolver-header__grow {
    flex: 1 1 auto;
}
