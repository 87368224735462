.image-shape-polygon {

}

.image-shape-polygon__m-highlighted {
    stroke-opacity: .5;
}

.image-shape-polygon__controls__m-move {
    cursor: move;
}

.image-shape-polygon__resize-control {
    cursor: grab;
}

.image-shape-polygon__resize-control__m-deny-edit {
    cursor: default;
}
