.resolver-image-notification {
    display: flex;
    flex-flow: row nowrap;
    background-color: #45464e;
    align-items: center;
}

.resolver-image-notification__content {
    flex: 1 1 auto;
    color: #c9cbd2;
    font-size: 10px;
    text-align: center;
}

.resolver-image-notification__m-type-error .resolver-image-notification__content {
    color: rgb(239, 83, 80);
}

.resolver-image-notification__close {
    position: relative;
}

.resolver-image-notification__close-button {
    position: relative;
    height: 16px;
    width: 16px;
    padding: 0;
    font-size: 14px;
    border-radius: 100%;
    background: url('../images/close.svg') no-repeat center;
    color: #97989c;
    border: none;
    cursor: pointer;
}

.resolver-image-notification__close-button:active,
.resolver-image-notification__close-button:focus {
    outline: none;
}
