.button {
    display: inline-block;
    cursor: pointer;
    border-radius: 25px;
    border: 2px solid transparent;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    text-align: center;
    white-space: nowrap;
}

.button__m-block {
    display: block;
    width: 100%;
}

.button__m-uppercase {
    text-transform: uppercase;
}

.button__m-disabled {
    opacity: .5;
    cursor: default;
}

.button:active,
.button:focus {
    outline: none;
}

.button__m-size-large {
    padding: 12px 25px;
}

.button__m-size-medium {
    padding: 10px 12px;
    font-size: 14px;
}

.button__m-size-small {
    padding: 5px 10px;
    font-size: 12px;
}

.button__m-theme-primary {
    background: #349563;
    color: #fff;
    border-color: #349563;
}

.button__m-theme-secondary {
    background: transparent;
    color: #fff;
    border-color: #349563;
}

.button__m-theme-warn {
    background: #EF5350;
    color: #fff;
    border-color: #EF5350;
}

.button__m-pulsing {
    animation: pulse 1.5s infinite;
    box-shadow: 0 0 0 0 rgba(0, 173, 102, .5);
}

.button__m-pulsing:hover {
    animation: none;
}

.m-small-screen .button__m-size-large {
    padding: 12px 15px;
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(.9);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 50px rgba(0, 173, 102, 0);
    }
    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 rgba(0, 173, 102, 0);
    }
}
