.feedback-container {
    position: absolute;
    top: 16px;
    right: 353px;
    z-index: 1;
}

.feedback {
    border-radius: 5px;
    border: 1px solid #00AD66;
    width: 314px;
    height: 44px;
    background: #25272f;
}

.feedback-vote {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 10px;
}

.feedback-vote__text {
    flex: 1 1 auto;
    font-size: 12px;
    line-height: 14px;

    color: #00AD66;
}
.feedback-vote__votes {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.feedback-vote__vote-button {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.feedback-vote__vote-button:not(:last-child) {
    margin-right: 16px;
}

.feedback-vote__vote-button__m-like {
    background: url(../images/like.svg) no-repeat;
}

.feedback-vote__vote-button__m-dislike {
    background: url(../images/dislike.svg) no-repeat;
}

.feedback-comment {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 10px;
}

.feedback-comment__content {
    flex: 1 1 auto;
    font-size: 12px;
    line-height: 14px;
    padding-right: 20px;
    color: #00AD66;
}

.feedback-comment__buttons {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.feedback-comment__button {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.feedback-comment__button__m-agree {
    background: url(../images/agree.svg) no-repeat;
}

.feedback-comment__button__m-disagree {
    background: url(../images/disagree.svg) no-repeat;
}

.feedback-comment__label {
    color: #000;
}

.feedback-comment__input::placeholder {
    font-size: 12px;
    color: #C9CBD2;
}
