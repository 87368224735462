.collection__controls {
    flex: 1 1 auto;
}

.collection__controls-items {
    display: flex;
    flex-flow: row;
}

.collection__controls-item {
    cursor: pointer;
    color: #00ad66;
    text-align: center;
    padding-right: 15px;
}

.collection__no_image {
    font-weight: 600;
    font-size: 30px;
    padding-bottom: 40px;
    letter-spacing: 0.8px;
    color: #3d6053;
}

.collection__upload_text {
    font-size: 17px;
    padding: 12px;
    letter-spacing: 0.5px;
}

.collection__controls-item-icon {
    display: inline-block;
    width: 100px;
    height: 100px;
    padding-bottom: 10px;

    position: relative;
}

.collection__controls-item-icon__m-upload-image {
    background: url(../images/button-upload-image.svg) no-repeat;
}

.collection__controls-item-icon__m-open-last-image {
    background: url(../images/button-open-recent.svg) no-repeat;
}

.collection__user-menu-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.collection-select-tooth {
    position: relative;
}

.collection-select-tooth__diagram {
    position: absolute;
    right: 0;
    top: 120px;
    min-width: 350px;
}

.collection__disable-header-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 76px;
    cursor: pointer;
}

.collection__disable-sidebar-layer {
    position: absolute;
    right: 0;
    top: 120px;
    width: 350px;
    bottom: 0;
    cursor: pointer;
}

.collection__disable-canvas-controls-layer {
    position: absolute;
    left: 0;
    right: 350px;
    bottom: 0;
    height: 88px;
    cursor: pointer;
}

.collection-tooth-selector-notification {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.collection-tooth-selector-notification__element {
    margin-left: 5px;
}

.collection__items {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.collection__top-panel {
    background: #25272fa3;
    box-shadow: rgb(0, 0, 0) -3px -1px 6px 1px, rgba(0, 0, 0, 0) 0 1px 4px;
    padding: 5px 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.collection__top-panel-text {
    color: #48b148;
    font-size: 17px;
    margin-right: 40px;
    font-weight: 400;
    letter-spacing: 0.3px;
    vertical-align: middle;
}

.collection__top-panel-img {
    width: 50px;
    height: 50px;
    background-size: cover;
    margin-right: 5px;
}
