.image-shapes {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.image-shapes__point {
    position: absolute;

    width: 10px;
    height: 10px;
    background: #fff;

    margin: -5px 0 0 -5px;

    border-radius: 50%;

    z-index: 3;
}

.image-shapes__svg {
    width: 100%;
    height: 100%;
    /*pointer-events: bounding-box;*/
}

.canvas-objects {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.canvas-objects svg {
    width: 100%;
    height: 100%;
    overflow: visible;
}
