.label {
    font-size: 14px;
    cursor: pointer;
}

.label-main {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
}

.label-name {
    flex: 1 1 auto;
    padding: 8px 3px;
}

.label-selector {
    flex: 1 1 auto;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 35px;
}

.label-measure-value {
    text-align: right;
    flex-shrink: 0;
    padding-right: 7px;
}

.label-confirm {
    width: 24px;
    flex-shrink: 0;
}

.label-remove {
    width: 24px;
    flex-shrink: 0;
}

.label-remove-button {
    width: 24px;
    height: 24px;
    background: url('../images/remove-button.svg') no-repeat center;
    cursor: pointer;
}

.label-measure-value-indicator {
    height: 1px;
    background: #25272e;
    position: relative;
}

.label-measure-value-indicator-line {
    width: 20%;
    height: 100%;
    margin-top: 2px;
}
