.tfv-main-layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-flow: column nowrap;

    min-width: 1080px;
}

.tfv-main-layout__header {
    border-bottom: 2px #1b1d24 solid;
    flex: 0 0 auto;
}

.tfv-main-layout-top-panel {
    flex: 0 0 auto;

    display: flex;
    flex-flow: row nowrap;
}

.tfv-main-layout-main-content {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
}

.tfv-main-layout__navigation {
    position: relative;
    height: 48px;
    /* flex: 0 0 auto;
 
     position: relative;
     min-height: 300px;
     height: 40%;*/
}

.tfv-main-layout__image-editor {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
}

.tfv-main-layout__editor-toolbar {
    flex: 1 1 auto;
    height: 40px;
    background-color: #2f313a;
    border-bottom: 2px solid #1b1d24;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.tfv-main-layout__list {
    flex: 1 1 auto;
    background-color: #1b1d24;
    position: relative;
    border-right: 2px solid #1b1d24;
    display: flex;
}

.tfv-main-layout__sidebar {
    flex: 0 0 auto;
    width: 413px;
    background-color: #25272f;
    position: relative;
    border-left: 2px #1b1d24 solid;
}

.tfv-main-layout__sidebar__m-collapsed {
    width: 0;
}

.tfv-main-layout__sidebar-panel {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    overflow-y: auto;
    margin-right: 2px;
}

.tfv-main-layout__sidebar-panel__m-bottom {
    bottom: 48px;
}

.tfv-main-layout--image-info {
    height: 40px;
    border-top: 2px solid #1b1d24;
}

.tfv-main-layout--dental-notation {
    width: 350px;
    height: 290px;
}


.tfv-main-layout--dental-notation .dental-notation-diagram__shift-numbering-buttons {
    margin-left: -25px;
}

.tfv-main-layout__sequential-mode-button {
    position: absolute;
    right: 10px;
    top: -37px;
    z-index: 90;
}


.tfv-main-layout__analyze-button {
    height: 30px;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding: 0 16px 0 0;
}

.tfv-main-layout__notifications {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.tfv-main-layout__notifications > *:not(:first-child) {
    margin: 2px 0;
}
