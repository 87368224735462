.resolver-sidebar {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow: hidden;
}

.resolver-sidebar__image-info {
    flex: 0 0 auto;
}

.resolver-sidebar__dental-notation {
    flex: 0 0 auto;
}

.resolver-sidebar-findings {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 16px 16px;
    background: #45464E;
}

.resolver-sidebar-findings-filters {
    flex: 0 0 auto;
    position: relative;
}

.resolver-sidebar-findings-filters::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #25272F;
}

.resolver-sidebar-findings-filters__primary {
    flex: 1 1 auto;
}

.resolver-sidebar-findings-filters__secondary {
    flex: 0 0 auto;
}

.resolver-sidebar-findings__list {
    flex: 1 1 auto;
    position: relative;
}

.resolver-sidebar-findings__footer {
    flex: 0 0 auto;
}

.resolver-sidebar-findings-items {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.resolver-sidebar__dental-notation {
    width: 350px;
    height: 175px;
}

.resolver-sidebar__dental-notation .dental-notation-diagram {
    transform: scale(1.17);
    transform-origin: 0 0;
}

.resolver-sidebar__dental-notation .dental-notation-diagram__top-row,
.resolver-sidebar__dental-notation .dental-notation-diagram__bottom-row {
    padding-top: 0;
    padding-bottom: 0;
}

.resolver-sidebar__dental-notation .dental-notation-diagram__shift-numbering-buttons {
    top: 66px;
}
