.stats {
    position: relative;
    flex: 1 1 auto;
}

.stats-header {
    flex: 0 0 auto;
    background: #25272f;
    height: 48px;
    margin-top: 2px;
    padding: 0 20px;
    display: flex;
    align-items: center;
}

.stats-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

.stats-status {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stats-user-selector {
    width: 200px;
    flex: 0 0 auto;
}

.stats-content {
    flex: 1 1 auto;
    position: relative;
    overflow: auto;
}

.stats-error-message {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.stats-error-message__button {
    padding-top: 10px;
}

.stats-cards {
    display: flex;
    flex-flow: row nowrap;
    padding: 24px 20px;
}

.stats-card-item {
    margin-right: 20px;
}

.stats-card-item-summary,
.stats-card-item-info {
    width: 280px;
    height: 160px;
}

.stats-card-item-summary__content,
.stats-card-item-info__content {
    padding: 16px 14px;
}

.stats-card-item-summary__header,
.stats-card-item-info__header {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #C9CBD2;
}

.stats-card-item-summary__price,
.stats-card-item-info__price {
    padding-top: 24px;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;/* identical to box height */
    display: flex;
    align-items: center;

    color: #00AD66;
}

.stats-stats {
    border-radius: 5px;
    margin: 20px 20px 0;
}

.stats-stats__tabs {
    display: flex;
    flex-flow: row nowrap;
    background: #25272F;
}

.stats-stats__tab {
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 0 24px;
    cursor: pointer;
}

.stats-stats__tab__m-selected {
    background: #4C4D55;
    cursor: default;
}

.stats-stats__grid {
    overflow-x: auto;
    overflow-y: auto;
    background: #57585F;
}
