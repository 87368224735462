.collection-card {
    cursor: pointer;
    min-height: 280px;
    min-width: 200px;
    margin: 5px;
    background: linear-gradient(rgba(73, 75, 88, 0.3) 0%,
        rgba(77, 77, 77, 0.7) 50%, rgba(80, 80, 80, 0.5) 100%);
}

.collection-card__loader {
    align-self: flex-end;
    margin-top: auto;
    width:100%;
}

.collection-card__description {
    height: 30px;
    overflow: hidden;
    position: relative;
}
.collection-card__description-content {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.collection-card__description-content-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
    font-size: 14px;
}

.collection-card__badges {
    position: absolute;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

.collection-card__images-num-badge {
    background: #1f1e1ecc;
    padding: 8px 5px 9px 10px;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.collection-card__default-badge {
    background: #134a29c4;
    padding: 8px 5px 9px 10px;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.collection-card__deleted-badge {
    background: #75070785;
    padding: 8px 5px 9px 10px;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.collection-card__shared-badge {
    background: #4a4113c4;
    padding: 8px 5px 9px 10px;
    font-size: 13px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}

.m-small-screen .collection-card {
    min-height: auto;
}
