.hint {
    position: absolute;
    border-radius: 5px;
    background-color: #25272f;
    border: solid 1px #00ad66;
    z-index: 100;
}

.hint__content {
    font-size: 12px;
    font-weight: 500;
    color: #00ad66;
    white-space: nowrap;
    padding: 8px 8px;
    background: #25272f;
    position: relative;
    border-radius: 5px;
    z-index: 1;
}

.hint__indicator {
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -9px;
    width: 18px;
    height: 18px;
    background-color: #25272f;
    border: solid 1px #00ad66;
    transform: rotate(45deg);
}

.hint__global-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
