.pagination {
    display: flex;
    flex-flow: row nowrap;
    color: #fff;
}

.pagination__position {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 28px 0 13px;
    font-size: 14px;
}

.pagination__control {
    margin-right: 15px;
}

.pagination__icon {
    display: inline-block;
}

.pagination__icon__m-prev {
    background-image: url(../images/prev.svg);
    width: 8px;
    height: 12px;
    position: relative;
    top: 1px;
    margin-right: 5px
}

.pagination__icon__m-next {
    background-image: url(../images/next.svg);
    width: 8px;
    height: 12px;
    position: relative;
    top: 1px;
    margin-left: 5px
}

.pagination__icon__m-first {
    background-image: url(../images/first.svg);
    width: 14px;
    height: 12px;
}

.pagination__icon__m-last {
    background-image: url(../images/last.svg);
    width: 14px;
    height: 12px;
}
