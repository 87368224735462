.draw-boneloss {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: default;
}

.draw-boneloss__svg {
    width: 100%;
    height: 100%;
    pointer-events: bounding-box;
}

.draw-boneloss__hint {
    position: absolute;
    width: 20px;
    height: 20px;
    font: 11px monospace;

    margin: -15px 0 0 -15px;
}
