.canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: #1b1d24;
    user-select: none;
}

.canvas__image {
    width: 100%;
    height: 100%;
    font-size: 0;
    position: absolute;
    left: 0;
    top: 0;
}

.canvas__image-viewport {
    display: inline-block;
}

.canvas__image-viewport__m-moving {
    cursor: move;
}

.canvas__image-viewport__m-magnifying {
    cursor: zoom-in;
}

.canvas__zoom-range-container {
    position: fixed;
    left: 10px;
    top: 120px;
    background-color: #000;
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
}

.canvas__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.canvas-grid {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}

.canvas__auto-rotate {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: rgb(51,51,51);
    background-image: url('../images/reverse-auto-rotate.svg');
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.viewport {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    user-select: none;
}

.viewport__canvas {
    display: inline-block;
}
