.avatar {
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    overflow: hidden;
}

.avatar__image {
    display: block;
    width: 100%;
    height: 100%;
}
