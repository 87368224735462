.popup-dialog-header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	box-sizing: border-box;
	overflow: hidden;
	padding: 15px 8px;
	background: #4c4d55;
	color: #fff;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	backface-visibility: hidden;
	font-size: 14px;
}

.popup-dialog-header__title {
	flex: 1 1 auto;
	display: inline-block;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.popup-dialog-header__icon {
	display: inline-block;
	margin-right: 10px;
	width: 20px;
	height: 20px;
}

.popup-dialog-header__close-button {
	padding: 5px;
	cursor: pointer;
}

.popup-dialog-header__icon__m-success {
	background: url('../images/success.svg');
}

.popup-dialog-header__icon__m-error {
	background: url('../images/error.svg');
}

.popup-dialog-header__icon__m-list {
	background: url('../images/list.svg');
}
