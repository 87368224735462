.loading {
    display: inline-block;
}

.loading__indicator {
    width: 100%;
    height: 100%;
    background: url('../images/loading.svg');
    background-size: cover;
}
