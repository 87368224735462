.editor-tooth-selector__header {
    position: relative;
    padding: 0 0 0 4px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
}

.editor-tooth-selector__indicator {
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -2px;
}

.editor-tooth-selector__item {
    position: relative;
    padding: 5px 0 5px 4px;
    font-size: 12px;
    color: #fff;
}

.editor-tooth-selector__item:hover {
    background-color: #66676d;
}

.editor-tooth-selector__select {
    width: 120px;
    height: 28px
}
