.app-entry {

}

.app-entry__error {
	background-color: #1b1d24;
	color: #fff;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
}
