.resolver-findings {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.resolver-findings__remove-button {
    width: 24px;
    height: 24px;
    background: url('../../../../../images/remove-button.svg') no-repeat center;
    cursor: pointer;
}

.resolver-findings__tooth {
    flex: 0 0 auto;
    width: 36px;
    padding-right: 10px;
}

.resolver-findings__tooth-key {
    width: 28px;
    height: 28px;
    border: 2px transparent solid;
    border-radius: 50%;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.resolver-findings-grid {
    position: relative;
    font-size: 12px;
    height: 100%;
}

.resolver-findings-grid-wrapper {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

.resolver-findings-grid-rows-wrapper {
    flex: 1 1 auto;
    position: relative;
}
.resolver-findings-grid-rows-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.resolver-findings-grid-collection-name {
    text-decoration: underline;
    cursor: pointer;
}

.resolver-findings-grid__header-row {
    flex: 0 0 auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    box-sizing: border-box;

    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

.resolver-findings-grid__content-row {
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    box-sizing: border-box;
    border-top: none;
}


.resolver-findings-grid__content-row__m-highlighted {
    background-color: #25272f;
}

.resolver-findings-grid__content-row__m-selected {
    background-color: #25272f;
}

.resolver-findings-grid__content-row__m-selected .switch__m-checked .switch__handle {
    background: #25272f;
}

.resolver-findings-grid__content-row:hover:not(.resolver-findings-grid__content-row__m-selected) {
    background-color: #66676d;
}

.resolver-findings-grid__header-cell {
    flex: 0 0 auto;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;

    box-sizing: border-box;
    padding: 7px 2px;

    text-align: left;
    font-weight: 500;

    color: #c9cbd2;
}

.resolver-findings-grid__content-cell {
    flex: 0 0 auto;

    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;

    box-sizing: border-box;
    padding: 6px 2px;

    color: #c9cbd2;
}

.resolver-findings-grid__content-cell__m-tooth .resolver-findings-grid__content-cell-content {
    width: 40px;
}

.resolver-findings-grid__content-cell__m-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}


.resolver-findings-grid__content-cell__m-surface .resolver-findings-grid__content-cell-content {
    min-width: 20px;
    cursor: pointer;
}

.resolver-findings-grid__content-cell__m-params .resolver-findings-grid__content-cell-content,
.resolver-findings-grid__content-cell__m-date .resolver-findings-grid__content-cell-content {
    width: 100%;
}

.resolver-findings-grid__header-cell-content {
    flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.resolver-findings-grid__content-cell-content {
    flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.resolver-findings-grid-selector .form-custom-select__header-content {
    padding: 0 25px 0 5px;
}

.resolver-findings__badges {
    display: flex;
    flex-flow: row wrap;
}

.resolver-findings__badge {
    display: inline-flex;
    margin: 4px 4px 0 0;
}
.resolver-findings__badge .resolver-findings-validation-wrapper__content {
    font-size: 10px;
    line-height: 12px;
}

.resolver-findings__treatment-button {
    width: 24px;
    height: 24px;
    background: url('../../../../../images/add-treatment.svg') no-repeat center;
    cursor: pointer;
}


.resolver-findings-grid__content-cell__m-surface,
.resolver-findings-grid__content-cell__m-treatment {
    align-items: center;
    text-align: center;
}

.resolver-findings-grid__content-cell__m-surface .resolver-findings-validation-wrapper__content {
    justify-content: center;
}

.resolver-findings-grid__content-cell__m-surface .resolver-findings-grid__content-cell-content,
.resolver-findings-grid__content-cell__m-treatment .resolver-findings-grid__content-cell-content {
    width: 100%;
}


.resolver-findings-grid__header-cell__m-surface,
.resolver-findings-grid__header-cell__m-treatment {
    align-items: center;
}
