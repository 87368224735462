.dental-notation-diagram {
    position: relative;
    background-color: #25272f;
}

.dental-notation-diagram__image {
    display: block;
    cursor: pointer;
    padding-left: 15px;
}

.dental-notation-diagram__top-row,
.dental-notation-diagram__bottom-row {
    align-items: center;
    justify-content: space-around;
    padding: 10px 12px;
    display: flex;
}

.dental-notation-diagram__tooth-key {
    display: inline-block;
    font-size: 10px;

    color: #838383;

    border: 2px transparent solid;
    border-radius: 50%;

    width: 20px;
    height: 20px;
    box-sizing: border-box;
    text-align: center;
    line-height: 16px;
}

.dental-notation-diagram__tooth-key__m-with-pathologies {
    color: #ef5350;
}

.dental-notation-diagram__tooth-key__m-selected {
    border-color: #97989c;
    color: #97989c;
}

.dental-notation-diagram__tooth-key__m-selected.dental-notation-diagram__tooth-key__m-has-primary-box {
    color: #00ad66;
    border-color: #00ad66;
}

.dental-notation-diagram__tooth-key__m-selected.dental-notation-diagram__tooth-key__m-no-pathologies:not(.dental-notation-diagram__tooth-key__m-has-primary-box) {
    border-color: #979797;
}

.dental-notation-diagram__tooth-key__m-selected.dental-notation-diagram__tooth-key__m-with-pathologies {
    color: #ef5350;
    border-color: #ef5350;
}

.dental-notation-diagram__tooth-key__m-notified {
    color: #FFF024;
}

.dental-notation-diagram__tooth-finding {
    white-space: nowrap;
    padding: 2px 0;
}

.dental-notation-diagram__tooltip-bottom {
    padding-top: 7px;
}

.dental-notation-diagram__shift-numbering {
    padding: 10px 15px 0 15px;
}

.dental-notation-diagram__shift-numbering-buttons {
    position: absolute;
    top: 86px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dental-notation-diagram__shift-numbering-button-wrapper:first-child {
    margin-right: 10px;
}
