.ui-huddle-tile-filter-counter {
	position: relative;
}

.ui-huddle-tile-filter-counter__count {
	color: #009B6D;
	font-weight: 300;
	font-size: 42px;
	line-height: 41px;
	padding: 0 0 6px 0;
}

.ui-huddle-tile-filter-counter__text {
	color: #FFF;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
}
