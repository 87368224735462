.tooth-actions {
    position: absolute;
    inset: 0;
    pointer-events: none;
}

.tooth-actions__tooth {
    position: absolute;
    left:0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
