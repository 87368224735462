.user-info {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    position: relative;
}

.user-info__auth-buttons {
    display: flex;
    flex-flow: row nowrap;
}
.user-info__auth-button:not(:last-child) {
    margin-right: 10px;
}

.user-info__username {
    width: 153px;
    font-size: 14px;
    color: #acadb2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.user-info__user-menu-button {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.user-info__user-menu-button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -5px;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
    border-top: 5px rgba(255,255,255,.6) solid;
}

.user-info__user-menu-button__m-opened:before {
    border-top: none;
    border-bottom: 5px rgba(255,255,255,.6) solid;
}

.user-info__dropdown-menu {
    background-color: #57585f;
    position: absolute;
    min-width: 200px;
    top: 100%;
    right: 0;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 100;
    padding: 10px;
}

.user-info__dropdown-menu:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 41px;
    border-left: 10px transparent solid;
    border-right: 10px transparent solid;
    border-bottom: 10px #57585f solid;
}
