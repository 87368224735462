.label-tooth-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
}

.label-tooth-controls__tooth-key-select {
    height: 35px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding-right: 10px;
}

.label-tooth-controls__form {
    flex: 1 1 auto;
}

.label-tooth-controls__remove-tooth-button {
    font-size: 14px;
    color: #ef5350;
    font-weight: 500;
    cursor: pointer;
    padding: 0 0 0 5px;
}

.label-tooth-controls-diagnosis-selector {
    font-size: 14px;
}
