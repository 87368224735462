.label-list-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
}


.label-list-wrapper__items {
    position: relative;
    height: 100%;
    flex: 1 1 auto;
    margin: 16px 0 0 0;
}

.label-list-wrapper__items__m-with-controls {
    margin-top: 0;
}

.label-list-wrapper__items__m-with-filters {
    margin-top: 0;
}

.label-list-wrapper__filters {
    display: flex;
    flex-flow: row nowrap;
    height: 30px;
    justify-content: flex-start;
    flex: 0 0 auto;
    padding: 0 21px;
}

.label-list-wrapper__filters__m-with-confidence-filter {
    justify-content: flex-end;
}

.label-list-wrapper__filters__m-with-offset {
    margin: 16px 0 0 0;
}

.label-list-wrapper__filter {
    flex: 1 1 auto;
    position: relative;
    font-size: 14px;
    line-height: 16px;
    max-width: 50%;
}

.label-list-wrapper__filter__m-confidence {
    width: 150px;
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
}

.label-list-wrapper__filter-label {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 11px;
    color: #c9cbd2;
}

.label-list-wrapper__filter-value {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #c9cbd2;
}

.label-list-wrapper__filter-control {
    position: relative;
    top: 7px;
}

.label-list-wrapper__m-disabled:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
}

.label-list-wrapper__controls {
    height: 61px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 21px;
    position: relative;
    flex: 0 0 auto;
}

.findings-wrapper__controls {
    height: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 21px;
    position: relative;
}

.label-list-wrapper__controls:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: rgba(0,0,0,.5);
    left: 21px;
    right: 21px;
    bottom: 13px;
}

.label-list-wrapper__show-all-classes-toggle {
    font-size: 14px;
    color: #c9cbd2;
    font-weight: 500;
    cursor: pointer;
    flex: 1 1 auto;
}

.label-list-wrapper__confirm-all-classes-toggle {
    font-size: 14px;
    color: #00ad66;
    font-weight: 500;
    cursor: pointer;
    flex: 0 1 auto;

}

.label-list-wrapper__remove-all-classes-toggle {
    font-size: 14px;
    color: #ef5350;
    font-weight: 500;
    cursor: pointer;
    flex: 0 1 auto;
    padding: 0 0 0 20px;
}

.label-list-wrapper-class-filter-dropdown {
    background: #2F313A;
    font-size: 12px;
}

.label-list-wrapper-class-filter-dropdown__item {
    display: flex;
    flex-flow: row;
    align-items: center;

    font-size: 12px;
    padding: 2px 3px;
    word-break: break-all;
}

.label-list-wrapper-class-filter-dropdown__item-control {
    padding-right: 2px;
}
