.resolver-findings-badge-selector {
    background-color: #AEBAB8;

}

.resolver-findings-badge-selector__items {
    background-color: #AEBAB8;
    border-top: 1px #8d8f96 solid;
    box-shadow: none;
}
.resolver-findings-badge-selector__header {
    height: auto;
}

.resolver-findings-badge-selector__header-content {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    padding: 2px 20px 2px 4px;
    width: 100%;
    font-size: 10px;
    color: rgb(69, 70, 78);
}

.resolver-findings-badge-selector__header-value {
    margin-right: 5px;
}

.resolver-findings-badge-selector__indicator {
    position: absolute;
    top: 50%;
    right: 9px;
    margin-top: -2px;
    width: 10px;
    height: 6px;
}

.resolver-findings-badge-selector__indicator svg {
    display: block;
    transform: scale(1, -1);
}

.resolver-findings-badge-selector__indicator svg path {
    fill: #45464e;
}

.resolver-findings-badge-selector__indicator__m-opened svg {
    transform: scale(1, 1);
}

.resolver-findings-badge-selector__item {
    position: relative;
    padding: 0 4px;
    white-space: normal;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 10px;
    color: rgb(69, 70, 78);
    word-break: break-all;
    cursor: pointer;
}

.resolver-findings-badge-selector__item:hover {
    background-color: #99aba8;
}
