.tfv-tooth-action {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 1));
    color: #fff;
    pointer-events: auto;
    cursor: pointer;
}

.tfv-tooth-action__menu {
    color: #fff;
}

.tfv-tooth-action__remove-action {
    position: absolute;
    top: 0;
    left: 20px;
    cursor: pointer;
}

.tfv-tooth-action__remove-icon {
    background-image: url('../images/remove.svg');
    background-size: 16px;
    width: 16px;
    height: 16px;
}

.tfv-tooth-action__tooth-key__m-with-conflict {
    color: #ef5350;
}
