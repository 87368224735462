.resolver-report {
    padding: 40px;
    width: 800px;
    box-sizing: border-box;
}

.resolver-report__caption {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #45464E;
}

.resolver-report-button {
    outline: none;
    display: inline-flex;
    background: #349563;
    border-radius: 22px;
    height: 36px;
    padding: 0 11px;

    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    align-items: center;
    text-align: center;

    color: #FFFFFF;

    cursor: pointer;
}

.resolver-report-button__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.resolver-report-button__icon {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    margin: 0 6px 0 0;
}

.resolver-report-button__icon__m-icon-preview {
    background-image: url('../images/preview.svg');
}

.resolver-report-button__icon__m-icon-email {
    background-image: url('../images/email.svg');
}

.resolver-report-button__icon__m-icon-print {
    background-image: url('../images/print.svg');
}

.resolver-report-button__icon__m-icon-edit {
    background-image: url('../images/edit.svg');
}

.resolver-report-header {
    display: flex;
    flex-flow: row nowrap;
}

.resolver-report-header-client-logo {
    flex: 0 0 auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;

    color: #000000;
}

.resolver-report-header-client-logo__image {
    position: absolute;
    transform: translate(0, -25%);
}

.resolver-report-header-buttons {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0 24px 0 0;
    align-items: center;
}

.resolver-report-header__button:not(:last-child) {
    margin-right: 8px;
}

.resolver-report-header-our-logo {
    flex: 0 0 auto;
}


.resolver-report-patient-data__caption {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #1B1D24;
}

.resolver-report-patient-data__row {
    display: flex;
    flex-flow: row nowrap;
    padding: 24px 0 0 0;
}

.resolver-report-patient-data__cell {
    overflow: hidden;
}

.resolver-report-patient-data__cell__m-long {
    width: 50%;
}

.resolver-report-patient-data__cell__m-medium {
    width: 25%;
}

.resolver-report-patient-data__cell__m-short {
    width: 16.6%;
}

.resolver-report-patient-data-field__label {
    color: #989898 !important;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
}

.resolver-report-patient-data-field__input {
    color: #25272F !important;
    font-size: 14px;
    line-height: 100% !important;
    height: 100% !important;
    border-bottom: 1px solid #989898;
}

.resolver-report-patient-data-field__value {
    color: #25272F;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}



.resolver-report-dental-chart {
    width: 720px;
    height: 225px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
}

.resolver-report-dental-chart__row {
    display: flex;
    flex-flow: row nowrap;
    transform: scale(1.45);
}

.resolver-report-dental-chart__row__m-top {
    position: absolute;
    top: 68px;
}

.resolver-report-dental-chart__row__m-bottom {
    position: absolute;
    top: 132px;
}

.resolver-report-dental-chart__tooth {
    margin: 0 2px;
    position: relative;
}

.resolver-report-dental-chart__tooth__m-key-16 {
    top: -2px;
}

.resolver-report-dental-chart__tooth__m-key-15 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-14 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-13 {
    top: -8px;
}

.resolver-report-dental-chart__tooth__m-key-12 {
    top: -6px;
}

.resolver-report-dental-chart__tooth__m-key-11 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-21 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-22 {
    top: -6px;
}

.resolver-report-dental-chart__tooth__m-key-23 {
    top: -8px;
}

.resolver-report-dental-chart__tooth__m-key-24 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-25 {
    top: -4px;
}

.resolver-report-dental-chart__tooth__m-key-26 {
    top: -2px;
}

.resolver-report-dental-chart__tooth__m-key-47 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-46 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-45 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-44 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-43 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-42 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-41 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-31 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-32 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-33 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-34 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-35 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-36 {
    top: -1px;
}

.resolver-report-dental-chart__tooth__m-key-37 {
    top: -1px;
}

.resolver-report-dental-chart__row-keys {
    position: absolute;
    display: flex;
    top: 43px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #838383;
}

.resolver-report-dental-chart__row-keys__m-top {
    top: 20px;
}

.resolver-report-dental-chart__row-keys__m-bottom {
    top: 190px;
}

.resolver-report-dental-chart__row-keys-key {
    width: 34px;
}

.resolver-report-dental-chart-legend {
    text-align: center;
}

.resolver-report-dental-chart-legend__item {
    display: inline-block;
    margin-right: 19px;
}

.resolver-report-dental-chart-legend__item:last-child {
    margin-right: 0;
}

.resolver-report-dental-chart-legend__title {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: #45464E;
}

.resolver-report-dental-chart-legend__indicator {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}



.resolver-report-findings__row {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0 16px 0;
}

.resolver-report-findings__left {
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 auto;
}

.resolver-report-findings__right {
    /*display: flex;*/
    /*flex-flow: row nowrap;*/
    flex: 1 1 auto;
}

.resolver-report-findings__image {
    display: block;
    width: 90px;
    height: 90px;
    background: #000;
}

.resolver-report-findings__tooth {
    padding: 0 16px 0 24px;
}

.resolver-report-findings__tooth-indicator {
    width: 32px;
    height: 32px;
    border: 2px #ccc solid;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: #45464E;
}

.resolver-report-findings__findings {
    padding: 5px 0 0 0;
}

.resolver-report-findings__finding {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #45464E;
}

.resolver-report-findings-finding-comment {
    padding: 12px 0 0;
}

.resolver-report-findings-finding-comment__label {
    color: #989898 !important;
    font-size: 12px;
    line-height: 14px;
}

.resolver-report-findings-finding-comment__input {
    color: #25272F !important;
    font-size: 14px;
    line-height: 30px;
    border-bottom: 1px solid #989898;
}

.resolver-report-findings-finding-comment__value {
    color: #25272F;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
}




.resolver-report-bottom__links {
    padding: 32px 0 0;
}

.resolver-report-bottom__link {
    font-size: 12px;
    line-height: 14px;
    padding: 0 0 5px 0;
}

.resolver-report-bottom__info {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    flex-flow: row nowrap;
    color: #1B1D24;
    padding: 32px 0 0;
}

.resolver-report-bottom__info-left {
    flex: 1 1 auto;
}

.resolver-report-bottom__info-right {
    flex: 0 0 auto;
}

.resolver-report-bottom__logo-start {
    display: inline;
    font-weight: 500;
}

.resolver-report-bottom__logo-end {
    display: inline;
    color: #00AD66;;
    font-weight: 500;
}


@media print {
    .resolver-report {
        padding: 0;
    }

    .resolver-report-findings__row {
        page-break-inside: avoid;
    }

    .resolver-report-header-buttons > * {
        display: none;
    }
}
