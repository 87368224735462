.switch {
    border: 1px solid #989898;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    height: 16px;
    position: relative;
}

.switch__handle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #989898;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(2px, 2px);
}

.switch__m-checked {
    background: #349563;
    border-color: transparent;
}

.switch__m-checked .switch__handle {
    background: #45464E;
    transform: translate(26px, 2px);
}

.switch__m-disabled {
    cursor: not-allowed;
}
