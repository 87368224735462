.guest-header {
    height: 90px;
    flex: 0 0 auto;
    background-color: #25272f;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
}

.guest-header__version {
    position: absolute;
    left: 11px;
    bottom: 6px;
    font-size: 10px;
    color: #c9cbd2;
}

.guest-header-logo {
    flex: 0 1 auto;
}

.guest-header-auth {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding: 0 35px 0 0;
    height: 100%;
    align-items: center;
}

.guest-header-auth__button {
    font-size: 18px;
    font-weight: 500;
    margin-right: 50px;
    cursor: pointer;
}

.guest-header-auth__button__m-sign-in {
    color: rgba(255,255,255,.5);
    position: relative;
    padding-right: 25px;
}

.guest-header-auth__button__m-sign-in:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    border-top: 5px rgba(255,255,255,.5) solid;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
}

.guest-header-auth__button__m-free-trial {
    color: #00ad66;
    margin-right: 0;
}


.guest-header__sign-in-menu {
    position: absolute;
    top: 0;
    right: 120px;
    width: 245px;
    background-color: #45464e;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 1;
}
