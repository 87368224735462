.popup {
    width: 100%;
    height: 100%;
}

.popup-global-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
}
