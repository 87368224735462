.header {
    width: 100%;
    height: 76px;
    background: #25272f;

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header__version {
    position: absolute;
    left: 11px;
    bottom: 6px;
    font-size: 10px;
    color: #c9cbd2;
}

.header__button {
    display: inline-block;
}

.header__button:not(:last-child) {
    margin-right: 12px;
}


.header__image-controls {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.header__collection-controls {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-right: auto;
}

.header__stats-controls {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-right: auto;
}

.header__image-mode-controls {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.header__help {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0 16px;
}

.header__user-info {
    height: 100%;
    display: flex;
    align-items: center;
}

.header__smth {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-right: 41px;
}

.header__edit-collection {
    height: 42px
}

.header__edit-collection a {
    display: inline-block;
}

.header__disable-layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;
}

.header-collection-search {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-left: 20px;
}

.header-collection-search__input-wrapper {
    position: relative;
    top: -4px;
    padding-right: 24px;
}

.header-collection-search__button {
    width: 125px;
}
.header-collection-search__input {
    background: red;
}

.header-collection-search__input::placeholder {
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
}
