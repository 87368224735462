.resolver-collection {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-flow: column nowrap;

    min-width: 1080px;
}

.resolver-collection__header {
    flex: 0 0 auto;
    border-bottom: 2px #1b1d24 solid;
}

.resolver-collection__main {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: center;
}
