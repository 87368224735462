.resolve-add-label-date {
    cursor: pointer;
}

.resolve-add-label__date {
    /*font-size: 15px;*/
    padding: 5px 0;
    border-bottom: 1px #97989c solid;
}

.resolve-add-label-surface-dropdown {
    background: #2F313A;
    font-size: 12px;
}

.resolve-add-label-surface-dropdown__item {
    display: flex;
    flex-flow: row;
    align-items: center;

    font-size: 12px;
    padding: 2px 3px;
    word-break: break-all;
}

.resolve-add-label-surface-dropdown__item-control {
    padding-right: 5px;
}
