.huddle-grid {
	font-size: 12px;
	position: absolute;
	inset: 0;
}

.huddle-grid__header-row {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: stretch;

	box-sizing: border-box;
}

.huddle-grid__content-row {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;

	box-sizing: border-box;
	border-top: none;

	min-height: 45px;
}

.huddle-grid__content-row:hover {
	background: #25272F;
}

.huddle-grid__header-cell {
	flex: 0 0 auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	justify-content: center;

	box-sizing: border-box;
	padding: 15px 10px;

	font-weight: 700;
	font-size: 12px;
	line-height: 16px;

	color: #ccc;

	background: #333542;
}

.huddle-grid__header-cell:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.huddle-grid__header-cell:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.huddle-grid__header-cell[class*="value"],
.huddle-grid__header-cell[class*="detected"] {
	color: #fff;
	font-weight: 500;
}

.huddle-grid__content-cell {
	flex: 0 0 auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	justify-content: center;

	box-sizing: border-box;
	padding: 5px 10px;

	color: #c9cbd2;
}

.huddle-grid__content-cell[class*="value"],
.huddle-grid__content-cell[class*="detected"] {
	color: #fff;
	font-weight: 500;
}

.huddle-grid__header-cell-content {
	flex: 0 1 auto;

	overflow: hidden;
	text-overflow: ellipsis;
}

.huddle-grid__content-cell-content {
	flex: 0 1 auto;

	overflow: hidden;
	text-overflow: ellipsis;
}

.huddle-grid__label {
	height: 28px;
	display: inline-flex;
	flex-flow: row nowrap;
	border-radius: 3px;
	background-color: #C9C2C2;
	color: #000;
	align-items: center;
	padding: 0 10px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	margin: 2px 3px 2px 0;
}

.huddle-grid__label-inner {
	height: 20px;
	display: flex;
	flex-flow: row nowrap;
	border-radius: 3px;
	color: #FFF;
	background-color: rgba(0, 0, 0, .2);
	align-items: center;
	padding: 0 10px;
	margin-left: 5px;
}

.huddle-grid-last-visit-summary {
	display: inline-flex;
	flex-flow: row wrap;
	border-radius: 3px;
	align-items: center;
	padding: 2px 10px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	margin: 2px 3px 2px 0;
	color: #000;
}

.huddle-grid-last-visit-summary__name {
	margin-right: 10px;
}

.huddle-grid-last-visit-summary__label {
	height: 20px;
	display: flex;
	flex-flow: row nowrap;
	border-radius: 3px;
	color: rgba(255, 255, 255, .7);
	align-items: center;
	padding: 0 5px;
	margin: 2px 1px;
	background-color: rgba(0, 0, 0, .2);
}

.huddle-grid-last-visit-summary__label-name {
	color: #FFF;
}

.huddle-grid-last-visit-summary__m-pathological {
	background-color: #CDB3D2;
}

.huddle-grid-last-image {
	display: block;
	outline: none;
	border: 1px #8A8B94 solid;
	border-radius: 3px;
	padding: 3px 6px;
}

.huddle-grid-last-image__date {
	font-size: 12px;
	color: #999;
}

.huddle-grid-last-image__summary {
	font-size: 12px;
	color: #FFF;
}
