.change-email {
    width: 500px;
}

.change-email__form {
    display: flex;

    flex-direction: row;
}

.change-email__form-main,
.change-email__form-secondary {
    width: 50%;
    color: #acadb2;
}

.change-email__message {
    text-align: center;
}
.change-email__message-text {
    padding: 10px 0;
}

