.image-box {
    position: absolute;
    border: 2px #838383 solid;
	z-index: 1;
    box-sizing: border-box;
    opacity: 1;
    cursor: pointer;
}

.image-box__m-invisible {
    visibility: hidden;
}

.image-box__m-faded {
    opacity: .6;
}

.image-box__m-primary {
    border-style: dashed;
    opacity: .6;
}

.image-box__m-secondary {
    border-style: dotted;
}

.image-box__m-no-pathologies {
    border-color: #00ad66;
}

.image-box__m-has-pathologies {
    border-color: #ef5350;
}

.image-box__m-selected,
.image-box__m-highlighted {
	z-index: 2;
    border-width: 3px;
}

.image-box__m-selected {
    opacity: 1;
    z-index: 3;
}

.image-box__m-highlighted {
    opacity: .6;
}

.image-box-controls {
    width: 100%;
    height: 100%;
    position: relative;

    cursor: move;

    display: none;
}

.image-box-resize-controls__left-top,
.image-box-resize-controls__right-top,
.image-box-resize-controls__left-bottom,
.image-box-resize-controls__right-bottom {
    width: 8px;
    height: 8px;
    background-color: #0db266;
}

.image-box-resize-controls__left-top {
    position: absolute;
    left: -2px;
    top: -2px;
    cursor: nw-resize;
}

.image-box-resize-controls__right-top {
    position: absolute;
    right: -2px;
    top: -2px;
    cursor: ne-resize;
}

.image-box-resize-controls__left-bottom {
    position: absolute;
    left: -2px;
    bottom: -2px;
    cursor: sw-resize;
}

.image-box-resize-controls__right-bottom {
    position: absolute;
    right: -2px;
    bottom: -2px;
    cursor: se-resize;
}

.image-box__m-selected .image-box-controls {
    display: block;
}

.image-box__m-has-pathologies .image-box-resize-controls__left-top,
.image-box__m-has-pathologies .image-box-resize-controls__right-top,
.image-box__m-has-pathologies .image-box-resize-controls__left-bottom,
.image-box__m-has-pathologies .image-box-resize-controls__right-bottom {
    background-color: #ef5350;
}
