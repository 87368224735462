.canvas-controls-fda-complete__icon-complete {
    position: relative;
    padding-right: 15px;
}

.canvas-controls-fda-complete__icon-complete:before {
    content: '';
    background: url('../images/tick.svg') no-repeat;
    width: 15px;
    height: 16px;
    position: absolute;
    top: 0;
    right: -7px;
}
