.form-input {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    display: inline-block;
    font-size: 14px;
}

.form-input__element {
    background: transparent;
    color: #fff;
    border: none;
    padding: 5px 0;
}

.form-input__element::placeholder {
    color: #fff;
}

.form-input__element:disabled {
    opacity: .5;
}

.form-input__element:active,
.form-input__element:focus {
    outline: none;
}

.form-input__m-block {
    display: block;
}

.form-input__m-block .form-input__element {
    width: 100%;
}

.form-input__m-invalid {
    border-color: #ef5350;
}

.form-input__m-invalid .form-input__element {
    color: #ef5350;
}
