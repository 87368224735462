.form-checkbox {
    position: relative;

    display: inline-block;
}
.form-checkbox__input {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    font-size: 20px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
}

.form-checkbox__visual {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;

    text-align: center;
    background: url('../images/checkbox-off.svg');
}

.form-checkbox__visual__m-checked {
    background: url('../images/checkbox-on.svg');
}
