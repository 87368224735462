.form-custom-select {
	position: relative;
	cursor: pointer;
	font-size: 12px;
	user-select: none;
	border-radius: 5px;
	background-color: #45464e;
}

.form-custom-select.form-custom-select__m-opened {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	position: relative;
}

.form-custom-select.form-custom-select__m-opened.form-custom-select__m-axis-v__m-pos-start {
	border-radius: 0 0 5px 5px;
}

.form-custom-select.form-custom-select__m-opened.form-custom-select__m-axis-v__m-pos-end {
	border-radius: 5px 5px 0 0;
}

.form-custom-select__m-disabled:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(69,70,78,.7);
	border-radius: 5px;
	z-index: 2;
	cursor: default;
}

.form-custom-select__header {
	height: 28px;
	display: flex;
	align-items: center;
}


.form-custom-select__header-content {
	position: relative;
	padding: 0 30px 0 11px;
	width: 100%;
	font-weight: 500;
}

.form-custom-select__indicator-wrapper {
	position: absolute;
	top: 50%;
	right: 9px;
	margin-top: -2px;
}


.form-custom-select__indicator {
	border-top: 5px rgba(255, 255, 255, 0.3) solid;
	border-left: 5px transparent solid;
	border-right: 5px transparent solid;
}

.form-custom-select.form-custom-select__m-opened .form-custom-select__indicator {
	border-top: none;
	border-bottom: 5px rgba(255, 255, 255, 0.3) solid;
	border-left: 5px transparent solid;
	border-right: 5px transparent solid;
}

.form-custom-select__items {
	background-color: #45464e;
	max-height: 250px;
	box-sizing: border-box;
	overflow: auto;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	padding: 3px 0;
	font-size: 12px;
	color: #fff;
}

.form-custom-select__items__m-axis-v__m-pos-start {
	border-radius: 5px 5px 0 0;
}

.form-custom-select__items__m-axis-v__m-pos-end {
	border-radius: 0 0 5px 5px;
}

.form-custom-select__item {
	cursor: pointer;
	user-select: none;
}

.form-custom-select__item__m-selected {
	background: #25272f;
	cursor: default;
}

.form-custom-select__item-content {
	position: relative;
	padding: 5px 0 5px 11px;
	white-space: normal;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.form-custom-select__item-content:hover {
	background-color: #66676d;
}

.form-custom-select__item-content__m-disabled {
	opacity: .7;
	cursor: not-allowed;
}
