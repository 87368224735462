.image-controls {
    display: flex;
}

.image-controls__button {
    display: inline-block;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 100%;
    cursor: pointer;

    box-sizing: border-box;

    margin-right: 12px;

    background-position: 0;
    background-color: transparent;
    background-repeat: no-repeat;

    background-size: 44px 44px;

    border: 1px solid #383a41;
}

/*HACK*/
label.image-controls__button {
    position: relative;
    top: 1px;
}

.image-controls__button:disabled {
    cursor: default;

    opacity: .5;
}

.image-controls__button:active,
.image-controls__button:focus {
    outline: none;
}

.image-controls__button__m-active,
.image-controls__button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.image-controls__button__m-text {
    color: #989898;
}

.image-controls__button__m-collections {
    background-image: url(../images/collections.svg);
    background-size: 30px 30px;
    background-position: 6px 7px;
}

.image-controls__button__m-upload {
    background-image: url(../images/upload.svg);
    background-position: -1px -2px;
}

.image-controls__button__m-open {
    background-image: url(../images/open.svg);
    background-position: -1px -2px;
}

.image-controls__button__m-save {
    background-image: url(../images/save.svg);
}

.image-controls__button__m-share {
    background-image: url(../images/share.svg);
}

.image-controls__button__m-copy {
    background-image: url(../images/copy.svg);
}


.image-controls__button__m-treatment-plan {
    background-image: url(../images/treatment-plan.svg);
}

.image-controls__button__m-back {
    width: 44px;
    height: 44px
}

.image-controls__icon__m-collections {
    color: #97989c;
    width: 30px !important;
    height: auto !important;
    margin-top: 2px;
}
