.report {
    padding: 5px;
    width: 595px;
    box-sizing: border-box;
    overflow: auto;

    user-select: initial;
}

.report-wrapper {
    background-color: #fff;
}

.report-logo {
    height: 100%;
    padding: 0 0 20px 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.report-logo img {
    max-width: 100%;
}

.report-name {
    display: flex;
    justify-content: center;
}

.report-name__value {
    font-size: 20px;
}

.report-name select {
    font-size: 20px;
}

.report__snapshot-link,
.report__report-link {
    font-size: 10px;
    padding: 10px 0;
}

.report__report-link {
    padding-top: 5px;
}

.report__snapshot-link span,
.report__report-link span {
    font-weight: 500;
    color: #25272f;
}

.report__snapshot-link a,
.report__report-link a {
    text-decoration: underline;
    opacity: .6;
}

.report-info {
    margin: 20px 0 0;
    border-collapse: collapse;
    width: 100%;
    color: #25272f;
}

.report-info__value {
    display: none;
}

.report-info__control {
    margin: 5px 0;
}

.report-info__control::placeholder {
    font-size: 12px;
}

.report-info td {
    padding: 0;
    margin: 0;
    vertical-align: top;
}

.report__patient {
    display: flex;
    flex-flow: row nowrap;
}

.report__patient-name {
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.report__patient-id {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    padding-right: 10px;
}

.report__patient-info {
    font-size: 10px;
    font-weight: 500;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
}

.report__patient-info-item {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.report__clinic-name {
    font-weight: 500;
    font-size: 11px;
    position: relative;
    top: -3px;
}

.report-doctor-info {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.6;
    position: relative;
    top: -4px;
}

.report__normal-appearance-title {
    padding: 10px 0;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
}
.report__normal-appearance {
    font-size: 12px;
}

.report__missing-teeth-title {
    padding: 10px 0;
    font-weight: 500;
    font-size: 12px;
}
.report__missing-teeth {
    font-size: 12px;
}

.report__findings-title {
    padding: 10px 0 5px;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    flex-flow: row nowrap;
}

.report__findings {
    padding: 0 0 10px 0;
}

.report__finding {
    display: flex;
    flex-flow: row nowrap;
    padding: 2px 0;
}

.report__finding-item {
    display: flex;
    flex-flow: row nowrap;
    padding: 1px 0;
}

.report__finding-comment {
    padding: 0 0 0 10px;
    flex: 1 1 auto;
    font-size: 12px;
}

.report__finding-comment textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin-right: 5px;
}

.report__finding-number input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}

.report__finding-number__m-status-normal {
    border-color: #b0db72;
}

.report__finding-number__m-status-pathology {
    border-color: #d0011b;
}

.report__finding-tooth-key {
    width: 15px;
    height: 15px;
    border: 2px #ccc solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
    font-size: 9px;
    font-weight: 500;
}

.report__finding-tooth-key__m-status-normal {
    border-color: #b0db72;
}

.report__finding-tooth-key__m-status-pathology {
    border-color: #d0011b;
}

.report__finding-tooth-key-control {
    padding: 0;
    width: 100%;
    border: none;
    border-bottom: 1px #ccc solid;
    font-size: 10px;
    background: transparent;
    text-align: center;
    font-weight: 500;
}

.report__finding-column {
    width: 50px;
}

.report__finding-diagnosis {
    padding-left: 5px;
    font-size: 12px;
    text-align: left;
    padding-top: 2px;
    color: #25272f;
}

.report__teeth {
    width: 483px;
    height: 143px;
    margin: 10px 0;
    background: url('../../../images/teeth/jaw.svg') no-repeat;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
}

.report__teeth-row {
    display: flex;
    flex-flow: row nowrap;
}

.report__teeth-row__m-top {
    position: absolute;
    top: 68px;
}

.report__teeth-row__m-bottom {
    position: absolute;
    top: 132px;
}

.report__tooth {
    margin: 0 2px;
    position: relative;
}

.report__tooth__m-key-18 {
    left: 2px;
}
.report__tooth__m-key-17 {
    left: -1px;
}
.report__tooth__m-key-16 {
    left: -6px;
    top: -2px;
}
.report__tooth__m-key-15 {
    top: -4px;
    left: -7px;
}
.report__tooth__m-key-14 {
    top: -4px;
    left: -6px;
}
.report__tooth__m-key-13 {
    top: -8px;
    left: -4px;
}
.report__tooth__m-key-12 {
    top: -6px;
    left: -1px;
}
.report__tooth__m-key-11 {
    top: -4px;
    left: 2px;
}
.report__tooth__m-key-21 {
    top: -4px;
}
.report__tooth__m-key-22 {
    top: -6px;
    left: 2px;
}
.report__tooth__m-key-23 {
    top: -8px;
    left: 4px;
}
.report__tooth__m-key-24 {
    top: -4px;
    left: 5px;
}
.report__tooth__m-key-25 {
    top: -4px;
    left: 6px;
}
.report__tooth__m-key-26 {
    top: -2px;
    left: 5px;
}
.report__tooth__m-key-27 {
    left: 1px;
}
.report__tooth__m-key-28 {
    left: -2px;
}
.report__tooth__m-key-48 {
    left: -2px;
}
.report__tooth__m-key-47 {
    left: -7px;
    top: -1px;
}
.report__tooth__m-key-46 {
    left: -12px;
    top: -1px;
}
.report__tooth__m-key-45 {
    left: -14px;
    top: -1px;
}
.report__tooth__m-key-44 {
    left: -13px;
    top: -1px;
}
.report__tooth__m-key-43 {
    left: -11px;
    top: -1px;
}
.report__tooth__m-key-42 {
    left: -8px;
    top: -1px;
}
.report__tooth__m-key-41 {
    left: -2px;
    top: -1px;
}
.report__tooth__m-key-31 {
    left: 3px;
    top: -1px;
}
.report__tooth__m-key-32 {
    left: 8px;
    top: -1px;
}
.report__tooth__m-key-33 {
    left: 10px;
    top: -1px;
}
.report__tooth__m-key-34 {
    left: 13px;
    top: -1px;
}
.report__tooth__m-key-35 {
    left: 14px;
    top: -1px;
}
.report__tooth__m-key-36 {
    left: 12px;
    top: -1px;
}
.report__tooth__m-key-37 {
    left: 7px;
    top: -1px;
}
.report__tooth__m-key-38 {
    left: 2px;
}

.report__teeth-row-keys {
    position: absolute;
    display: flex;
    top: 43px;
    font-size: 10px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #838383;
}

.report__teeth-row-keys__m-top {
    top: 43px;
}

.report__teeth-row-keys__m-bottom {
    top: 172px;
}

.report__teeth-row-keys-key {
    width: 24px;
    height: 11px;
}

.report__status-indicator {
    width: 15px;
    height: 15px;
}

.report__legend {
    text-align: center;
    padding-top: 35px;
}

.report__legend-item {
    display: inline-block;
    margin-right: 19px;
}

.report__legend-item:last-child {
    margin-right: 0;
}

.report__legend-item-title {
    display: inline-block;
    font-size: 9px;
    line-height: 1.56;
    color: #9b9b9b;
    vertical-align: middle;
}

.report__status-indicator {
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
}

.report__status-indicator {
    width: 15px;
    height: 15px;
    margin-right: 7px;
}

.report__prepared {
    opacity: 0.6;
    font-size: 10px;
    color: #25272f;
    padding: 10px 0;
}

.report__prepared-highlight {
    color: #00ad66;
}

.report__powered-by {
    font-weight: 500;
}

.report__screens:after {
    content: '';
    display: table;
    clear: both;
}

.report-screen {
    width: 50%;
    display: inline-flex;
    flex-flow: row nowrap;
    padding-bottom: 5px;
}

.report-screen__image {
    width: 90px;
    height: 90px;
}

.report-screen__image canvas {
    display: block;
}

.report-screen__content {
    padding-left: 15px;
}

.report-screen__findings {
    padding-top: 10px;
}

.report-screen-finding {
    padding-right: 5px;
    font-size: 10px;
    line-height: 1.4;
    text-align: left;
    padding-top: 2px;
    color: #25272f;
    word-wrap: break-word;
}

.report-screen-finding:last-child {
    padding-left: 0;
}

.report-controls {
    padding-bottom: 20px;
    border-bottom: 1px #ccc solid;
    margin-bottom: 20px;
    position: relative;
}
.report-controls__locale-label {
    color: #000;
}
.report-controls__show-links-label {
    color: #000;
}

.report-controls__locale-element {
    padding-left: 5px;
}

.report-controls__print-button {
    position: absolute;
    right: 0;
    top: 0;
}

.report__user-info {
    width: 100%;
    margin: 20px 0 5px;
}

.report__user-info td {
    font-size: 11px;
    vertical-align: top;
}

.report__user-info td:last-child {
    text-align: right;
}

.report-footer-comment {
    font-size: 13px;
}

.report-spec {
    display: inline-flex;
    flex-flow: column nowrap;
    position: relative;
}

.report-spec-item {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.report-spec-add {
    position: absolute;
    left: -17px;
    top: 0;
    font-size: 20px;
    cursor: pointer;
}

.report-spec-remove {
    position: absolute;
    left: -15px;
    top: 4px;
    font-size: 13px;
    cursor: pointer;
}

.report__canvas-wrapper {
    display: flex;
    flex-flow: row nowrap;

    align-items: center;
    justify-content: center;

    margin-top: 5px;
}

.report__canvas {
    display: block;

    width: 100%;
    height: 100%;
}

.report__image {
    display: none;

    max-width: 100%;
    max-height: 100%;
}

.report-summary {
    padding: 5px 0 0;

    display: flex;
    flex-flow: column nowrap;
}

.report-summary__title {
    padding: 10px 0 5px;
    font-weight: 500;
    font-size: 12px;
}

.report-summary__text {
    border: 1px #ccc solid;
}

.report-teeth-numbers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.report-teeth-numbers__title {
    padding: 10px 0 5px;
    font-weight: 500;
    font-size: 12px;
}

.report-teeth-numbers__control input {
    padding: 0 5px;
    width: 100%;
    border: none;
    border-bottom: 1px #ccc solid;
    font-size: 12px;
}

.report-combined-findings {
    padding-bottom: 10px;
}

@media print
{
    body {
        overflow: auto !important;
    }
    .report {
        width: 100%;
    }

    .report__teeth {
        margin: 70px auto;
        transform: scale(1.4);
    }

    .report__legend-item-title {
        font-size: 12px;
    }

    .report-controls {
        display: none !important;
    }

    .report-info__value {
        display: inline;
    }

    .report-info__control {
        display: none;
    }

    .report__finding-comment__control input {
        padding: 2px 0;
        width: 100%;
        border: none;
        color: #999;
        font-size: 12px;
    }

    .report__finding-comment__control input::placeholder {
        color: transparent;
    }

    .report__finding-number input::placeholder {
        color: transparent;
    }

    .report__finding {
        page-break-inside: avoid;
    }

    .report__screen {
        page-break-inside: avoid;
    }

    .report-spec-add {
        display: none;
    }

    .report-spec-remove {
        display: none;
    }

    .report-summary__text {
        border: none;
    }

    .report-teeth-numbers__control input {
        border: none;
    }

    .report__finding-tooth-key-control {
        border: none;
    }

    .report-name select {
        border: none;
        background: transparent;
    }
}
