.history-state-button__icon-redo {
    position: relative;
    padding-right: 15px;
}

.history-state-button__icon-redo:before {
    content: '';
    background: url('../images/redo.svg') no-repeat;
    width: 15px;
    height: 16px;
    position: absolute;
    top: 0;
    right: -7px;
}

.history-state-button__icon-undo {
    position: relative;
    padding-left: 15px;
}

.history-state-button__icon-undo:before {
    content: '';
    background: url('../images/undo.svg') no-repeat;
    width: 15px;
    height: 16px;
    position: absolute;
    top: 0;
    left: -7px;
}
