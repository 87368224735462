.rounded-button {
    display: inline-block;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 100%;
    cursor: pointer;

    box-sizing: border-box;
    background-position: 0;
    background-color: transparent;
    background-repeat: no-repeat;

    background-size: 44px 44px;
    outline: none;
}

.rounded-button__m-disabled {
    cursor: default;
    opacity: .5;
}

.rounded-button__m-theme-primary {
    border: 1px solid #349563;
}

.rounded-button__m-theme-secondary {
    border: 1px solid #383a41;
}

.rounded-button__m-type-add {
    background-image: url(../images/add.svg);
    background-position: -1px -1px;
}

.rounded-button__m-type-add-thin {
    background-image: url(../images/add-thin.svg);
    background-size: 14px 14px;
    background-position: center center;
}


.rounded-button__m-type-stats {
    background-image: url(../images/stats.svg);
    background-position: -1px -1px;

}

.rounded-button__m-type-collections {
    background-image: url(../images/collections.svg);
    background-size: 30px 30px;
    background-position: 6px 7px;
}

.rounded-button__m-type-undo {
    background-image: url(../images/undo.svg);
    background-size: 15px 16px;
    background-position: center center;
}

.rounded-button__m-type-redo {
    background-image: url(../images/undo.svg);
    background-size: 15px 16px;
    background-position: center center;
    transform: scale(-1, 1);
}

.rounded-button__m-size-large {
    width: 44px;
    height: 44px;
}

.rounded-button__m-size-medium {
    width: 40px;
    height: 40px;
}

.rounded-button__m-size-small {
    width: 30px;
    height: 30px;
}
