.registry {
    min-width: 1050px;
    user-select: none;
}

.registry__no-images {
    min-height: 200px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1 auto;
    justify-content: center;
}

.registry__content {
    position: relative;
    min-height: 200px;
}

.registry__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
}

.registry__removing-error-message {
    padding: 10px 0;
}

.registry-grid {
	position: absolute;
	inset: 0;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 12px;
}

.registry-grid__header-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    box-sizing: border-box;
}

.registry-grid__content-row {
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    box-sizing: border-box;
    border-top: none;
}

.registry-grid__content-row:hover {
    background: #66676d;
}

.registry-grid__header-cell {
    flex: 0 0 auto;

    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-end;

    box-sizing: border-box;
    padding: 15px 10px;

    text-align: left;
    font-weight: 500;

    color: #c9cbd2;
}

.registry-grid__content-cell {
    flex: 0 0 auto;

    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;

    box-sizing: border-box;
    padding: 5px 10px;

    text-align: left;
}

.registry-grid__content-cell__m-image-date .registry-grid__content-cell-content,
.registry-grid__content-cell__m-patients-id .registry-grid__content-cell-content,
.registry-grid__content-cell__m-birthday .registry-grid__content-cell-content {
    text-overflow: clip;
}

.registry-grid__content-cell__m-index,
.registry-grid__content-cell__m-image-id,
.registry-grid__content-cell__m-file-name,
.registry-grid__content-cell__m-last-opening,
.registry-grid__content-cell__m-last-editing {
    color: #c9cbd2;
}

.registry-grid__content-cell__m-checked {
    padding-right: 0;
}


.registry-grid__header-cell-content {
    flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.registry-grid__content-cell-content {
    flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.registry__divider {
    border-bottom: solid 1px #4c4d55;
}

.registry__buttons {
    display: flex;
    padding: 20px 40px;

    flex-direction: row;
}

.registry__buttons-main,
.registry__buttons-secondary {
    width: 50%;
}

.registry__buttons-main {
    display: flex;
    justify-content: flex-end;
}

.registry__buttons-left {
    display: flex;

    justify-content: flex-start;
}

.registry__buttons .button {
    margin-right: 10px;
}

.registry__buttons .button:last-child {
    margin-right: 0;
}

.registry__status-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.registry__error{
    color: red;
    font-size: 10px;
    top: -1px;
    position: absolute
}

.registry__filters {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    border-bottom: 1px solid #45464E;
}

.registry__filter {
    padding: 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.registry__filter-link {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: right;

    color: #00AD66;
}

.registry__filter-link__m-disabled {
    color: #333;
}

.registry__filter-label {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.registry__filter-label-text {
    font-size: 12px;
    padding-left: 5px;
    display: inline-block;
}

.registry__filter-classes-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 12px;
}

.registry__filter-classes-item-control {
    margin-right: 5px;
}
