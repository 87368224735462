.resolve-add-label {
    min-height: 300px;
}
.resolve-add-label__setting__m-surfaces {
    padding: 7px 0;
}

.resolve-add-label__setting-label {
    display: flex;
    align-items: center;
    color: #c9cbd2;
    font-size: 12px;
    padding: 0 0 5px;
}

.resolve-add-label__surfaces {
    min-width: 50px;
    cursor: pointer;
    padding: 5px 0;
    border-bottom: 1px #97989c solid;
}
