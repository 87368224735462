.alignment {
    width: 100%;
    height: 100%;
}

.alignment:after {
    content: '';
    display: inline-block;
    height: 100%;
    font-size: 0;
    vertical-align: middle;
}

.alignment__content {
    display: inline-block;
    text-align: left;
    position: relative;
}

.alignment__content__m-vertical-top {
    vertical-align: top;
}

.alignment__content__m-vertical-center {
    vertical-align: middle;
}

.alignment__content__m-vertical-bottom {
    vertical-align: bottom;
}

.alignment__m-horizontal-left {
    text-align: left;
}

.alignment__m-horizontal-center {
    text-align: center;
}

.alignment__m-horizontal-right {
    text-align: right;
}
