.canvas-controls {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.canvas-controls__image-position {
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 500;
    padding: 0 10px;
}
.canvas-controls__history {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 8px;
}

.canvas-controls__history-button {
    padding-left: 12px;
}

.canvas-controls__history-button__m-reset {
    padding-left: 12px;
}

.canvas-controls__images-navigation {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding-left: 10px;
}

.canvas-controls__controls {
    padding-right: 12px;
}

.canvas-controls__controls:nth-child(2) {
    padding-right: 0;
}

.canvas-controls__images-navigation-button:last-child {
    padding-right: 0;
}

.canvas-controls__prev-image-button {
    background: url('../images/prev.svg') no-repeat;
    width: 8px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.canvas-controls__next-image-button {
    background: url('../images/next.svg') no-repeat;
    width: 8px;
    height: 12px;
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 1px;
}

.canvas-controls__controls-button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: solid 2px #349563;
    background: transparent;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.canvas-controls__controls-button__m-small {
    width: 30px;
    height: 30px;
}

.canvas-controls__controls-button:focus {
    outline: none;
}

.canvas-controls__controls-button__m-disabled {
    opacity: .5;
    cursor: default;
}

.canvas-controls__controls-button__m-first-image {
    background: url('../images/first.svg') center center no-repeat;
}

.canvas-controls__controls-button__m-last-image {
    background: url('../images/last.svg') center center no-repeat;
}
.canvas-controls__image-position-input {
    display: inline-block;
    background: none;
    color: #fff;
    width: 30px;
    font-size: 12px;
    font-weight: 500;
    border: none;
    text-align: right;
}
