.draw-polygon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: default;
}

.draw-polygon__svg {
    width: 100%;
    height: 100%;
    pointer-events: bounding-box;
}
