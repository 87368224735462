.stats-grid {
    position: relative;
    font-size: 12px;
}

.stats-grid-collection-name {
    text-decoration: underline;
    cursor: pointer;
}

.stats-grid__header-row {
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    background: #4C4D55;

    box-sizing: border-box;
}

.stats-grid__content-row {
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    box-sizing: border-box;
    border-top: none;
}

.stats-grid__content-row:hover {
    background: #66676d;
}

.stats-grid__header-cell {
    flex: 0 0 auto;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;

    box-sizing: border-box;
    padding: 15px 10px;

    text-align: center;
    font-weight: 500;

    color: #c9cbd2;

    background: #4C4D55;
}

.stats-grid__header-cell[class*="value"],
.stats-grid__header-cell[class*="detected"] {
    color: #fff;
    font-weight: 500;
}

.stats-grid__content-cell {
    flex: 0 0 auto;

    height: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;

    box-sizing: border-box;
    padding: 5px 10px;

    color: #c9cbd2;

    text-align: center;
}

.stats-grid__content-cell[class*="value"],
.stats-grid__content-cell[class*="detected"] {
    color: #fff;
    font-weight: 500;
}

.stats-grid__header-cell-content {
    flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.stats-grid__content-cell-content {
    flex: 0 1 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}
