.collections {
    flex: 1 1 auto;
}

.collections__pagination {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 16px 0 32px 0;
}

.collections__no-items {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    padding: 32px 0;
}

.collections__status-indicator {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 32px;
}
