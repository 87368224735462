.ui-huddle-layout {
	padding: 10px 40px 0;
	
	display: flex;
	flex-flow: column nowrap;
	flex: 1 1 auto;
	box-sizing: border-box;
}

.ui-huddle-layout__top-row {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
	margin: 36px 0 22px 0
}

.ui-huddle-layout__patients {
	flex: 0 0 auto;
	font-weight: 700;
	font-size: 34px;
	line-height: 41px;
	min-width: 200px;
}

.ui-huddle-layout__filters {
	flex: 1 1 auto;
	display: flex;
	flex-flow: row nowrap;
}

.ui-huddle-layout__filter {
	/*flex: 1 1 auto;*/
	max-width: 270px;
	width: 25%;
}

.ui-huddle-layout__filter:not(:first-child) {
	margin-left: 15px;
}

.ui-huddle-layout__cards {
	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;
}

.ui-huddle-layout__items {
	flex: 1 1 auto;
	margin-top: 12px;
	min-height: 300px;
	position: relative;
}

.ui-huddle-layout__empty {
	padding: 24px;
	text-align: center;
}

.ui-huddle-layout__slider-header {
	display: flex;
	flex-flow: row nowrap;
	font-size: 15px;
	color: #FFF;
	margin-top: 5px;
	user-select: none;
}

.huddle-grid__content-cell__m-findings .huddle-grid__content-cell-content {
	display: flex;
	flex-flow: row wrap;
}
