.fmx-viewer {
    position: absolute;
    left: 0;
    top: 0;
    right: 72px;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    user-select: none;
}

.fmx-viewer-canvas {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.fmx-viewer-row {
    height: 30%;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
}

.fmx-viewer-row__m-top {
    align-content: flex-start;
}

.fmx-viewer-row__m-middle {
    height: 40%;
    align-items: center;
   /* padding: 5px 0;*/
    box-sizing: border-box;
    padding: 10px 0;
}

.fmx-viewer-row__m-bottom {
    align-items: flex-end;
}

.fmx-viewer-cell {
    display: flex;
    flex-flow: row nowrap;
    width: 32%;
    height: 100%;
}

.fmx-viewer-cell__m-main {
    width: 36%;
    /*padding: 0 10px;*/
    box-sizing: border-box;
}

.fmx-viewer-image {
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 10px;
    box-sizing: border-box;
    margin: auto;
    position: relative;
}

.fmx-viewer-image__preview {
    flex: 1 1 auto;
    /*overflow: hidden;*/
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
}

.fmx-viewer-image__preview-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
}

.fmx-viewer-image__preview img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    margin: auto;
}

.fmx-viewer-image__preview__m-loaded img {
    position: static;
}

.fmx-viewer-image__teeth {
    flex: 0 0 auto;
    font-size: 8px;
    color: #fff;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.fmx-viewer-image__m-text-orientation-bottom .fmx-viewer-image__preview {
    order: 1;
}

.fmx-viewer-image__m-text-orientation-bottom .fmx-viewer-image__teeth {
    order: 2;
}

.fmx-viewer-image__m-text-orientation-top .fmx-viewer-image__preview {
    order: 2;
}

.fmx-viewer-image__m-text-orientation-top .fmx-viewer-image__teeth {
    order: 1;
}

.fmx-viewer-image__shapes {
    position: absolute;
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    overflow: hidden;
}

.fmx-viewer-image__shapes-inner {
    position: absolute;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
}

.fmx-viewer-image__shapes svg {
    width: 100%;
    height: 100%;
    overflow: visible;
}

.fmx-viewer-rotation-buttons-container {
    position: absolute;
    left: 0;
    top: -24px;
    right: 0;
    bottom: -24px;
}

.fmx-viewer-highlighted-image {
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
}

.fmx-viewer-highlighted-image__m-error {
    filter: drop-shadow(0px 0px 8px rgb(239, 83, 80));
}
