.fmx-viewer-rotation-buttons {
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
}

.fmx-viewer-rotation-buttons__m-position-down {
    top: auto;
    bottom: 0px;
}

.fmx-viewer-rotation-buttons__button {
    width: 24px;
    height: 24px;
    background-color: #25272f;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fmx-viewer-rotation-buttons__button:hover {
    background-color: #45464e;
}

.fmx-viewer-rotation-buttons__button__m-left:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url('../images/rotate-left.svg') no-repeat center center;
    background-size: 16px;
}

.fmx-viewer-rotation-buttons__button__m-right:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url('../images/rotate-right.svg') no-repeat center center;
    background-size: 16px;
}
