.magnifying-glass {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.magnifying-glass__viewport {
    position: absolute;
    left: 0;
    top: 0;
}

.magnifying-glass-image-status {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.magnifying-glass-image-status__content {
    margin: auto;
}
