.tfv-list {
    flex: 1 1 auto;
    position: relative;
}

.tfv-list__canvas {
    position: absolute;
    top: 0;
    right: 104px;
    left: 32px;
    bottom: 0;
}

.tfv-list__images {
    position: absolute;
    top: 0;
    right: 104px;
    left: 32px;
    bottom: 0;
}
.tfv-list__images-inner {
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    overflow: auto;
    left: 32px;
    top: 0;
    bottom: 0;
    right: 32px;
}

.tfv-list__slider {
    position: absolute;
    width: 32px;
    left: -32px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tfv-list__slider__m-right {
    left: auto;
    right: -32px;
}

.tfv-list__slider-action {
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../images/slider-left-arrow.svg');
}

.tfv-list__slider-action__m-right {
    background-image: url('../images/slider-right-arrow.svg');
}

.tfv-list__slider-action__m-disabled {
    opacity: .5;
    cursor: default;
}

.tfv-list__image-tooth-key {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #fff;
    left: 50%;
    top: 50%;
}
