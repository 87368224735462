.tooth-info-tooth-select__header {
    position: relative;
    padding: 0 0 0 4px;
    width: 100%;
    font-weight: 500;
    font-size: 10px;
}

.tooth-info-tooth-select__indicator {
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -2px;
}

.tooth-info-tooth-select__item {
    position: relative;
    padding: 5px 0 5px 4px;
    font-size: 10px;
}

.tooth-info-tooth-select__item:hover {
    background-color: #66676d;
}

.tooth-info-tooth-select__required-box {
    position: absolute;
    right: 10px;
    top: 7px;
    width: 14px;
    height: 14px;
    background-image: url('../images/required-box.svg');
    background-size: 14px;
}

.tooth-info-tooth-select__select {
    width: 36px;
    height: 28px
}
