.tooltip {
    border-radius: 5px;
    background-color: #acadb2;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #66676d;
    font-size: 12px;
    color: #45464e;
    padding: 7px;

    margin: 10px 0 0 0;
    text-align: left;
}

.tooltip__m-global {
    margin: 0;
    position: relative;
}

.tooltip-global-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 120;
    opacity: 0;
}

.tooltip__m-cursor-x-left.tooltip__m-cursor-y-top:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 10px;
    border-bottom: 5px #66676d solid;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
}

.tooltip__m-cursor-x-right.tooltip__m-cursor-y-top:before {
    content: '';
    position: absolute;
    top: -5px;
    right: 10px;
    border-bottom: 5px #66676d solid;
    border-left: 5px transparent solid;
    border-right: 5px transparent solid;
}
