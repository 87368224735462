.user-sidebar {
    width: 420px;
    height: 100%;

    display: flex;
    flex-direction: column;

    position: relative;
    overflow: auto;

    background-color: #45464e;
}

.user-sidebar__info {
    height: 76px;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    flex-shrink: 0;
}

.user-sidebar__editor {
    flex: 1 1 auto;
    padding: 22px 31px;
}

.user-sidebar__form {
    display: flex;
    flex-direction: row;
}

.user-sidebar__form-secondary {
    width: 90px;
    padding-right: 20px
}

.user-sidebar__form-main {
    flex: 1 1 auto;
}

.user-sidebar__form__m-personal {
    padding-bottom: 30px;
}

.user-sidebar__form__m-job {
    padding-top: 30px;
}

.user-sidebar__form__m-links {
    justify-content: flex-end;
    padding: 2px 0;
}

.user-sidebar__form__m-links a {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
}

.user-sidebar__footer {
    height: 88px;
    min-height: 88px;
    flex-basis: 88px;
    flex-shrink: 0;
}

.user-sidebar__footer-controls {
    border-top: solid 1px #4c4d55;
    height: 100%;
    display: flex;
    box-sizing: border-box;

    align-items: center;
    justify-content: center;
}

.user-sidebar__footer-controls-button {
    width: 145px;
    margin: 0 0 0 20px;
    box-sizing: border-box;
}

.user-sidebar__footer-controls-button:last-child {
    margin-right: 0;
}

.user-sidebar__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
}

.user-sidebar__status-message {
    text-align: center;
    color: #fff;
}

.user-sidebar__status-message-text {
    padding: 10px 0;
}

.user-sidebar__upload-clinic-logo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 1px #b2b2b2 solid;
    color: #b2b2b2;
    text-align: center;
    cursor: pointer;
}

.user-sidebar__upload-clinic-logo input {
    display: none;
}

.user-sidebar__email-label {
    position: static !important;
}

.user-sidebar__email-field {
   margin-top: 0 !important;
}
