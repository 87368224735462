.resolver-navigation {
    background: #1B1D24;
    padding: 0 0 8px 0;
    overflow: hidden;
}
.resolver-navigation-top-panel {
    height: 40px;
    display: flex;
    flex-flow: row nowrap;
}

.resolver-navigation-top-panel__main {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 16px;
}

.resolver-navigation-top-panel__secondary {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 8px 0 0;
}

.resolver-navigation-top-panel__name {
    color: #FFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.resolver-navigation-top-panel__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #989898;
}

.resolver-navigation-top-panel__separator {
    color: #FFF;
    display: inline-block;
    width: 16px;
    text-align: center;
}

.resolver-navigation-top-panel__toggle-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.resolver-navigation-top-panel__toggle-button__m-show {
    background-image: url('../images/hide-thumbs.svg');
    transform: scale(1, -1);
}

.resolver-navigation-top-panel__toggle-button__m-hide {
    background-image: url('../images/hide-thumbs.svg');
}

.resolver-navigation-content {
    position: relative;
}

.resolver-navigation-previews {
    overflow: auto;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    padding: 16px 0 6px 0;
    margin: -16px 0 0 0;
}

.resolver-navigation-previews__items {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 auto;
}

.resolver-navigation-previews-item {
    height: 104px;
    user-select: none;
}

.resolver-navigation-previews-item__m-selected {
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.75));
}

.resolver-navigation-previews-item__content {
    cursor: pointer;
}

.resolver-navigation-previews-item__image {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: #000;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.resolver-navigation-previews-item__description {
    height: 16px;
    overflow: hidden;
    position: relative;
    user-select: none;
}

.resolver-navigation-previews-item__description-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.resolver-navigation-previews-control {
    position: absolute;
    top: 16px;
    width: 60px;
    height: 88px;
    cursor: pointer;
}

.resolver-navigation-previews-control__arrow {
    position: absolute;
    top: 24px;
    width: 22px;
    height: 40px;
    background-image: url('../images/arrow.svg');
    background-repeat: no-repeat;
    background-size: 22px 40px;
}

.resolver-navigation-previews-control__m-hidden {
    opacity: 0;
}

.resolver-navigation-previews-control__m-disabled {
    cursor: auto;
}

.resolver-navigation-previews-control__m-disabled .resolver-navigation-previews-control__arrow {
    opacity: .8;
}

.resolver-navigation-previews-control__m-prev {
    background: linear-gradient(90deg, #1B1D24 0%, rgba(27, 29, 36, 0) 100%);
    left: 0;
}

.resolver-navigation-previews-control__m-prev .resolver-navigation-previews-control__arrow {
    left: 21px;
}

.resolver-navigation-previews-control__m-next {
    background: linear-gradient(270deg, #1B1D24 0%, rgba(27, 29, 36, 0) 100%);
    right: 0;
}

.resolver-navigation-previews-control__m-next .resolver-navigation-previews-control__arrow {
    right: 21px;
    transform: scale(-1, 1);
}

.resolver-navigation-positions {
    position: absolute;
    top: 180px;
    left: 50%;
    height: 8px;
    transform: translate(-50%, 0);
    display: flex;
    flex-flow: row nowrap;
}

.resolver-navigation-position {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #45464E;
}

.resolver-navigation-position:not(:first-child) {
    margin: 0 0 0 8px;
}

.resolver-navigation-position__m-active {
    width: 24px;
}

.resolver-navigation-scroll::-webkit-scrollbar {
    height: 8px;
}

.resolver-navigation-scroll::-webkit-scrollbar-thumb {
    background: #45464E;
    border-radius: 4px;
}
