.form-label {
    display: flex;
}
.form-label__m-top {
    flex-direction: column-reverse;
}

.form-label__m-left {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.form-label__m-right {
    flex-direction: row;
}

.form-label__m-bottom {
    flex-direction: column;
}

.form-label__wrapper-element {

}

.form-label__wrapper-label-text {
    display: flex;
    align-items: center;
    color: #c9cbd2;
    font-size: 12px;
}

.form-label__m-top {
    padding-bottom: 10px;
}

.form-label__m-left {
    padding-right: 10px;
}

.form-label__m-right {
    padding-left: 10px;
}

.form-label__m-bottom {
    padding-top: 10px;
}

.form-label__m-no-padding {
    padding: 0;
}
