.add-diagnosis__select {
    width: 170px;
    height: 30px;
    background-color: #2F313A;
    border-radius: 0 !important;
    font-size: 12px;
}

.add-diagnosis__select-header {
    background-color: #2F313A;
    padding-left: 10px;
}

.add-diagnosis__wrapper {
    padding: 5px;
    background-color: #25272f;
}

.add-diagnosis__select-items {
    border-radius: 0 !important;
}

.add-diagnosis__select-item {
    font-size: 12px;
}
