.shift-numbering-button {
    width: 22px !important;
    height: 22px !important;
    padding: 0 !important;
}

.shift-numbering-button__content {
    width: 7px;
    height: 13px;
}
.shift-numbering-button__content__m-left {
    background: url('../images/prev.svg') no-repeat;
    margin: 1px 0 0 4px;
}

.shift-numbering-button__content__m-right {
    background: url('../images/next.svg') no-repeat;
    margin: 1px 0 0 6px;
}
