.resolver-finding-viewer {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
}

.resolver-finding-viewer__main {
    flex: 1 1 auto;
    width: 300px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
}

.resolver-finding-viewer__secondary {
    flex: 0 0 auto;
    position: relative;
    background: #ACADB2;
    border-radius: 8px 0 8px 8px;
}

.resolver-finding-viewer__preview-container {
    background: #45464E;
    margin: 8px ;
}

.resolver-finding-viewer__tooth-container {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
}

.resolver-finding-viewer__tooth {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    background: #45464E;
    border-radius: 50%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
}

.resolver-finding-viewer-list {
    flex: 1 1 auto;
    position: relative;
}

.resolver-finding-viewer-list__inner {
    position: absolute;
    left: -1px;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.resolver-finding-viewer-list__inner::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.resolver-finding-viewer-list__inner::-webkit-scrollbar-thumb {
    background: #45464E;
    border-radius: 4px;
}

.resolver-finding-viewer-image {
    display: flex;
    flex-flow: row nowrap;
    padding: 8px 16px 8px 8px;
    background: #ACADB2;
}

.resolver-finding-viewer-image:first-child {
    border-radius: 0 8px 0 0;
}

.resolver-finding-viewer-image:last-child {
    border-bottom-right-radius: 8px;
}

.resolver-finding-viewer__image-type {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
}

.resolver-finding-viewer-image__preview {
    flex: 0 0 auto;
    margin: 0 8px 0 0;
}

.resolver-finding-viewer-image__preview img {
    display: block;
    width: 88px;
}

.resolver-finding-viewer-image__preview.resolver-finding-viewer-image__preview__m-current img {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.75));
}

.resolver-finding-viewer-content {
    flex: 1 1 auto;
    position: relative;
}

.resolver-finding-viewer-findings {
    position: relative;
}

.resolver-finding-viewer-finding {
    display: flex;
    flex-flow: row nowrap;
    padding: 2px 0;
}

.resolver-finding-viewer-finding__name {
    font-size: 12px;
    line-height: 16px;
    flex: 1 1 auto;
    color: #25272F;
}

.resolver-finding-viewer-finding__actions {
    flex: 0 0 auto;
}

.resolver-finding-viewer-finding__action {
    width: 16px;
    height: 16px;
    cursor: pointer;
    background-repeat: no-repeat;
}

.resolver-finding-viewer-finding__action {
    background-size: 100%;
    background-image: url('../images/remove.svg');
}

.resolver-finding-viewer__viewport {
    position: relative;
    overflow: hidden;
}

.resolver-finding-viewer__viewport img {
    position: absolute;
    top: 0;
    left: 0;
}

.resolver-finding-viewer__image-shapes {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.resolver-finding-viewer__image-shapes svg {
    width: 100%;
    height: 100%;
    /*pointer-events: bounding-box;*/
}

.resolver-finding-viewer__close {
    position: absolute;
    top: 0;
    right: 8px;
    width: 24px;
    height: 24px;
    background-image: url("../images/close.svg");
    cursor: pointer;
}

.resolver-finding-viewer__current {
    font-size: 11px;
    padding: 3px 0;
    text-align: center;
}
