.image-shape-box__m-highlighted {
    stroke-opacity: .5;
}

.image-shape-box__controls {
    cursor: move;
}

.image-shape-box__resize-control {
    cursor: grab;
}

.image-shape-box__action-control {
    pointer-events: bounding-box;
}
.image-shape-box__action-control,
.image-shape-box__action-control-box {
    cursor: pointer;
}

.image-shape-box__action-control:hover {
    stroke-opacity: .5;
    stroke-width: 2.8;
}
