.resolver-findings-validation-wrapper {

}

.resolver-findings-validation-wrapper__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
}

.resolver-findings-validation-wrapper__icon {
    width: 16px;
    height: 16px;
    background-image: url(../../../../../images/error.svg);
}

.resolver-findings-validation-wrapper__icon__m-theme-gray {
    width: 10px;
    height: 10px;
    background-image: url(../../../../../images/warn-gray.svg);
}

.resolver-findings-validation-wrapper__icon__m-position-left {
    margin: 0 4px 0 0;
}

.resolver-findings-validation-wrapper__icon__m-position-right {
    margin: 0 0 0 4px;
}

.resolver-findings-validation-wrapper__icon__m-position-right-near {
    position: absolute;
    right: 1px;
    top: 3px;
    width: 12px;
    height: 12px;
    background-size: cover;
}
